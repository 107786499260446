import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Loading from "../Loading";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import AsyncSelect from 'react-select/async';
import axios from "../AxiosConfig";
import Modal from 'react-bootstrap/Modal';
import { useAuth } from "../Authentication/AuthContext";
import FundortObservationTable from "./FundortObservationTable";
import { uniqBy } from "lodash";
import FundorteMap from "./FundorteMap";
import FundortTabelle from "./FundortTabelle";


function Fundortverwaltung() {

  const navigate = useNavigate();
  const auth = useAuth();
  const [map, setMap] = useState(null);
  const [choicesFundort, setChoicesFundort] = useState([]);
  const [fundort, setFundort] = useState(null);
  const [fundorte, setFundorte] = useState([]);
  const [observations, setObservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = (id) => {
    console.log("handleDelete");
    setButtonDisabled(true);
    axios.post(`fundorte/delete/`, {
      id: id
    }).then(response => {
      console.log("deleted fundort");
      setButtonDisabled(false);
      handleClose();
      toast.success("Fundort wurde gelöscht!");
      navigate("/meinefundorte", { replace: true });
    })
      .catch(function (error) {
        console.log(error);
        setButtonDisabled(false);
        toast.error("Es ist ein Fehler aufgetreten!");
      });
  };

  const handleEdit = (id) => {
    console.log("handleEdit");
    navigate("/editfundort?id=".concat(id), { replace: true });
  };

  useEffect(() => {
    const getChoices = async (created_by = null) => {
      const response = await axios.get(`fundorte/get?user=${auth.id}`);
      console.log(response.data);
      const fundort = response.data.map((row) => {
        row["value"] = row.id;
        row["label"] = row.fundort;
        return (row)
      });
      // console.log(fundort);
      setFundorte(response.data);
      setChoicesFundort(fundort);
    };
    getChoices();
  }, [auth.id]);

  const getObservations = async (id) => {
    setLoading(true);
    // HUHU: allow to add id_fundort to query
    await axios.get(`user/observations?id=${id}`)
      .then(res => {
        const uniqueObservations = uniqBy(res.data, "id");
        setObservations(uniqueObservations);
        console.log(uniqueObservations);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Ein Fehler ist aufgetreten.");
        setLoading(false);
      });
  };

  const selectFundort = (option) => {
    console.log(option);
    if (option !== null) {
      if (map) {
        map.setView([option.latitude, option.longitude], 15);
      }
      setFundort(option);
      getObservations(option.id);
    } else {
      setFundort(null);
      setObservations([]);
    }
  };

  const filterFundorte = (inputValue) => {
    let filteredArray = choicesFundort
      .filter((element) =>
        element.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    return filteredArray
  };

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(filterFundorte(inputValue));
      }, 1000);
    });

  return (
    <>
      <br></br>
      <Container>
        <Toaster position="bottom-right" />
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Fundort wirklich löschen?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Dies kann nicht rückgängig gemacht werden! Die zugehörigen
            Beobachtungen werden nicht gelöscht, aber es wird die Verknüpfung
            mit diesem Fundort gelöscht.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Abbrechen
            </Button>
            <Button
              variant="danger"
              disabled={buttonDisabled}
              onClick={() => handleDelete(fundort.id)}
            >
              Ja, Fundort löschen
            </Button>
          </Modal.Footer>
        </Modal>
        <h4>Meine Fundorte</h4>
        <hr></hr>
        <Form style={{ maxWidth: "600px" }}>
          <Row>
            <Col md={6}>
              <AsyncSelect
                isClearable
                cacheOptions
                id={"fundort"}
                type={"text"}
                onChange={selectFundort}
                defaultOptions={choicesFundort.slice(0, 100)}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                loadOptions={promiseOptions}
                placeholder="Fundort suchen...">
              </AsyncSelect>
            </Col>
            <Col md={6}>
              <Link to="/neuerfundort">
                <Button
                  variant="outline-success"
                >
                  Neuer Fundort
                </Button>
              </Link>
            </Col>
          </Row>
        </Form>
        <hr></hr>
        <Row>
          <Col md={6}>
            <Container className="panel">
              <FundorteMap
                fundorte={fundorte}
                selected={fundort}
                setMap={setMap}
                selectFundort={selectFundort}
              />
            </Container>
          </Col>
          <Col md={6}>
            {fundort ?
              <Container className="panel">
                <h5>
                  {fundort.label}
                  {/* <Button
                    title="Fundort löschen"
                    onClick={() => handleShow()}
                    style={{ float: "right" }}
                    variant="danger"
                  >
                    <i className="fa fa-trash"></i>
                  </Button> */}
                  <Button
                    title="Fundort editieren"
                    onClick={() => handleEdit(fundort.id)}
                    style={{ float: "right" }} // marginRight: "5px" 
                    variant="warning"
                  >
                    <i className="fa fa-pencil"></i>
                  </Button>
                </h5>
                <FundortTabelle observation={fundort} />
              </Container>
              : <p>Kein Fundort ausgewählt.</p>
            }
          </Col>
        </Row>
        <hr></hr>
        {fundort ?
          <>
            <Container>
              <h5>Beobachtungen des Fundort</h5>
              {loading ? <Loading height="100px" /> :
                <FundortObservationTable observations={observations} />}
            </Container>
          </> : <p>Kein Fundort ausgewählt.</p>
        }
        <br></br>
      </Container>
    </>
  )
}

export default Fundortverwaltung;
