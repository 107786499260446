export function toGermanDate(date) {
  if (date.length > 4) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return (new Date(Date.parse(date)).toLocaleDateString('de-DE', options));
  } else {
    return (date)
  }
}

export function toGermanDateShort(date) {
  if (date.length > 4) {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return (new Date(Date.parse(date)).toLocaleDateString('de-DE', options));
  } else {
    return (date)
  }
}

export default toGermanDate;
