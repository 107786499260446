import React from 'react';
import dayjs from 'dayjs';

export const YearInput = ({ id, value, placeholder, setFieldValue, handleBlur }) => {
  const minYear = 1800;
  const maxYear = dayjs().year();

  const handleChange = (event) => {
    const newValue = event.target.value;
    setFieldValue(id, newValue);

    // if (id === "jahr_min" && value !== null && value < newValue && newValue >= minYear) {
    //   setFieldValue("jahr_max", newValue);
    // }
    // if (id === "jahr_max" && value !== null && value > newValue && newValue >= minYear) {
    //   setFieldValue("jahr_min", newValue);
    // }
  };

  return (
    <input
      className="form-control"
      id={id}
      type="number"
      value={value}
      placeholder={placeholder}
      min={minYear}
      max={maxYear}
      onChange={handleChange}
      onBlur={handleBlur} />
  );
};
