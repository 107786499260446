import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { Checkbox } from 'pretty-checkbox-react';
import { Link, useNavigate } from 'react-router-dom';
// import axios from "../AxiosConfig";
import toast, { Toaster } from 'react-hot-toast';
import { useAuth } from "./AuthContext";

const Registration = () => {

  const navigate = useNavigate();
  const auth = useAuth();
  const formik = useFormik({

    initialValues: {
      vorname: '',
      nachname: '',
      email: '',
      passwort: '',
      passwort2: '',
      nutzungsvereinbarung: false,
      adresse: "",
      ort: "",
      telefon: ""
    },
    validationSchema: Yup.object({
      vorname: Yup.string()
        .required('Pflichtfeld'),
      nachname: Yup.string()
        .required('Pflichtfeld'),
      email: Yup.string()
        .email('E-Mail Addresse ungültig!')
        .required('Pflichtfeld'),
      passwort: Yup.string()
        .min(8, 'Das Passwort muss mindestens 8 Zeichen lang sein!')
        .required('Pflichtfeld'),
      passwort2: Yup.string()
        .oneOf([Yup.ref('passwort'), null], 'Passwörter müssen übereinstimmen!'),
      // nutzungsvereinbarung: Yup.bool().isValid(true),

    }),
    onSubmit: values => {
      if (values.nutzungsvereinbarung === false) {
        toast.error("Die Nutzungsvereinbarung muss akzeptiert werden!");
        return (null);
      }

      auth.register(() => {
        navigate('/beobachtungen', { replace: true });
      }, values.nachname, values.vorname, values.email, values.passwort,
        values.adresse, values.ort, values.telefon);
    }
  });

  return (
    <div>
      <Toaster position="bottom-right"></Toaster>
      <div className="auth-form-wide">
        <div className="auth-form-body-wide">
          <h3>Registrierung</h3>
          <hr></hr>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group>
              <h5>Persönliche Angaben</h5>
              <Form.Group controlId="vorname">
                <Form.Label>Vorname*</Form.Label>
                <Form.Control
                  type={'text'}
                  value={formik.values.vorname}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder="Max"
                />
                {formik.touched.vorname && formik.errors.vorname ? (
                  <div style={{ color: 'red' }}>{formik.errors.vorname}</div>
                ) : null}
              </Form.Group>
              <Form.Group controlId="nachname">
                <Form.Label>Nachname*</Form.Label>
                <Form.Control
                  type={'text'}
                  value={formik.values.nachname}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder="Mustermann"
                />
                {formik.touched.nachname && formik.errors.nachname ? (
                  <div style={{ color: 'red' }}>{formik.errors.nachname}</div>
                ) : null}
              </Form.Group>
              <br></br>
              <h5>Kontakt Informationen</h5>
              <Form.Group controlId="email">
                <Form.Label>E-Mail*</Form.Label>
                <Form.Control
                  type={'email'}
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder="example@domain.com"
                />
                {formik.touched.email && formik.errors.email ? (
                  <div style={{ color: 'red' }}>{formik.errors.email}</div>
                ) : null}
              </Form.Group>
              <Row>
                <Form.Group as={Col} xs={12} md={6} controlId="adresse">
                  <Form.Label>Straße, Hausnummer (optional)</Form.Label>
                  <Form.Control
                    type={'text'}
                    value={formik.values.adresse}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Wiesenstraße, 20"
                  />
                </Form.Group>
                <Form.Group as={Col} xs={12} md={6} controlId="ort">
                  <Form.Label>PLZ, Ort (optional)</Form.Label>
                  <Form.Control
                    type={'text'}
                    value={formik.values.ort}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="80333, München"
                  />
                </Form.Group>
              </Row>
              <Form.Group controlId="telefon">
                <Form.Label>Telefonnummer (optional)</Form.Label>
                <Form.Control
                  type={'tel'}
                  value={formik.values.telefon}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Form.Group>
              <br></br>
              <h5>Passwort</h5>
              <Form.Group controlId="passwort">
                <Form.Label>Passwort*</Form.Label>
                <Form.Control
                  type={'password'}
                  value={formik.values.passwort}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.passwort && formik.errors.passwort ? (
                  <div style={{ color: 'red' }}>{formik.errors.passwort}</div>
                ) : null}
              </Form.Group>
              <Form.Group controlId="passwort2">
                <Form.Label>Passwort wiederholen*</Form.Label>
                <Form.Control
                  type={'password'}
                  value={formik.values.passwort2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.passwort2 && formik.errors.passwort2 ? (
                  <div style={{ color: 'red' }}>{formik.errors.passwort2}</div>
                ) : null}
              </Form.Group>
            </Form.Group>
            <label style={{ display: "block" }}>
              <Checkbox
                color="primary"
                name="nutzungsvereinbarung"
                value={formik.values.nutzungsvereinbarung}
                onChange={formik.handleChange}
              ></Checkbox>Hiermit stimme ich der <Link to='/nutzungsvereinbarung' rel="noopener noreferrer" target="_blank">Nutzungsvereinbarung</Link> zu.
            </label>
            {/* {formik.touched.nutzungsvereinbarung && formik.errors.nutzungsvereinbarung ? (
                            <div style={{ color: 'red' }}>{formik.errors.nutzungsvereinbarung}</div>
                ) : null} */}
            <hr></hr>
            <div className="d-grid gap-2">
              <Button variant="success" type="submit">
                Registrieren
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <br></br>
    </div>
  )
}

export default Registration;