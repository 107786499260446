import React, { useEffect, useState } from "react";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { Link } from 'react-router-dom';
import Pagination from '../Pagination.js';
// import user from '../../img/user.jpg';
import { toGermanDate } from "../../functions/toGermanDate";


function ObservationCard(props) {
  const tk25 = props.tk25 ?? "unbekannt";
  const datum = props.datum ?? props.jahr;
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src={props.foto} alt={"[Beobachtung ".concat(props.id)
          .concat("] ")
          .concat(props.art)
          .concat(", ")
          .concat(props.private ? props.ort : "Landkreis ".concat(props.landkreis))
          .concat(", ")
          .concat(props.private ? "" : tk25.concat(", "))
          .concat(toGermanDate(datum))
          .concat(" (Beobachter: ")
          .concat(props.beobachter)
          .concat(")")} />
      {/* <div class="image" style={backgroundImage: "url('http://2.bp.blogspot.com/-isrPay4twtQ/UWudn23k1sI/AAAAAAAAAZQ/vDeXHzTJxeU/s1600/imagens-lindas+(1).jpeg'); "}></div> */}
      {/* { !props.private ? <img className="userIcon" src={user} title={props.beobachter}></img> : null } */}
      <Link to={"/beobachtung?id=".concat(props.id)} rel="noopener noreferrer" target="_blank">
        <Card.Body style={{ color: "black" }}>
          <Card.Title>
            <i>{props.art}</i>
          </Card.Title>
          {/* <hr></hr> */}
          <Card.Text>
            <i className="fa fa-map-marker"></i>
            <small> {props.private ? props.ort : tk25}
              <span style={{ float: "right" }}>{toGermanDate(datum)}</span>
            </small>
          </Card.Text>
        </Card.Body>
      </Link>
    </Card>
  )
}

function ObservationCardGallery(props) {

  const observationsWithImages = props.observations.filter((row) => {
    return (row.foto !== null);
  })

  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 16;

  const indexLast = currentPage * cardsPerPage;
  const indexFirst = indexLast - cardsPerPage;

  useEffect(() => {
    setCurrentPage(1);
  }, [props]);
  
  const paginate = (pageNum) => setCurrentPage(pageNum);
  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  const cards = observationsWithImages.slice(indexFirst, indexLast).map((row, index) => {
    if (row.foto !== null) {
      return (
        <ObservationCard
          key={row.id.toString()}
          foto={row.foto}
          art={row.art}
          tk25={row.tk25}
          datum={row.datum}
          beobachter={row.beobachter}
          landkreis={row.landkreis}
          ort={row.ort}
          naturraum_name={row.naturraum_name}
          id={row.id}
          private={props.private}
        />
      )
    } else {
      return null;
    }
  });

  return (
    <Row>
      <SimpleReactLightbox>
        <SRLWrapper>
          <div className="grid">
            {cards}
          </div>
        </SRLWrapper>
      </SimpleReactLightbox>
      <br></br>
      {
        observationsWithImages.length <= cardsPerPage ? null :
          <Pagination
            objectsPerPage={cardsPerPage}
            totalObjects={observationsWithImages.length}
            paginate={paginate}
            nextPage={nextPage}
            prevPage={prevPage}
            currentPage={currentPage}
            />
      }
    </Row>
  )
}

export { ObservationCard, ObservationCardGallery };
