import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import axios from "../AxiosConfig";
import datenerfassung1 from "../../img/datenerfassung-1.jpg";
import datenerfassung4 from "../../img/datenerfassung-4.png";
import datenerfassung3 from "../../img/datenerfassung-3.jpg";
import beobachtungen0 from "../../img/beobachtungen-0.png";
import beobachtungen2 from "../../img/beobachtungen-2.png";
import beobachtungen3 from "../../img/beobachtungen-3.png";
import beobachtungen4 from "../../img/beobachtungen-4.png";
import beobachtungen5 from "../../img/beobachtungen-5.png";


function Datenerfassung() {

  const downloadDatenvorlage = async () => {
    axios({
      url: "download",
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "datenvorlage_schmetterlingebayern.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <>
      <h4>Datenerfassung</h4>
      <p>
        Daten können über unterschiedliche Wege erfasst und dem Projekt zur
        Verfügung gestellt werden.
      </p>
      <hr></hr>
      <h5>Online Eingabe</h5>
      <p>
        Das Eingabe-Tool unter "Neue Beobachtung" kann zum Erfassen von Beobachtungen genutzt werden.
        Eine detaillierte Anleitung dazu findet sich <a href="#onlineeingabe">hier</a>.
      </p>
      <h5>Excel Vorlage zur Datenerfassung</h5>
      <p>Beobachtungsdaten können auch per Excel Datei erfasst werden. Hierfür
        gibt es eine Vorlage, die über den Link unten heruntergeladen werden
        kann:
        <Button variant="link" onClick={downloadDatenvorlage}>
          <i className="fa fa-download" role="presentation" aria-label="download icon"></i>{" "}
          Datenvorlage herunterladen
        </Button>
      </p>
      <p>Die ausgefüllte Vorlage schicken Sie uns bitte per Mail an die auf
        der Startseite angegebene E-Mail Adresse.
      </p>
      <h5>Apps, andere Portale etc.</h5>
      <p>
        Wenn Sie Ihre Beobachtungen schon bei einer anderen Seite erfassen,
        nutzen Sie bitte die dort gegebenen Exportmöglichkeiten und
        schicken Sie uns Ihre Daten im Excel Format per E-Mail zu.
        Bitte haben Sie Verständnis dafür, dass die Übernahme der Daten
        je nach Aufwand der Umwandlung der Daten einige Wochen dauern kann,
        insbesondere im Sommer. Für einzelne Apps, z.B. Ornitho existieren
        bereits Skripte, sodass die Übernahme der Daten mit weniger Aufwand
        verbunden ist. Wenn Sie Ihre Daten über <i>observation.org</i> oder
        {" "}<i>iNaturalist</i> erfassen, müssen Sie uns diese Daten nicht extra
        zuschicken, da wir diese Daten in regelmäßigen Abständen direkt aus
        den Portalen bzw. von GBIF importieren. Wichtig ist hierbei nur, dass diese Daten
        unter einer CC-Lizenz (z.B. CC0, CC-BY) lizensiert sind, damit sie ohne
        Probleme übernommen werden können.
        Bei iNaturalist können Sie Ihre Beobachtungen aus Bayern dem Projekt
        <a href="https://www.inaturalist.org/projects/schmetterlinge-in-bayern">{" "}
          Schmetterlinge in Bayern</a> hinzufügen. Dadurch ist es möglich,
        die Koordinaten für dieses Projekt offenzulegen.
      </p>
      <div id="onlineeingabe"></div>
      <hr></hr>
      <h5>Wie erstelle ich eine Beobachtung auf der Website?</h5>
      <br></br>
      <Alert variant={"secondary"}>
        <b>1. </b>Klicken Sie auf den grünen Button <b>"Neue Beobachtung"</b> in der Navigationsleiste.
      </Alert>
      <img src={datenerfassung1} alt="Datenerfassung" />
      <br></br>
      <br></br>
      <Alert variant={"secondary"}>
        <b>2. Fundumstände</b>:<br></br>
        Wählen Sie zunächst das Datum der Beobachtung aus. Als Beobachter ist bereits der eigene Name vorausgewählt.
        Navigieren Sie in der Karte zum Fundort der Beobachtung.
        Klicken Sie in der Karte auf das Marker Symbol und setzen den Marker an die Stelle
        der Beobachtung.
      </Alert>
      <img src={datenerfassung4} alt="Datenerfassung" />
      <br></br>
      <br></br>
      <Alert variant={"secondary"}>
        <b>3. Details zur Beobachtung</b>:<br></br>
        Wählen Sie auf dieser Seite die Art aus, auch Fotos können hier
        hochladen. Weitere Angaben wie Stadium, Anzahl oder Bemerkungen
        können ebenfalls ergänzt werden. Klicken Sie abschließend auf den grünen Button
        <b>"Beobachtung speichern"</b>.
      </Alert>
      <img src={datenerfassung3} alt="Datenerfassung" />
      <br></br>
      <br></br>
      <Alert variant={"secondary"}>
        <b>4.</b> Nachdem die Beobachtung gespeichert wurde, kann sie unter
        <b>"Meine Beobachtungen"</b> angezeigt werden.
      </Alert>
      <hr></hr>
      <h5>Hinweise zur Online-Eingabe</h5>
      <b>Bildbelege</b>
      <p>
        Zum Beleg der Beobachtung können ein oder mehrere Fotos an die Beobachtung
        angehängt werden. Diese können per "Drag und Drop" oder über Klick auf
        das Feld hochgeladen werden.
        Um die Seiteladezeiten gering zu halten und nicht zu viel Speicher zu
        verbrauchen, sollten die Fotos vor dem Upload in ihrer Größe reduziert werden.
        Besonders geeignet für die Darstellung ist das Format 900x600 Pixel (Querformat).
      </p>
      <p>
        Bei schwierig zu bestimmenden Arten (z.B. Gattung <i>Melitaea</i>) können Sie gerne
        auch mehrere Ansichten eines Individuums hochladen (z.B. Unterseite, Oberseite,
        Fühlerkolben), um die Chance zu erhöhen, dass eine eindeutige Bestimmung
        möglich ist.
        Auch Habitatfotos und Fotos von Nahrungspflanzen der Raupen bzw. Eiablagepflanzen
        sind willkommen. Bei Habitatfotos sollte darauf geachtet werden, dass es sich
        nicht nur um einen zufälligen Aufenthaltsort eines Falters, sondern im Idealfall
        um das Larvalhabitat oder ein für die Art auf andere Weise typischen Lebensraum
        (z.B. Gipfelbalzplatz beim Segelfalter) handelt. Gute Fotos der Nahrungspflanzen
        der Raupen sind als Beleg auch hilfreich, vor allem wenn es sich um schwierig zu bestimmende
        Pflanzenfamilien handelt.
      </p>
      <b>Metadaten</b>
      <p>
        Wenn Fotos hochgeladen werden, werden Funddatum und Koordinaten - sofern möglich - aus den Metadaten
        abgeleitet. Dafür ist es wichtig, dass die Einstellungen (z.B. Uhrzeit) in der Kamera
        richtig sind.
      </p>
      <b>Nahrungspflanzen</b>
      <p>
        Für jedes beobachtete Stadium sollte eine separate Beobachtung erstellt werden. Z.B. sollte bei einer
        Eiablage eine Beobachtung für das Weibchen und eine weitere Beobachtung für das Ei erstellt werden,
        da in diesem Fall zwei Stadien gleichzeitig beobachtet werden.
        Beim Eintrag für das Ei kann auch die Ablagepflanze (=Nahrungspflanze) unter
        "Larvalnahrung" eingetragen werden, sofern es sich sehr wahrscheinlich auch
        um die Pflanze handelt, an der die Raupe fressen wird.
        Im Bemerkungsfeld kann festgehalten werden, dass es sich um eine Eiablage
        gehandelt hat.
      </p>
      <p>
        In den Feldern "Nektarpflanzen" und "Larvalnahrung" kann mittels Texteingabe
        in einer Liste von Pflanzennamen (deutsche und wissenschaftliche Namen) gesucht werden.
        Alternativ können auch eigene Einträge hinzugefügt
        werden, wenn auf "Create ..." geklickt wird. Oft kann die Pflanze auch nicht genau bestimmt werden,
        in diesem Fall ist auch die Angabe der Gattung, z.B. "Salix spec." möglich.
        Die Angaben zur Larvalökologie werden auch in der Tabelle auf der Seite "Larvalökologie" für jede Art
        dargestellt.
      </p>
      <p>
        Wenn möglich sollten die wissenschaftlichen Namen verwendet werden.
      </p>
      <p>
        Für jede Nahrungspflanze sollte ebenfalls ein neuer Eintrag erstellt werden, z.B. wenn am Fundort
        drei Raupen von <i>Melitaea didyma</i> an <i>Plantago lanceolata</i> und zwei Raupen an
        <i> Stachys recta</i> gefunden wurden, sollten zwei Beobachtungen erstellt werden.
      </p>
      <b>Datenfreigabe</b>
      <p>
        Beobachtungen werden nach dem Speichern von den Admins im Laufe der nächsten Tage geprüft
        und freigegeben, ggf. die Bestimmung korrigiert. Erst nach Freigabe erscheinen die Beobachtungen
        unter "Alle Beobachtungen". Nach dem Editieren wird eine erneute Freigabe
        notwendig, sofern die Bestimmung geändert wurde oder neue Fotos hochgeladen wurden.
      </p>
      <hr></hr>
      <b>Beobachtungen exportieren</b>
      <p>
        Selbstverständlich können alle eingegebenen Datensätze auch wieder
        exportiert werden. Die Option zum Datenexport findet sich unter
        "Meine Beobachtungen" -&gt; "Weitere Filter".
      </p>
    </>
  );
}

function Beobachtungen() {
  return (
    <>
      <h4>Beobachtungen</h4>
      <hr></hr>
      <h5>Wie kann ich mir die Beobachtungen einer Art anschauen?</h5>
      <br></br>
      <Alert variant={"secondary"}>
        <b>1.</b> Gehen Sie dafür auf die Seite "Beobachtungen" in der Navigationsleiste.
      </Alert>
      <img src={beobachtungen0} alt="Datenerfassung" />
      <br></br>
      <br></br>
      <Alert variant={"secondary"}>
        <b>2. Filter</b><br></br>
        Oben auf der Seite sind verschiedene Filtermöglichkeiten.
        Z.B. kann hier die Art ausgewählt bzw. eingegeben werden (nach wissenschaftlichem
        oder deutschem Namen suchen). Weitere Filter können durch Klick auf "Mehr Filter"
        ausgewählt werden. Dies ermöglicht eine flexible Suche, z.B. in einer bestimmten
        Region, Zeitraum oder Artengruppe (z.B. nur Widderchen).
      </Alert>
      <img src={beobachtungen5} alt="Datenerfassung" />
      <br></br>
      <br></br>
      <img src={beobachtungen2} alt="Datenerfassung" />
      <br></br>
      <br></br>
      <Alert variant={"secondary"}>
        <b>3. Daten abfragen</b><br></br>
        Klicken Sie auf den grünen Button "Daten abfragen".
        Anschließend werden die Daten geladen (je nach Datenmenge dauert es einen Moment) und
        dargestellt.
      </Alert>
      <img src={beobachtungen3} alt="Datenerfassung" />
      <br></br>
      <br></br>
      <Alert variant={"secondary"}>
        <b>4. Ansichten</b><br></br>
        Es gibt verschiedene Ansichten der abgefragten Beobachtungen.
        Standardmäßig werden die Daten in einer Tabelle angezeigt. Hier kann man
        aber auch zur Verbreitungskarte, der Höhenverbreitung, Flugzeit oder den Fotos
        wechseln.
      </Alert>
      <img src={beobachtungen4} alt="Datenerfassung" />
      <br></br>
      <br></br>
      <Alert variant={"warning"}>
        <b>Wichtiger Hinweis:</b><br></br>
        Es werden nur von den Administratoren freigegebene Beobachtungen unter 
        "Alle Beobachtungen" dargestellt. Außerdem werden standardmäßig alle
        unsicheren Beobachtungen ausgeblendet, diese können aber unter "Mehr Filter"
        mitausgewählt werden.
      </Alert>
    </>
  );
}


export default function Benutzungshinweise() {

  return (
    <>
      <br></br>
      <Container className="panel" style={{ "maxWidth": "1200px", width: "100%" }}>
        <Tab.Container id="left-tabs-example" defaultActiveKey="datenerfassung">
          <Row>
            <Col md={4}>
              <h4>Benutzungshinweise</h4>
              <hr></hr>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="datenerfassung">Datenerfassung</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="beobachtungen">Beobachtungen filtern</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md={8}>
              <Tab.Content>
                <Tab.Pane eventKey="datenerfassung">
                  <Datenerfassung />
                </Tab.Pane>
                <Tab.Pane eventKey="beobachtungen">
                  <Beobachtungen />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
      <br></br>
    </>
  );
}
