/**
 * Get number of intervals based on aggregation
 * @param  {String} aggregation Either "pentade", "monat" or "tag_im_jahr"
 * @return {Number} Number of intervals
 */
function getNumberOfIntervals(aggregation = "pentade") {
  let nIntervals;
  switch (aggregation) {
    case "tag_im_jahr":
      nIntervals = 365;
      break;
    case "monat":
      nIntervals = 12;
      break;
    default:
      nIntervals = 72;
  };

  return nIntervals;
}

// console.log(getNumberOfIntervals("pentade"));
// console.log(getNumberOfIntervals("monat"));
// console.log(getNumberOfIntervals("tag_im_jahr"));


/**
 * Create x axis options for phenogram chart
 * @param  {String} xAxis Either "pentade", "monat" or "tag_im_jahr"
 * @return {Object} x axis options to be included in highcharts plot
 */
export function getXAxisOptions(xAxis = "pentade") {
  let step;
  let positions;
  let tickInterval;
  switch (xAxis) {
    case "tag_im_jahr":
      positions = Array.from({ length: 12 }, (_, i) => 15 + 30 * i);
      tickInterval = 15;
      step = 30;
      break;
    case "monat":
      positions = Array.from(Array(12).keys());
      tickInterval = 1;
      step = 1;
      break;
    default:
      positions = Array.from({ length: 12 }, (_, i) => 2 + 6 * i);
      tickInterval = 2;
      step = 6;
  };

  const nIntervals = getNumberOfIntervals(xAxis);

  // Define positions for month labels on the x-axis
  const categories = Array.from(new Array(nIntervals), (val, index) => index);
  // const months = [["Jan"], ["Feb"], ["Mar"], ["Apr"], ["Mai"], ["Jun"],
  // ["Jul"], ["Aug"], ["Sep"], ["Okt"], ["Nov"], ["Dez"]];
  // Use short labels so that labels are shown on smaller screens as well
  const months = [["J"], ["F"], ["M"], ["A"], ["M"], ["J"],
  ["J"], ["A"], ["S"], ["O"], ["N"], ["D"]];
  let monthLabels = Array(nIntervals).fill("");
  for (let i = 0; i < months.length; i++) {
    monthLabels[positions[i]] = months[i][0];
  }

  // Define the placements of grid lines
  let gridLines = [];
  if (xAxis === "tag_im_jahr") {
    const gridLinePositions = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334, 365];
    for (let i = 0; i < (months.length + 1); i++) {
      gridLines.push({
        color: 'lightgrey',
        value: gridLinePositions[i] - 0.5
      })
    }
  } else {
    for (let i = 0; i < (months.length + 1); i++) {
      gridLines.push({
        color: 'lightgrey',
        value: (step * i) - 0.5
      })
    }
  }

  return {
    // title: {
    //   enabled: false,
    //   text: 'Monat'
    // },
    categories: categories,
    tickInterval: tickInterval,
    labels: {
      rotation: 0,
      formatter: function () { return monthLabels[this.value]; },
    },
    min: 0,
    max: nIntervals,
    plotLines: gridLines
  }
}

// console.log(getXAxisOptions("pentade"));
// console.log(getXAxisOptions("monat"));
// console.log(getXAxisOptions("tag_im_jahr"));


/**
 * Count number of occurences of each element in an array
 * @param  {Array} data Data
 * @param  {String} aggregation Name of grouping variable, e.g. "pentade", "monat" or "tag_im_jahr"
 * @param  {Number} nIntervals Length of array, e.g. 72 for aggregation="pentade"
 * @param  {String} y If "beobachtungen" then each row is counted as 1, else colAnzahl is used
 * @param  {String} colAnzahl Name of column to aggregate, e.g. "anzahl"
 * @return {Array} Array of length nIntervals with counts
 */
export function aggregateSightings(data, { aggregation = "pentade", nIntervals = 72, y = "beobachtungen", colAnzahl = "anzahl" }) {

  let counts = [];
  for (let i = 0; i < nIntervals; i++) {
    counts[i] = 0;
  }

  for (let i = 0; i < data.length; i++) {
    let anzahl = (y === "beobachtungen" && data[i][colAnzahl] > 0) ? 1 : data[i][colAnzahl];
    let num = data[i][aggregation];
    let idx = num - 1;
    counts[idx] = counts[idx] ? counts[idx] + anzahl : anzahl;
  }

  return counts
}

/**
* Count number of occurences of each element in an array
* Similar to aggregateSightings but automatically determines array length
* @param  {Array} data Data
* @param  {String} aggregation Name of grouping variable, e.g. "pentade", "monat" or "tag_im_jahr"
* @param  {String} y If "beobachtungen" then each row is counted as 1, else colAnzahl is used
* @param  {String} colAnzahl Name of column to aggregate, e.g. "anzahl"
* @return {Array} Array with counts
*/
export function aggregateSightingsByRange(data, { aggregation = "pentade", y = "beobachtungen", colAnzahl = "anzahl" }) {
  const nIntervals = getNumberOfIntervals(aggregation);
  const counts = aggregateSightings(data, { aggregation: aggregation, nIntervals: nIntervals, y: y, colAnzahl: colAnzahl })
  return (counts);
}
