import React from "react";
import { ThreeDots } from "react-loader-spinner";

function Loading(props) {
  return(
    <div style={{
      width: "100%",
      height: props.height ?? "80vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <ThreeDots color="#2BAD60" height="100" width="100" />
    </div>
  );
}

export default Loading;
