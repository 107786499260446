import config from '../config/index.js';

function getColorByYear(year) {
  let fillColor;
  if (year >= config.ZEITRAUM.JAHR1) {
    fillColor = "black";
  } else if (year < config.ZEITRAUM.JAHR1 && year >= config.ZEITRAUM.JAHR2) {
    fillColor = "#808080";
  } else if (year < config.ZEITRAUM.JAHR2 && year >= config.ZEITRAUM.JAHR3) {
    fillColor = "#C0C0C0";
  } else {
    fillColor = "white";
  }
  return(fillColor)
}

export default getColorByYear;
