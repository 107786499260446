import React from 'react';
import { Radio } from 'pretty-checkbox-react';


export default function PhenogramOptions({ xAxis, yAxis }) {

  return (
    <>
      <p>Optionen für die Darstellung der Phänologie</p>
      <h6>x-Achse</h6>
      <Radio color="primary" name="aggregation" value="tag_im_jahr" {...xAxis}>Tage</Radio>
      <Radio color="primary" name="aggregation" value="pentade" {...xAxis}>Pentaden</Radio>
      <Radio color="primary" name="aggregation" value="monat" {...xAxis}>Monate</Radio>
      <br></br>
      <br></br>
      <h6>y-Achse</h6>
      <Radio color="primary" name="y-axis" value="beobachtungen" {...yAxis}>Anzahl Beobachtungen</Radio>
      <Radio color="primary" name="y-axis" value="individuen" {...yAxis}>Anzahl Individuen</Radio>
    </>
  )
}