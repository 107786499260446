import React from 'react';
import ReactTable from '../ReactTable';
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { id, foto, art, created_at } from "../TableColumns";

function ObservationTable(props) {

  const data = React.useMemo(
    () =>
      props.observations,
    [props.observations]
  );

  const columns = React.useMemo(
    () => [
      id,
      foto,
      art,
      {
        Header: 'Stadium',
        accessor: 'stadium'
      },
      {
        Header: 'Anzahl',
        accessor: 'anzahl'
      },
      {
        Header: 'Beobachter',
        accessor: 'beobachter',
      },
      {
        Header: 'Datum',
        accessor: 'datum',
      },
      {
        Header: 'TK25 Quadrant',
        accessor: 'tk25_quadrant',
      },
      created_at
    ],
    []
  );

  return (

    <SimpleReactLightbox>
      <SRLWrapper>
        <ReactTable data={data} columns={columns}></ReactTable>
      </SRLWrapper>
    </SimpleReactLightbox>
  )
}

export default ObservationTable;
