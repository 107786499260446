// Configuration for Schmetterlinge Bayern
export const links = [
  { url: "https://www.abe-entomofaunistik.org", text: "Arbeitsgemeinschaft Bayerischer Entomologen (ABE)" },
  { url: "https://www.tagfalter-thueringen.de", text: "Tagfalter in Thüringen" },
  { url: "http://www.lepiforum.de", text: "Lepiforum" },
  { url: "https://www.schmetterlinge-d.de/", text: "Schmetterlinge Deutschland" },
  { url: "https://www.schmetterlinge-bw.de/", text: "Schmetterlinge Baden Württemberg" },
  { url: "https://www.schmetterlinge-brandenburg-berlin.de/", text: "Schmetterlinge in Brandenburg und Berlin" }
];

export const admins = [
  "Julian Bittermann",
  "Oliver Böck",
  "Markus Dumke",
  "Thomas Netter",
  "Alexander Ohr",
  "Maximilian Schmucker",
  "Korbinian Schrauth",
  "Herbert Stadelmann (Allgäu)",
  "Werner Wolf"
];
