import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PhenogramTabs from '../Phenogram/PhenogramTabs';
import Altitude from '../Beobachtungen/Altitude';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { sortBy, orderBy, uniqBy } from "lodash";
import LarvaloekologieTable from "../LarvaloekologieTable";
import ObservationTable from '../Beobachtungen/ObservationTable';
// import observations from '../../data/artportrait/data_satyrium_ilicis.json';
// import taxonArr from '../../data/artportrait/satyrium_ilicis.json';
import Map from '../Map/MapArtportrait';
import Fotogalerie from '../Beobachtungen/Fotogalerie';
import EntwicklungChart from '../Beobachtungen/EntwicklungChart';
import Links from './Links';
import Statistik from './Statistik';
import Navigation from './Navigation';
// import Systematik from './Systematik';
import axios from "../AxiosConfig";
import config from '../../config/index.js';
import { LeafletLegendZeitraum } from "../Map/LeafletLegend";
import Loading from '../Loading.js';

// Bestimmung
// Verbreitung
// Höhenverbreitung
// Lebensraum
// Fotos mit Bildunterschriften
// Falternahrung, Verhalten
// Larvalökologie
// Flugzeit
// Anzahl Beobachtungen, TK25

export default function Artportrait({ artQuery }) {

  const art = artQuery.charAt(0).toUpperCase() + artQuery.substr(1).replace("_", " ");
  console.log(art)

  // State
  const [taxon, setTaxon] = useState();
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [observations, setObservations] = useState([]);
  const [observationsAtlas, setObservationsAtlas] = useState([]);
  const [larvalnachweise, setLarvalnachweise] = useState([]);

  const fotosArt = images.slice(0, 5).map((row) => {
    return (
      {
        original: row.foto,
        thumbnail: row.foto,
        description: <small>&#169; {row.beobachter}</small>,
      }
    )
  });

  const getTaxon = async (art) => {
    const response = await axios.get(`arten/get?art=${art}&artengruppe=${config.ARTENGRUPPE}`);
    setTaxon(response.data[0]);
    console.log(response.data)
    setLoading(false);
  };

  const getImages = async (art) => {
    const response = await axios.get(`galerie?art=${art}&bundesland=${config.BUNDESLAND}`);
    setImages(response.data);
    console.log(response.data);
  };

  const getObservations = async (art) => {
    const response = await axios.get(
      `observations/get?art=${art}&bundesland=${config.BUNDESLAND}&bestimmungssicherheit=sicher`
    );
    setObservations(response.data);
    // console.log(response.data);
  };

  const getLarvalnachweise = async (art) => {
    const response = await axios.get(`larvalnachweise?art=${art}&bundesland=${config.BUNDESLAND}`);
    setLarvalnachweise(response.data);
  };

  const getObservationsAtlas = async (art) => {
    const response = await axios.get(`observations/atlas?art=`.concat(art));
    setObservationsAtlas(response.data);
  };

  useEffect(() => {
    getTaxon(art);
    getImages(art);
    getObservations(art);
    getLarvalnachweise(art);
    if (config.BUNDESLAND === "Bayern") {
      getObservationsAtlas(art);
    }
  }, [art]);

  const observationsSorted = orderBy(observations, { state: "datum" }, { state: "desc" });
  const uniqueObservations = uniqBy(observationsSorted, "id");

  const larvalnachweiseUnique = uniqBy(larvalnachweise, "id");
  const larvalnachweiseSorted =
    sortBy(larvalnachweise, ["stadium"]).filter((observation) => {
      return (observation.foto !== undefined && observation.foto !== null)
    });;
  console.log(larvalnachweiseSorted)

  const observationsWithImages = observationsSorted.filter((observation) => {
    return (observation.foto !== undefined && observation.foto !== null)
  }).map((x) => {
    x["stadium"] = x["stadium"] in ["Larve", "Raupennest"] ? "Larve" : x["stadium"];
    return x;
  });
  const observationsImagesNoDups = uniqBy(observationsWithImages, "foto");
  console.log(uniqueObservations)
  const nObservations = uniqueObservations.length;
  const nTK25 = [...new Set(uniqueObservations.map((x) => x.tk25))].length;
  const nFotos = observationsWithImages.length;

  const counts = uniqueObservations.reduce((p, c) => {
    const name = c.jahr;
    if (!p.hasOwnProperty(name)) {
      p[name] = {
        jahr: c.jahr,
        beobachtungen: 0,
        individuen: 0,
        tk25: [c.tk25],
        erstsichtung_am: null,
      };
    }
    if (!p[name]["tk25"].includes(c.tk25)) {
      p[name]["tk25"].push(c.tk25)
    }
    if (p[name].erstsichtung_am === null && c.datum && c.datum.length > 4 && c.stadium === "Imago") {
      p[name]["erstsichtung_am"] = c.datum;
    }
    if (c.stadium === "Imago" && c.datum && c.datum.length > 4 && c.datum < p[name]["erstsichtung_am"]) {
      p[name]["erstsichtung_am"] = c.datum;
    }
    p[name]["beobachtungen"]++;
    p[name]["individuen"] += c.anzahl;
    return p;
  }, {});

  const countsExtended = Object.keys(counts).map((k, i) => {
    return {
      id: i + 1,
      jahr: counts[k].jahr,
      erstsichtung_am: counts[k].erstsichtung_am,
      beobachtungen: counts[k].beobachtungen,
      individuen: counts[k].individuen,
      tk25: counts[k].tk25.length,
    };
  });

  const countsOrdered = orderBy(countsExtended, "jahr", "desc");
  console.log(countsOrdered);

  let imageGalleryElement = <></>
  if (fotosArt.length > 0) {
    imageGalleryElement = <ImageGallery items={fotosArt} showNav={false} showPlayButton={false} slideDuration={0} />
  } else {
    imageGalleryElement = <p style={{ color: "grey" }}>Es wurden keine Fotos gefunden.</p>
  }

  return (
    loading ?
      <Loading /> :
      <div>
        <br></br>
        <Container>
          <div className="panel" style={{ maxWidth: 1200, margin: "auto" }}>
            <Row>
              <Col className="col-padding" xs={7}>
                <h3>
                  <i> {taxon.art}{" "}</i>
                  <small>
                    {taxon.autoren}
                  </small>
                  {/* <small> {taxon.autor}{" "}
                  <Badge variant="warning">RL D: 2</Badge>{" "}
                  <Badge variant="warning">RL BY: 2</Badge>
                </small> */}
                </h3>
                <h5 style={{ color: "darkgrey" }}>{taxon.deutscher_name}</h5>
                {/* <Systematik taxon={taxon} /> */}
              </Col>
              <Col className="col-padding">
                <Navigation taxon={taxon} />
              </Col>
            </Row>
          </div>
          <div style={{ maxWidth: 1200, margin: "auto" }}>
            {/* <hr></hr> */}
            <br></br>
            <Row>
              <Col className="col-padding">
                {imageGalleryElement}
              </Col>
              <Col className="col-padding">
                <Map
                  observations={observations}
                  observationsAtlas={observationsAtlas}
                />
              </Col>
            </Row>
          </div>
          <br></br>
          <div className="panel" style={{ maxWidth: 1200, margin: "auto" }}>
            <Row>
              <Col className="col-padding">
                <Tabs defaultActiveKey="statistik" id="tabs-stats" >
                  <Tab eventKey="statistik" title="Statistik">
                    <Statistik beobachtungen={nObservations} tk25={nTK25} fotos={nFotos} />
                    <h5>Legende Karte</h5>
                    <LeafletLegendZeitraum />
                    <br></br>
                  </Tab>
                  <Tab eventKey="entwicklung" title="Entwicklung">
                    <EntwicklungChart data={countsOrdered} />
                  </Tab>
                  <Tab eventKey="hoehe" title="Höhe">
                    <Altitude observations={observations} />
                  </Tab>
                </Tabs>
              </Col>
              <Col className="col-padding">
                <PhenogramTabs observations={uniqueObservations} />
              </Col>
            </Row>
          </div>
          <br></br>
          <div className="panel" style={{ maxWidth: 1200, margin: "auto" }}>
            <Row>
              <Col className="col-padding">
                <Tabs defaultActiveKey="beobachtungen" id="tabs-content">
                  <Tab eventKey="beobachtungen" title="Beobachtungen">
                    <ObservationTable observations={uniqueObservations} />
                  </Tab>
                  <Tab eventKey="fotos" title="Fotos">
                    <Fotogalerie observations={observationsImagesNoDups} imagesPerPage={20} />
                  </Tab>
                  <Tab eventKey="larvalnahrung" title="Nahrungspflanzen">
                    <LarvaloekologieTable observations={larvalnachweiseUnique} />
                    <Fotogalerie observations={larvalnachweiseSorted} imagesPerPage={20} />
                  </Tab>
                  <Tab eventKey="information" title="Information">
                    <Row>
                      <Col md={4}>
                        <Links taxon={taxon} />
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </div>
        </Container>
        <br></br>
      </div>
  )
}
