import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useRadioState } from 'pretty-checkbox-react';
import PhenogramStadium from './PhenogramStadium';
import PhenogramSex from './PhenogramSex';
import PhenogramImago from './PhenogramImago';
import PhenogramOptions from './PhenogramOptions';


export default function PhenogramTabs({ observations }) {
  const x = useRadioState({ state: "pentade" });
  const y = useRadioState({ state: "beobachtungen" });

  // Filter observations by the selected stadium and exclude Zucht and Totfund/Diapause
  const observationsPheno = observations.filter((observation, index) => {
    return (
      observation.datum &&
      observation.datum.length > 4 &&
      observation.zustand !== "Totfund" &&
      observation.zustand !== "Diapause" &&
      observation.art_der_beobachtung !== "Zucht"
    );
  });

  return (
    <>
      <Tabs defaultActiveKey="flugzeit" id="tabs-pheno" >
        {/* <PhenogramTabs observations={uniqueObservations} /> */}
        <Tab eventKey="flugzeit" title="Flugzeit">
          <PhenogramImago observations={observationsPheno} x={x.state} y={y.state} />
        </Tab>
        <Tab eventKey="geschlecht" title="Geschlecht">
          <PhenogramSex observations={observationsPheno} x={x.state} y={y.state} />
        </Tab>
        <Tab eventKey="stadium" title="Stadium">
          <PhenogramStadium observations={observationsPheno} x={x.state} y={y.state} />
        </Tab>
        <Tab eventKey="Optionen" title="Optionen">
          <PhenogramOptions xAxis={x} yAxis={y} />
        </Tab>
      </Tabs>
    </>
  )
}
