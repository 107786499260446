import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LetzteBeobachtungen from './LetzteBeobachtungen';
import KontaktSchmetterlingeBayern from "./KontaktSchmetterlingeBayern";
import KontaktTagfalterThueringen from "./KontaktTagfalterThueringen";
import NeuigkeitenBayern from './NeuigkeitenTagfalterBayern';
import NeuigkeitenTagfalterThueringen from './NeuigkeitenTagfalterThueringen';
import ProjektbeschreibungSchmetterlingeBayern from './ProjektbeschreibungSchmetterlingeBayern';
import ProjektbeschreibungTagfalterBayern from './ProjektbeschreibungTagfalterBayern';
import ProjektbeschreibungTagfalterThueringen from './ProjektbeschreibungTagfalterThueringen';
import config from '../../config/index.js';
import FotosHomepageSchmetterlingeBayern from './FotosHomepageSchmetterlingeBayern';
import FotosHomepageTagfalterBayern from './FotosHomepageTagfalterBayern';
import FotosHomepageTagfalterThueringen from './FotosHomepageTagfalterThueringen';

let Neuigkeiten;
let Projektbeschreibung;
let Kontakt;
let FotosHomepage;

if (config.BUNDESLAND === "Bayern") {
  Neuigkeiten = NeuigkeitenBayern;
  Kontakt = KontaktSchmetterlingeBayern;
}

if (config.BUNDESLAND === "Thüringen") {
  Neuigkeiten = NeuigkeitenTagfalterThueringen;
  Projektbeschreibung = ProjektbeschreibungTagfalterThueringen;
  Kontakt = KontaktTagfalterThueringen;
  FotosHomepage = FotosHomepageTagfalterThueringen;
}

if (config.BUNDESLAND === "Bayern" && config.ARTENGRUPPE === "Tagfalter") {
  Projektbeschreibung = ProjektbeschreibungTagfalterBayern;
  FotosHomepage = FotosHomepageTagfalterBayern;
}

if (config.BUNDESLAND === "Bayern" && config.ARTENGRUPPE === "Lepidoptera") {
  Projektbeschreibung = ProjektbeschreibungSchmetterlingeBayern;
  FotosHomepage = FotosHomepageSchmetterlingeBayern;
}


function Homepage() {

  return (
    <Container>
      <Row>
        <Col>
          <Projektbeschreibung />
          <LetzteBeobachtungen />
        </Col>
        <Col>
          <FotosHomepage />
          <Neuigkeiten />
          <Kontakt />
        </Col>
      </Row>
      <br></br>
    </Container >
  )
}

export default Homepage;
