import React from 'react';
import InputArt from '../Inputs/InputArt2';
import { useNavigate } from 'react-router-dom';


export default function Artsuche() {
  const navigate = useNavigate();
  const onChange = (value) => {
    if (value) {
      navigate(`/art?art=${value.value}`, { replace: true });
    }
  }

  return (
    <InputArt onChange={onChange} />
  )
}
