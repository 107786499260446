import React from "react";
import { MapContainer, Pane } from 'react-leaflet'
import LayerControl from './LayerControl';
import { orderBy, uniqBy } from 'lodash';
import TK25Pane from "./TK25Pane";
import ObservationCircles from "./ObservationCircles";
import bundeslandMittelpunkte from "../../data/gadm/bundesland_mittelpunkt.json";
import AtlasRectangles from "./AtlasRectangles";
import config from '../../config/index.js';

const bundeslandMittelpunkt =
  bundeslandMittelpunkte.filter((bl) => (bl.Bundesland === config.BUNDESLAND));

const bundeslandMittelpunktLat = bundeslandMittelpunkt[0].latitude;
const bundeslandMittelpunktLng = bundeslandMittelpunkt[0].longitude;
const bundeslandZoomLevel = bundeslandMittelpunkt[0].zoom;

// function uniqueObservationByBeobachter(arr) {
//   let clean = arr.filter((arr, index, self) =>
//     index === self.findIndex((t) => (t.beobachter === arr.beobachter && t.art === arr.art && t.jahr === arr.jahr)))
//   return clean
// }

export default function Map(props) {

  const observationsWithCoords = uniqBy(orderBy(props.observations, "jahr", "desc"), "tk25_quadrant").filter((row) => {
    return (row.center_lat !== null && row.center_lng !== null);
  });

  return (
    <MapContainer
      className={"map-artportrait"}
      center={[bundeslandMittelpunktLat, bundeslandMittelpunktLng]}
      zoom={bundeslandZoomLevel}
      scrollWheelZoom={true}
      fullscreenControl={true}
      whenCreated={props.changeMapRef}>
      <LayerControl />
      <TK25Pane // TODO
        quadranten={{ state: false }}
        radioTK25={{ state: "bundesland" }}
        handleMapHover={() => { }}
      />
      <AtlasRectangles
        observationsAtlas={props.observationsAtlas}
      />
      <Pane name="neuedaten" style={{ zIndex: 404 }}>
        <ObservationCircles
          observations={observationsWithCoords}
          handleMapClick={() => { }}
          pane="neuedatenTK25" />
      </Pane>
    </MapContainer>
  )
}
