import React from "react";
import { Link } from 'react-router-dom';
import FreigabeIcon from './FreigabeIcon';

export const id = {
  Header: 'ID',
  accessor: 'id',
  Cell: ({ cell: { value } }) => <Link to={"/beobachtung?id=".concat(value)} rel="noopener noreferrer" target="_blank">{value}</Link>
  // Cell: ({ cell: { value } }) => <a href={"beobachtungen=".concat(value)}>{value}</a>
}

export const foto = {
  Header: 'Foto',
  accessor: 'foto',
  // Cell method will provide the cell value; we pass it to render a custom component
  Cell: ({ cell: { value } }) => <img src={value} alt="" width={120}></img>
}

export const art = {
  Header: 'Art',
  accessor: 'art',
  Cell: row => {
    return (
      <p>
        <i>{row.row.original.art}</i>
        <small style={{ color: "#A9A9A9" }}> | {row.row.original.deutscher_name}</small>
      </p>
    )
  }
}

export const created_at = {
  Header: 'Hinzugefügt am',
  accessor: 'created_at',
  Cell: ({ cell: { value } }) => <p style={{ color: "grey" }}>{value}</p>
}

export const freigabe =       {
  Header: "",
  accessor: "freigegeben_am",
  Cell: row => {
    return (
      <FreigabeIcon observation={row.row.original} />
    )
  }
}
