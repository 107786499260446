
import React from "react";
import validation1 from "../icons/validation1.png";
import validation6 from "../icons/validation6.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import toGermanDate from "../functions/toGermanDate";

function FreigabeIcon(props) {

  const tooltip = props.observation.freigegeben_am ?
    "Freigegeben am " + toGermanDate(props.observation.freigegeben_am) + " durch " + props.observation.freigeber :
    "Noch nicht freigegeben";

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <img
        src={props.observation.freigegeben_am ? validation1 : validation6}
        alt=""
      >
      </img>
    </OverlayTrigger>
  )
}

export default FreigabeIcon;
