import React from 'react';
import config from '../config/index.js';

function Footer() {

  const titel = config.TITEL;
  const email = config.EMAIL;

  return (
    <div className="footer">
      <p className="footer-div">
        <span>
          {titel} - Arbeitsgemeinschaft Bayerischer Entomologen e.V. -
          <a href="/impressum"> Impressum </a>
           - Kontakt:{" "}
           <a href={`mailto:${email}`}>{email}</a>
        </span>
      </p>
    </div>
  )
}

export default Footer;
