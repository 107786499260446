import React, { useState } from "react";
import { Formik } from 'formik';
import Container from "react-bootstrap/Container";
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import axios from "./AxiosConfig";
import InputArt from './Inputs/InputArt';
import LarvaloekologieTable from "./LarvaloekologieTable";
import config from '../config/index.js';


export default function Larvaloekologie() {

  const [observations, setObservations] = useState([]);

  const getObservations = async (art) => {
    const response = await axios.get(`larvalnachweise?art=${art}&bundesland=${config.BUNDESLAND}`);
    setObservations(response.data);
    console.log(response.data);
  };

  // Create array with only one object per observation id
  let id = [];
  const uniqueObservations = observations.filter((observation) => {
    if (!id.includes(observation.id)) {
      id.push(observation.id);
      return (observation)
    } else {
      return null;
    }
  });

  return (
    <Formik
      initialValues={{
        art: null
      }}

      onSubmit={
        (values) => {
          console.log("get images");
          // load images using axios and display
          getObservations(values.art.value);
        }
      }
    >
      {
        props => {
          const {
            // values,
            // handleChange,
            handleSubmit,
            // handleBlur,
            // setFieldValue
          } = props;
          return (
            <div>
              <Container>
                <br></br>
                <h3>Larvalökologie</h3>
                <p style={{ color: "grey" }}>Nachgewiesene Raupennahrungspflanzen und Eiablagepflanzen in {config.BUNDESLAND}.</p>
                <Form style={{ maxWidth: "400px" }} onSubmit={handleSubmit}>
                  <InputArt name="art" onChange={handleSubmit}></InputArt>
                </Form>
                <hr></hr>
                <LarvaloekologieTable observations={uniqueObservations} />
              </Container>
              <br></br>
            </div>
          );
        }
      }
    </Formik>
  )
}
