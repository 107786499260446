import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-hot-toast';
import { NeueBeobachtungUI } from './NeueBeobachtungUI.js';
import axios from '../AxiosConfig.js';
import { validateForm, createObservation, createFormData } from './formHelpers.js';
import Loading from '../Loading.js';


const initializeFormValues = (observation) => {
  return {
    zustand: observation[0].zustand ? { value: observation[0].zustand, label: observation[0].zustand } : "",
    stadium: { value: observation[0].stadium, label: observation[0].stadium },
    bemerkungen: observation[0].bemerkungen ? observation[0].bemerkungen : "",
    anzahl: observation[0].anzahl,
    anzahl_m: observation[0].anzahl_m,
    anzahl_w: observation[0].anzahl_w,
    nektarpflanzen: observation[0].nektarpflanzen ? [{ value: observation[0].nektarpflanzen, label: observation[0].nektarpflanzen }] : "",
    larvalnahrung: observation[0].larvalnahrung ? { value: observation[0].larvalnahrung, label: observation[0].larvalnahrung } : "",
    datum: observation[0].datum,
    art: { value: observation[0].art, label: observation[0].art },
    beobachter: observation[0].beobachter,
    fundort: observation[0].fundort ? { value: observation[0].id_fundort, label: observation[0].fundort } : "",
    id_fundort: observation[0].id_fundort,
    latitude: observation[0].latitude,
    longitude: observation[0].longitude,
    unschaerfe: observation[0].unschaerfe ? observation[0].unschaerfe : 100,
    bestimmer: observation[0].bestimmer,
    begleiter: observation[0].begleiter,
    datenquelle: observation[0].datenquelle ? { value: observation[0].datenquelle, label: observation[0].datenquelle } : "",
    datenquelle_bemerkung: observation[0].datenquelle_bemerkung ? observation[0].datenquelle_bemerkung : "",
    beleg: observation[0].beleg ? { value: observation[0].beleg, label: observation[0].beleg } : "",
    art_der_beobachtung: observation[0].art_der_beobachtung ? { value: observation[0].art_der_beobachtung, label: observation[0].art_der_beobachtung } : "",
    bestimmungsmethode: observation[0].bestimmungsmethode ? { value: observation[0].bestimmungsmethode, label: observation[0].bestimmungsmethode } : "",
    bestimmungssicherheit: observation[0].bestimmungssicherheit ? { value: observation[0].bestimmungssicherheit, label: observation[0].bestimmungssicherheit } : "",
  };
};


export default function EditObservation(id) {

  const edit = true;
  const [observation, setObservation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [map, setMap] = useState(null);
  const [files, setFiles] = useState([]);
  const [imgCollection, setImgCollection] = useState('');
  const [source, setSource] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const [loadingAfterEdit, setLoadingAfterEdit] = useState(false);
  const drawLayer = useRef(null);


  const getObservation = async (id) => {
    const response = await axios.get(`observation/get?id=`.concat(id));
    console.log(response.data);
    setObservation(response.data.observation);
    if (response.data.observation[0].foto !== null) {
      // console.log(response.data.observation[0].foto);
      const fotoUrls = response.data.observation.map(a => {
        return (a.foto.replace("storage.googleapis.com/schmetterlingebayern",
          "schmetterlingebayern.storage.googleapis.com"));
      });
      // console.log(response.data.observation[0].bemerkungen);
      console.log(fotoUrls);
      // There is a CORS issue with external images (e.g. from observation.org)
      // therefore we check if it is in our cloud storage bucket 
      // and otherwise do not show images
      if (fotoUrls[0].match("schmetterlingebayern")) {
        setFiles(fotoUrls);
      }
      // setInitialFiles(fotoUrls);
    }
    setLoading(false);
    // cb(response.data.observation[0].created_by);
  };

  useEffect(() => {
    console.log(id.id);
    console.log("get observation");
    getObservation(id = id.id);
  }, []);

  return (
    <>
      {
        loading ?
          <Loading /> :
          <Formik
            initialValues={initializeFormValues(observation)}
            onSubmit={
              (values, { resetForm }) => {
                // alert(JSON.stringify(values, null, 2));
                let error = validateForm(values);
                if (!error) {
                  console.log(values);
                  let newObservation = createObservation(values, edit, files, observation);

                  console.log(newObservation);
                  let formData = createFormData(newObservation, imgCollection, source, edit);

                  setButtonDisabled(true);
                  setLoading(true);
                  setLoadingAfterEdit(true);
                  const axiosPromise = axios.post(`observation/edit`, formData, {
                  }).then(res => {
                    setButtonDisabled(false);
                    console.log(res.data);
                    // setTimeout(
                    //   () => {
                    setLoading(false);
                    setLoadingAfterEdit(false);
                    navigate("/beobachtung?id=".concat(id.id), { replace: true });
                    //     2000
                    // );
                  }).catch(function (error) {
                    setButtonDisabled(false);
                    if (error.response) {
                      // Request made and server responded
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                    } else if (error.request) {
                      // The request was made but no response was received
                      console.log(error.request);
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      console.log('Error', error.message);
                    }
                  });

                  toast.promise(axiosPromise, {
                    loading: 'Änderungen werden gespeichert.',
                    success: 'Beobachtung wurde geändert.',
                    error: 'Ein Fehler ist aufgetreten.',
                  });
                }
              }
            }
          >
            {
              props => {
                const {
                  values,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  setFieldValue
                } = props;
                return (
                  <NeueBeobachtungUI
                    values={values}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    map={map}
                    setMap={setMap}
                    files={files}
                    setFiles={setFiles}
                    buttonDisabled={buttonDisabled}
                    imgCollection={imgCollection}
                    setImgCollection={setImgCollection}
                    setSource={setSource}
                    id={id.id}
                    edit={edit}
                    drawLayer={drawLayer}
                    mapCenter={[values.latitude, values.longitude]}
                    mapZoom={15}
                    createdBy={observation[0].created_by}
                  />
                )
              }
            }
          </Formik >
      }
    </>
  );
}
