import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';
import BaseMap from '../Map/BaseMap';
import ImageGallery from 'react-image-gallery';
// import axios from 'axios';
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import "react-image-gallery/styles/css/image-gallery.css";
import toGermanDate from "../../functions/toGermanDate";
import Loading from "../Loading";


function BeobachtungPublic(observationArg) {

  console.log(observationArg);
  const observation = observationArg.observation;

  // console.log("id:".concat(id));

  // const [observation, setObservation] = useState(null);

  // const getObservation = async (id) => {
  //   const response = await axios.get(`observation/`.concat(id));
  //   console.log(response.data);
  //   setObservation(response.data);
  // };

  // useEffect(() => {
  //   console.log("useEffect");
  //   getObservation(id);
  // }, []);

  // // TODO: Catch if observation does not exist

  // // console.log(observation[0].datum);

  console.log(observation);

  // div that contains images if there are any
  let imagePlaceholder;
  if (observation !== null && observation.length > 0) {
    // let nImages = observation.length;
    let images = observation.map(function (observation) {
      return (
        {
          original: observation.foto,
          thumbnail: observation.foto
        }
      )
    });
    if (observation[0].foto !== null) {
      imagePlaceholder = <div>
        {
          observation.length > 1 ?
            <ImageGallery items={images} showNav={false} showPlayButton={false} slideDuration={0} /> :
            <SimpleReactLightbox>
              <SRLWrapper>
                <img src={observation[0].foto} alt={observation[0].art}></img>
              </SRLWrapper>
            </SimpleReactLightbox>
          // <img src={observation.foto} alt={observation.art}></img>
        }
        <br></br>
        <h4>Details</h4>
      </div>
    } else {
      imagePlaceholder = <div></div>
    }
  }

  const variant = observation[0].bestimmungssicherheit === "sicher" ? "success" : "warning";

  const licenses = ["CC-BY", "CC-BY-NC", "CC-BY-NC-ND", "CC-BY-NC_SA", "CC-BY-ND", "CC-BY-SA"];

  return (
    observation ?
      <Container>
        <Row>
          <Col md={6} xs={12}>
            <br></br>
            <Container className="panel">
              <h3>
                {/* <BeobachtungHeader observation={observation[0]}></BeobachtungHeader> */}
                <i>{observation[0].art}</i>
                <div className="hide-small-screen">
                  <Badge style={{ float: "right", fontSize: "50%" }} bg={variant}>Bestimmung: {observation[0].bestimmungssicherheit}</Badge>
                </div>
              </h3>
              <h5 className="color-grey"> {observation[0].deutscher_name}
              </h5>
              <hr></hr>
              {imagePlaceholder}
              <Table responsive hover size="sm">
                <tbody>
                  <tr>
                    <th>Stadium</th>
                    <td>{observation[0].stadium}</td>
                  </tr>
                  <tr>
                    <th>Anzahl</th>
                    <td>{observation[0].anzahl}</td>
                  </tr>
                  <tr>
                    <th>Beobachter</th>
                    <td>{observation[0].beobachter}</td>
                  </tr>
                  {
                    observation[0].datum.length > 0 ?
                      <tr>
                        <th>Datum</th>
                        <td>{toGermanDate(observation[0].datum)}</td>
                      </tr> :
                      <tr>
                        <th>Jahr</th>
                        <td>{observation[0].datum}</td>
                      </tr>
                  }
                  {
                    observation[0].stadium === "Imago" ?
                      <tr>
                        <th>Nektarpflanzen</th>
                        <td><i>{observation[0].nektarpflanzen}</i></td>
                      </tr> :
                      <tr>
                        <th>Larvalnahrung</th>
                        <td><i>{observation[0].larvalnahrung}</i></td>
                      </tr>
                  }
                  <tr>
                    <th>Zustand</th>
                    <td>{observation[0].zustand}</td>
                  </tr>
                  <tr>
                    <th>ID</th>
                    <td>{observation[0].id}</td>
                  </tr>
                  {observation[0].url ? <tr>
                    <th>URL</th>
                    <td><a rel="noopener noreferrer" target="_blank" href={observation[0].url} >{observation[0].url}</a></td>
                  </tr> : null}
                  {observation[0].lizenz ? <tr>
                    <th>Lizenz</th>
                    <td>
                      {licenses.includes(observation[0].lizenz) ?
                        <a rel="noopener noreferrer" target="_blank" href={`https://creativecommons.org/licenses/${observation[0].lizenz.replace("CC-", "")}/4.0/`}>{observation[0].lizenz}</a> :
                        observation[0].lizenz}
                    </td>
                  </tr> : null}
                </tbody>
              </Table>
            </Container>
          </Col>
          <Col md={6} xs={12}>
            <br></br>
            <Container className="panel">
              <BaseMap observation={observation[0]} className="map-small"></BaseMap>
              <br></br>
              <Table responsive hover size="sm">
                <tbody>
                  <tr>
                    <th>Bundesland</th>
                    <td>{observation[0].bundesland}</td>
                  </tr>
                  <tr>
                    <th>Regierungsbezirk</th>
                    <td>{observation[0].regierungsbezirk}</td>
                  </tr>
                  <tr>
                    <th>Landkreis</th>
                    <td>{observation[0].landkreis}</td>
                  </tr>
                  <tr>
                    <th>Naturraum Grosslandschaft</th>
                    <td>{observation[0].naturraum_grosslandschaft}</td>
                  </tr>
                  <tr>
                    <th>Naturraum Hauptname</th>
                    <td>{observation[0].naturraum_hauptname}</td>
                  </tr>
                  <tr>
                    <th>Naturraum Name</th>
                    <td>{observation[0].naturraum_name}</td>
                  </tr>
                  <tr>
                    <th>TK25</th>
                    <td>{observation[0].tk25}</td>
                  </tr>
                  <tr>
                    <th>TK25 Quadrant</th>
                    <td>{observation[0].tk25_quadrant}</td>
                  </tr>
                </tbody>
              </Table>
            </Container>
          </Col>
        </Row>
        <br></br>
      </Container> :
      <Loading />
  );
}

export default BeobachtungPublic;