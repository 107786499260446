import React from 'react';
import { Circle, MapContainer, Marker } from 'react-leaflet';
import Drawing from './Drawing.js';
import LayerControl from '../Map/LayerControl.js';
import config from '../../config/index.js';
import TK25Raster from '../Map/TK25Raster.js';

const quadrantenBundesland = config.QUADRANTENBUNDESLAND;
const tk25Raster =
  <TK25Raster
    raster={quadrantenBundesland}
    keyName="tk25_quadrant"
    color="darkgrey"
    interactive={false}
  />;

export default function FundortMap({ mapCenter, mapZoom, setFieldValue, setMap, values, drawLayer }) {

  console.log([values.latitude, values.longitude])
  return (
    <MapContainer
      ref={setMap}
      scrollWheelZoom={true}
      fullscreenControl={true}
      center={mapCenter}
      style={{ height: 500, marginBottom: "10px" }}
      zoom={mapZoom}
      minZoom={6}
    >
      <LayerControl />
      <Drawing setFieldValue={setFieldValue} drawLayer={drawLayer} />
      {tk25Raster}
      {values.latitude ?
        <Marker position={[values.latitude, values.longitude]} /> : null}
      {/* {values.latitude && values.unschaerfe ?
        <Circle position={[values.latitude, values.longitude]} radius={values.unschaerfe} /> : null} */}
    </MapContainer>
  );
}
