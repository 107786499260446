import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import abelogo from '../../img/abe-logo.png';

function Projektbeschreibung() {

  return (
    <Card>
      <Card.Body>
        <Card.Title><h1>Tagfalter in Bayern
          <span style={{ float: "right" }}>
            <a href="https://www.abe-entomofaunistik.org" rel="noopener noreferrer" target="_blank">
              <img src={abelogo} alt="ABE"></img>
            </a>
          </span>
        </h1></Card.Title>
        <hr></hr>
        <Card.Title>Das Projekt</Card.Title>
        <Card.Text style={{textAlign: "justify" }}>Das Portal „Tagfalter in Bayern“ ist die Fortführung des
          Tagfalter Atlas Bayern ("Tagfalter in Bayern" [Bräu et al., 2013]) durch die Arbeitsgemeinschaft Bayerischer Entomologen e.V.</Card.Text>
        <Card.Text style={{textAlign: "justify" }}>Im Gegensatz zur gedruckten Version, die immer nur eine Momentaufnahme
          der aktuellen Bestandssituation darstellen kann, ermöglicht das
          Onlineportal „Tagfalter in Bayern“ erstmalig eine dynamische
          Visualisierung der bayerischen Daten zu Tagfaltern und Widderchen.
          Zielsetzung ist es, einen aktuellen Überblick über Verbreitung
          und Bestandssituation der Tagfalter in Bayern geben zu können. </Card.Text>
        <Card.Text style={{textAlign: "justify" }}>Insbesondere sollen dadurch aktuelle Bestandsentwicklungen,
          Veränderungen von Verbreitungsarealen und phänologische Verschiebungen
          sowie neue Erkenntnisse zur Lebensweise der Tagfalter und Widderchen
          in Bayern veranschaulicht werden.</Card.Text>
        <Link to="/registrierung"><Button variant="success">&gt; Jetzt mitmachen</Button></Link>
      </Card.Body>
    </Card>
  )
}

export default Projektbeschreibung
