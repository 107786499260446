import React from 'react';
import { FeatureGroup, useMap } from 'react-leaflet';
import { GeomanControls } from 'react-leaflet-geoman-v2';

export default function Drawing({ setFieldValue, drawLayer }) {

  const map = useMap();

  const handleChange = (e) => {

    // If the layer already exists, return early
    if (drawLayer.current === e.layer) {
      return;
    }

    if (drawLayer.current) {
      map.removeLayer(drawLayer.current); // remove layer from map
    }
    drawLayer.current = e.layer;

    // Update coordinates
    const { lat, lng } = e.layer.getLatLng();
    setFieldValue("latitude", lat);
    setFieldValue("longitude", lng);
    // setCoordinates([lat, lng]);
    setFieldValue("unschaerfe", Math.round(e.layer.getRadius()));
    setFieldValue("fundort", "");
    setFieldValue("id_fundort", "");

    // Disable drawing mode after a marker is placed
    // e.sourceTarget.pm.disableDraw('Marker');
  }

  return (
    <FeatureGroup>
      <GeomanControls
        options={{
          position: 'topleft',
          drawMarker: false,
          drawText: false,
          drawCircle: true,
          drawCircleMarker: false,
          drawPolygon: false,
          drawPolyline: false,
          drawRectangle: false,
          editMode: false,
          dragMode: false,
          cutPolygon: false,
          removalMode: false,
          rotateMode: false,
        }}
        lang='de'
        onCreate={handleChange}
        onChange={(e) => console.log('onChange', e)}
      />
    </FeatureGroup>
  )
}
