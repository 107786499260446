import React from "react";
import Table from 'react-bootstrap/Table';

export default function RegionTabelle({ props }) {
  return (
    <Table responsive hover size="sm">
        <thead>
          <th>Gebiete</th>
        </thead>
        <tbody>
          <tr>
            <td>{props.bundesland}<small style={{color: "grey"}}> (Bundesland)</small></td>
          </tr>
          {props.regierungsbezirk ? 
          <tr>
            <td>{props.regierungsbezirk}<small style={{color: "grey"}}> (Regierungsbezirk)</small></td>
          </tr> : null }
          <tr>
            <td>{props.landkreis}<small style={{color: "grey"}}> (Landkreis)</small></td>
          </tr>
          <tr>
            <td>{props.gemeinde}<small style={{color: "grey"}}> (Gemeinde)</small></td>
          </tr>
          <tr>
            <td>{props.ort}<small style={{color: "grey"}}> (Ort)</small></td>
          </tr>
        </tbody>
      </Table>
  )
}
