import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Figure from 'react-bootstrap/Figure';
import baton from '../../img/baton.jpg';
import populi from '../../img/populi.jpg';

function FotosHomepage() {
  return (
    <Card>
    <Card.Body>
      <Row>
        <Col xs={12} md={6}>
          <Figure>
            <Figure.Image
              width={300}
              alt="Pseudophilotes baton"
              src={baton}
            />
            <Figure.Caption>
              Die Bestände von <i>Pseudophilotes baton</i> sind in den letzten Jahren stark zurückgegangen.
            </Figure.Caption>
          </Figure>
        </Col>
        <Col xs={12} md={6}>
          <Figure>
            <Figure.Image
              width={300}
              alt="Limenitis populi"
              src={populi}
            />
            <Figure.Caption>
              Der Große Eisvogel <i>Limenitis populi</i> ist Bayerns größter Tagfalter.
            </Figure.Caption>
          </Figure>
        </Col>
      </Row>
    </Card.Body>
  </Card>
  )
}

export default FotosHomepage;
