import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import axios from '../AxiosConfig';

export default function NeuigkeitenBayern() {

  const [posts, setPosts] = useState([]);

  const url = "https://blog.schmetterlingebayern.de/wp-json/wp/v2/";

  useEffect(() => {
    axios.get(url.concat("posts?per_page=6"))
      .then(response => {
        const promises = response.data.map(post =>
          axios.get(url.concat(`users/${post.author}`))
            .then(userResponse => ({ ...post, authorName: userResponse.data.name }))
        );
        Promise.all(promises).then(postsWithAuthor => setPosts(postsWithAuthor));
      })
      .catch(error => console.log(error));
  }, []);

  return (
    <Card>
      <Card.Body>
        <Card.Title>Neuigkeiten</Card.Title>
        <hr></hr>
        <Accordion defaultActiveKey="0">
          {posts.map((post, index) => (
            <Accordion.Item eventKey={post.id} key={index}>
              <Accordion.Header>
                <b>{post.title.rendered}</b>
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col md={12}>
                    <i>mitgeteilt von {post.authorName} am {new Date(post.date).toLocaleDateString()}</i>
                    <p style={{ textAlign: "justify", marginTop: '10px' }} dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                    <a href={post.link} target="_blank" rel="noopener noreferrer">Weiterlesen</a>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        <br></br>
        <p>
          Weitere Beiträge finden sich unter <a href={"https://blog.schmetterlingebayern.de"} target="_blank" rel="noopener noreferrer">Blog</a>.
        </p>
      </Card.Body>
    </Card>
  );
}
