import moment from 'moment';
import EXIF from 'exif-js';

/**
 * Converts Degrees Minutes Seconds (DMS) to Decimal Degrees (DD).
 *
 * @param {Array} input - An array containing degrees, minutes, and seconds.
 * @param {string} direction - The direction of the coordinates. Can be "N", "S", "E", or "W".
 * @returns {number} The converted decimal degrees.
 */
function ConvertDMSToDD(input, direction) {
  const degrees = input[0];
  const minutes = input[1];
  const seconds = input[2];
  let dd = degrees + (minutes / 60) + (seconds / 3600);
  if (direction === "S" || direction === "W") {
    dd = dd * -1;
  }
  return dd;
}

/**
 * Extracts EXIF data from an image.
 *
 * @param {Object} image - The image from which to extract EXIF data.
 * @returns {Object} An object containing the date, latitude, and longitude extracted from the image's EXIF data.
 */
const extractExifData = (image) => {
  let datum, latitude, longitude;

  let exifData = EXIF.pretty(image);

  if (exifData) {
    const dateTime = EXIF.getTag(image, "DateTimeOriginal");
    if (dateTime !== undefined) {
      datum = moment(dateTime, "YYYY:MM:DD HH:mm:ss").toDate();
    }

    latitude = EXIF.getTag(image, "GPSLatitude");
    longitude = EXIF.getTag(image, "GPSLongitude");

    if (latitude !== undefined) {
      let latDirection = EXIF.getTag(image, "GPSLatitudeRef");
      latitude = ConvertDMSToDD(latitude, latDirection);
    }

    if (longitude !== undefined) {
      let lngDirection = EXIF.getTag(image, "GPSLongitudeRef");
      longitude = ConvertDMSToDD(longitude, lngDirection);
    }
  }

  return { datum, latitude, longitude };
};

/**
 * Handles the extraction of EXIF data from a file and updates form fields and map view accordingly.
 *
 * @param {Object} file - The file from which to extract EXIF data.
 * @param {Function} setFieldValue - The function to call to set the value of a form field.
 * @param {Object} map - The map object to update the view of.
 */
export const handleExifData = (file, setFieldValue, map, drawLayer) => {
  EXIF.getData(file, function () {
    let exifData = EXIF.pretty(this);
    if (exifData) {
      const { datum, latitude, longitude } = extractExifData(this);
      if (datum) {
        setFieldValue("datum", datum);
      }
      // use latitude and longitude as needed
      if (!isNaN(longitude) && !isNaN(latitude)) {
        setFieldValue("latitude", latitude);
        setFieldValue("longitude", longitude);
        setFieldValue("fundort", "");
        setFieldValue("id_fundort", "");
        console.log(map)
        if (map !== null) {
          map.setView([latitude, longitude], 15);
          if (drawLayer.current) {
            map.removeLayer(drawLayer.current);
          }
        }
      }
    } else {
      console.log("No EXIF data found in image '" + file.file.name + "'.");
    }
  });
};
