import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Figure from 'react-bootstrap/Figure';

function FotosHomepage() {
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col xs={12} md={6}>
            <Figure>
              <Figure.Image
                width={300}
                alt="Proserpinus proserpina"
                src="https://storage.googleapis.com/schmetterlingebayern/20c06b78-a2f8-4b5b-addc-0cfab8aea7b1-1.jpg"
              />
              <Figure.Caption>
                Der Nachtkerzen-Schwärmer (<i>Proserpinus proserpina</i>) steht in Bayern auf der Vorwarnliste.
              </Figure.Caption>
            </Figure>
          </Col>
          <Col xs={12} md={6}>
            <Figure>
              <Figure.Image
                width={300}
                alt="Limenitis populi"
                src="https://schmetterlingebayern.storage.googleapis.com/markusdumke-20200608093540-11.jpg"
              />
              <Figure.Caption>
                Der Große Eisvogel (<i>Limenitis populi</i>) ist Bayerns größter Tagfalter.
              </Figure.Caption>
            </Figure>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default FotosHomepage;
