import React, { useEffect, useState } from "react";
import axios from '../AxiosConfig';

function Unsubscribe({ email, code }) {

  console.log("email:".concat(email));
  console.log("code:".concat(code));

  const [message, setMessage] = useState("E-Mails abbestellen...");

  const unsubscribe = async (email, code) => {
    await axios.post(`user/unsubscribe/`,
    {
      email: email,
      code: code
    })
      .then((response) => {
        console.log(response);
        setMessage("Alle E-Mails von www.tagfalterbayern.de wurden erfolgreich abbestellt!");
      })
      .catch((error) => {
        setMessage("Ein Fehler ist aufgetreten! Bitte wenden Sie sich an den Support.")
      });
  };

  useEffect(() => {
    unsubscribe(email, code);
  }, [email, code]);

  return (
    <div style={{
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
     {message}
    </div>
  )

}

export default Unsubscribe;