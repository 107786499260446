import React, { useEffect, useRef, useState } from "react";
import { MapContainer, Pane } from 'react-leaflet';
import LayerControl from './LayerControl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { GeomanControls } from 'react-leaflet-geoman-v2';
import MapTablePrivate from './MapTablePrivate';
import { Checkbox, useCheckboxState, Radio, useRadioState } from 'pretty-checkbox-react';
import { orderBy, uniqBy } from 'lodash';
import ObservationCircles from "./ObservationCircles";
import ObservationMarkers from "./ObservationMarkers";
import TK25Pane from "./TK25Pane";
import { LeafletLegendZeitraum } from "./LeafletLegend";
import bundeslandMittelpunkte from "../../data/gadm/bundesland_mittelpunkt.json";
import config from '../../config/index.js';
import ResizeMap from "./ResizeMap";


const bundeslandMittelpunkt =
  bundeslandMittelpunkte.filter((bl) => (bl.Bundesland === config.BUNDESLAND));

const bundeslandMittelpunktLat = bundeslandMittelpunkt[0].latitude;
const bundeslandMittelpunktLng = bundeslandMittelpunkt[0].longitude;
const bundeslandZoomLevel = bundeslandMittelpunkt[0].zoom;


const CancelFilterButton = ({ cancelFilterMapBounds }) => {
  // const map = useMap();
  // You can use map instance with its methods here, if needed

  return (
    <div
      style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        zIndex: 1000 // to make sure it appears above all map layers
      }}
    >
      <Button
        onClick={cancelFilterMapBounds}
        variant="secondary"
        style={{ marginLeft: "10px" }}
        size="sm"
      >
        <i className="fa fa-remove"></i> Zurücksetzen
      </Button>
    </div>
  );
};


export default function MapPrivate({ allObservations, observations, setObservations,
  admin, filterTK25, cancelFilterTK25, filterMapBounds,
  showButton, showButtonMap, setShowButtonMap, bounds, setBounds }) {

  const drawLayer = useRef(null);
  const [map, setMap] = useState(null);
  const [tk25Quadrant, setTk25Quadrant] = useState(null);
  const [tableObservations, setTableObservations] = useState([]);

  const radio = useRadioState({ state: "punkte" });
  const radioTK25 = useRadioState({ state: "bundesland" });
  const quadranten = useCheckboxState();

  useEffect(() => {
    console.log("MapPrivate useEffect")
    if (allObservations.length > 0 && bounds) {
      console.log("MapPrivate useEffect filterMapBounds")
      filterMapBounds(bounds);
    }
  }, [bounds, filterMapBounds]);

  useEffect(() => {
    console.log("MapPrivate useEffect radio.state")
    if (radio.state === 'tk25_quadrant' && drawLayer.current) {
      drawLayer.current.remove();
      setObservations(allObservations);
      setShowButtonMap(false);
      setBounds(null);
    }
  }, [radio.state, allObservations, setObservations, setShowButtonMap, setBounds]);


  const cancelFilterMapBounds = () => {
    console.log("cancelFilterMapBounds")
    setObservations(allObservations);
    setShowButtonMap(false);
    setBounds(null);
    if (drawLayer && drawLayer.current) {
      map.removeLayer(drawLayer.current); // remove layer from map
    }
  };

  // When creating a shape filter observations on map
  const onCreate = (e) => {
    console.log("onCreate shape")
    // If the layer already exists, return early
    if (drawLayer && drawLayer.current === e.layer) {
      return;
    }

    if (drawLayer && drawLayer.current) {
      map.removeLayer(drawLayer.current); // remove layer from map
    }
    drawLayer.current = e.layer;
    const newBounds = e.layer.getBounds();
    setBounds(newBounds);
  }

  const handleMapClick = (tk25_quadrant) => {
    console.log("handle TK25 Click", tk25_quadrant)
    let obs =
      allObservations.filter((observation) => {
        return (
          observation.tk25_quadrant === tk25_quadrant
        )
      });
    setTk25Quadrant(tk25_quadrant);
    setTableObservations(obs);
  }

  // create tk25 circles for observations
  // use unique to only render each circle once
  const observationsWithTK25 =
    allObservations.filter((row) => { return (row.center_lat !== null) });

  const observationsWithCoords = observations.filter((row) => {
    return (row.latitude !== null)
  });

  let shape = null;
  if (radio.state === "tk25_quadrant") {
    shape =
      <ObservationCircles
        observations={uniqBy(orderBy(observationsWithTK25, "datum", "desc"), "tk25_quadrant")}
        handleMapClick={handleMapClick}
        pane="neuedatenTK25"
      />;
  } else if (radio.state === "punkte") {
    shape = <ObservationMarkers observations={observationsWithCoords} />;
  }

  const activeTK25Observations = observationsWithTK25.filter((row) => {
    return row.tk25_quadrant === tk25Quadrant;
  });

  let mapTableUI = null;
  if (radio.state === "tk25_quadrant") {
    if (tk25Quadrant !== null) {
      mapTableUI = <div>
        <MapTablePrivate observations={orderBy(tableObservations, "datum", "desc")} admin={admin}></MapTablePrivate>
      </div>;
    } else {
      mapTableUI = <p style={{ color: "grey" }}>Bei Klick auf einen TK25 Kreis werden hier die zugehörigen Beobachtungen angezeigt.</p>;
    }
  } else {
    mapTableUI = <>
      <MapTablePrivate observations={orderBy(observations, "datum", "desc")} admin={admin}></MapTablePrivate>
    </>
  }


  return (
    <div>
      <Row>
        <Col md={12} lg={6}>
          <MapContainer
            id="map-container"
            ref={setMap}
            center={[bundeslandMittelpunktLat, bundeslandMittelpunktLng]}
            zoom={bundeslandZoomLevel}
            scrollWheelZoom={true}
            fullscreenControl={true}
            minZoom={6}
          >
            <LayerControl />
            {
              radio.state === "punkte" && showButtonMap ?
                <CancelFilterButton cancelFilterMapBounds={cancelFilterMapBounds} /> :
                <></>
            }
            <TK25Pane
              quadranten={quadranten}
              radioTK25={radioTK25}
              handleMapHover={() => { }}
            />
            <Pane name="neuedaten" style={{ zIndex: 404 }}>
              {shape}
            </Pane>
            {
              radio.state === "tk25_quadrant" ?
                <Pane name="aktiverquadrant" style={{ zIndex: 406 }}>
                  <ObservationCircles
                    observations={activeTK25Observations}
                    handleMapClick={handleMapClick}
                    fillColor="#dc3545"
                    pane="aktiverquadrantTK25"
                  />
                </Pane> : null
            }
            <ResizeMap />
            {radio.state === "punkte" ?
              <GeomanControls
                options={{
                  position: 'topleft',
                  drawMarker: false,
                  drawText: false,
                  drawCircle: false,
                  drawCircleMarker: false,
                  drawPolygon: false,
                  drawPolyline: false,
                  drawRectangle: true,
                  editMode: false,
                  dragMode: false,
                  cutPolygon: false,
                  removalMode: false,
                  rotateMode: false,
                }}
                lang='de'
                onCreate={onCreate}
                onChange={(e) => console.log('onChange', e)}
              /> : null}
          </MapContainer>
          <br></br>
          <Row>
            <Col md={5} xs={12}>
              <h5>Aggregation</h5>
              <Radio
                color="primary"
                name="map-aggregation"
                value="punkte"
                {...radio}
              >
                Punkte
              </Radio>
              <Radio color="primary" name="map-aggregation" value="tk25_quadrant" {...radio}>TK25 Quadrant</Radio>
              <br></br>
              {
                radio.state === "tk25_quadrant" ?
                  <>
                    <hr></hr>
                    <h5>Legende</h5>
                    <LeafletLegendZeitraum />
                  </> : null
              }
              <br></br>
            </Col>
            <Col md={7} xs={12}>
              <h5>TK25 Raster</h5>
              <Radio color="primary" name="map-raster" value="bundesland" {...radioTK25}>{config.BUNDESLAND}</Radio>
              <Radio color="primary" name="map-raster" value="deutschland" {...radioTK25}>Deutschland</Radio>
              <Radio color="primary" name="map-raster" value="none" {...radioTK25}>kein Raster</Radio>
              <hr></hr>
              <Checkbox color="primary" name="map-raster-quadrant" {...quadranten}>Quadranten anzeigen</Checkbox>
            </Col>
          </Row>
        </Col>
        <Col md={12} lg={6}>
          {
            radio.state === "tk25_quadrant" & tk25Quadrant !== null ?
              <h4>{tk25Quadrant}</h4> : <></>
          }
          {mapTableUI}
          {tk25Quadrant !== null && radio.state === "tk25_quadrant" ?
            !showButton ?
              <Button variant="outline-success" onClick={() => {
                filterTK25(tk25Quadrant);
              }}>
                TK25 Quadrant als Filter übernehmen
              </Button> :
              <Button
                onClick={cancelFilterTK25}
                variant="outline-secondary"
                style={{ marginLeft: "10px" }}
                size="sm"
              >
                <i className="fa fa-remove"></i> TK25 Filter löschen
              </Button> : null}
          <br></br>
        </Col>
      </Row>
    </div >
  )
}
