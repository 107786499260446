import React from "react";
import {
  Routes as Switch,
  Route,
  Navigate,
  Outlet,
  useLocation,
  useSearchParams
} from "react-router-dom";

import Loading from "./Loading";
import { useAuth } from "./Authentication/AuthContext";
import LoginForm from "./Authentication/LoginForm";
import NotFound from './NotFound';
import Homepage from './Homepage/Homepage';
import Beobachtungen from './Beobachtungen/Beobachtungen';
import Registration from './Authentication/Registration';
import NeueBeobachtung from "./NeueBeobachtung/NeueBeobachtung";
import EditObservation from "./NeueBeobachtung/EditObservation";
import RoteListeTabelle from './RoteListeTabelle';
import ArtportraitGalerie from './Artportrait/ArtportraitGalerie';
import Artportrait from './Artportrait/Artportrait';
import MeineBeobachtungen from './Beobachtungen/MeineBeobachtungen';
import Beobachtung from './Observation/Beobachtung';
import Nutzungsvereinbarung from "./Authentication/Nutzungsvereinbarung";
import PasswordResetForm from "./Authentication/PasswordResetForm";
import PasswordResetEmail from "./Authentication/PasswordResetEmail";
import Benutzerprofil from "./Authentication/Benutzerprofil";
import Galerie from "./Galerie";
import Benutzertabelle from "./Benutzertabelle";
import Freigabe from "./Freigabe";
import Impressum from "./Impressum";
import AdminBeobachtungen from "./Beobachtungen/AdminBeobachtungen";
// import Fundorte from "./Fundorte";
import Larvaloekologie from "./Larvaloekologie";
import Statistik from "./Statistik";
import Unsubscribe from "./Authentication/Unsubscribe";
import Fundortmaske from "./Fundorte/Fundortmaske";
import Fundortverwaltung from "./Fundorte/Fundortverwaltung";
import Benutzungshinweise from "./Authentication/Benutzungshinweise";


import axios from "./AxiosConfig";
axios.defaults.withCredentials = true;

function useQuery() {
  const [searchParams] = useSearchParams();
  return searchParams;
}

// This example has 3 pages: a public page, a protected
// page, and a login screen. In order to see the protected
// page, you must first login. Pretty standard stuff.
//
// First, visit the public page. Then, visit the protected
// page. You're not yet logged in, so you are redirected
// to the login page. After you login, you are redirected
// back to the protected page.
//
// Notice the URL change each time. If you click the back
// button at this point, would you expect to go back to the
// login page? No! You're already logged in. Try it out,
// and you'll see you go back to the page you visited
// just *before* logging in, the public page.

export default function Routes() {
  const query = useQuery();
  // const auth = useAuth();

  return (
    <Switch>
      <Route path='/' element={<Homepage />} />
      <Route path='/beobachtungen' element={<Beobachtungen />} />
      <Route path="/login" element={<LoginForm />} />
      <Route path='/registrierung' element={<Registration />} />
      <Route path="/passwort" element={<PasswordResetEmail />} />
      <Route path="/passwortneu" element={<PasswordResetForm />} />
      <Route path="/benutzungshinweise" element={<Benutzungshinweise />} />
      <Route path='/arten' element={<ArtportraitGalerie familie={query.get("familie")} />} />
      <Route path='/art' element={<Artportrait artQuery={query.get("art")} />} />
      <Route path='/roteliste' element={<RoteListeTabelle />} />
      <Route path="/galerie" element={<Galerie />} />
      <Route path="/larvaloekologie" element={<Larvaloekologie />} />
      <Route path="/impressum" element={<Impressum />} />
      <Route path="/statistik" element={<Statistik />} />
      <Route path='/beobachtung' element={<Beobachtung id={query.get("id")} />} />
      <Route path='/unsubscribe' element={<Unsubscribe email={query.get("email")} code={query.get("code")} />} />
      <Route path="/nutzungsvereinbarung" element={<Nutzungsvereinbarung />} />


      {/* Private Routes - only accessible for logged in users */}
      <Route element={<PrivateRoute />}>
        <Route path="meinebeobachtungen" element={<MeineBeobachtungen />} />
        <Route path='neuebeobachtung' element={<NeueBeobachtung />} />
        <Route path='neuerfundort' element={<Fundortmaske />} />
        <Route path='edit' element={<EditObservation id={query.get("id")} />} />
        <Route path='editfundort' element={<Fundortmaske id={query.get("id")} />} />
        <Route path='meinefundorte' element={<Fundortverwaltung />} />
        <Route path='benutzerprofil' element={<Benutzerprofil />} />
      </Route>

      {/* Admin Routes */}
      <Route element={<AdminRoute />}>
        <Route path='adminbeobachtungen' element={<AdminBeobachtungen />} />
        <Route path='freigabe' element={<Freigabe />} />
        <Route path='benutzer' element={<Benutzertabelle />} />
      </Route>

      <Route path='*' element={<NotFound />} />
    </Switch>
  );
}


const navigateToLogin = (location) => {
  return (
    <Navigate
      to={{
        pathname: "/login",
        state: { from: location }
      }}
      replace
    />
  )
}

function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  let location = useLocation();

  console.log(auth);

  if (auth.loading) {
    return (
      <Loading />
    );
  }

  if (!auth.user) {
    return (
      navigateToLogin(location)
    )
  }

  return children ? children : <Outlet />;
}

const AdminRoute = ({ children }) => {
  let auth = useAuth();
  let location = useLocation();

  if (auth.loading) {
    return (
      <Loading />
    );
  }

  if (!auth.user) {
    return (
      navigateToLogin(location)
    )
  }

  if (!auth.admin) {
    return (
      <Navigate
        to={{
          pathname: "/",
          state: { from: location }
        }}
        replace
      />
    )
  }

  return children ? children : <Outlet />;
};
