import React, { useEffect, useState } from "react";
import axios from "./AxiosConfig";
import Loading from "./Loading";
import ReactTable from './ReactTable';
import Container from "react-bootstrap/Container";

export default function Benutzertabelle() {

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const getUsers = async () => {
    const response = await axios.get(`admin/users`);
    setUsers(response.data);
    setLoading(false);
  }

  useEffect(() => {
    getUsers();
  }, []);

  console.log(users);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id'
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Ort',
        accessor: 'ort'
      },
      {
        Header: 'E-Mail',
        accessor: 'email',
      },
      {
        Header: 'Telefon',
        accessor: 'telefon',
      },
      {
        Header: 'Mobil',
        accessor: 'mobil',
      },
      {
        Header: 'Registriert seit',
        accessor: 'created_at',
      },
      {
        Header: "Beobachtungen",
        accessor: "beobachtungen"
      },
      {
        Header: "Letzte Meldung am",
        accessor: "zuletzt"
      }
    ],
    []
  );

  return (
    loading ?
      <Loading /> :
      // <div>Users</div>
      <Container>
        <br></br>
        <ReactTable data={users} columns={columns} pageSize={100} pageSizeOptions={[10, 20, 50, 100]}></ReactTable>
      </Container>
  )

}
