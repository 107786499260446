import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getXAxisOptions } from './utils-phenogram';


export default function PhenogramChart({ counts, x = "pentade", y = "beobachtungen", legend = false }) {

  const xAxisOptions = getXAxisOptions(x);

  const options = {
    chart: {
      type: 'column',
      zoomType: "xy",
      resetZoomButton: {
        align: "left"
      },
      // marginBottom: 70
    },
    title: {
      text: null
    },
    legend: {
      enabled: legend,
      align: "center",
      verticalAlign: "top",
      // floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false
    },
    yAxis: {
      min: 0,
      title: {
        text: y === "beobachtungen" ? 'Anzahl Beobachtungen' : "Anzahl Individuen"
      },
    },
    xAxis: xAxisOptions,
    plotOptions: {
      column: {
        stacking: 'normal'
      }
    },
    series: counts
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  )
}
