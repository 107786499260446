import React, { useRef } from 'react';
import Form from 'react-bootstrap/Form';
// import { Checkbox, useCheckboxState } from 'pretty-checkbox-react';
import { FilePond, registerPlugin } from "react-filepond";
import * as FilePondPluginExifOrientation from 'filepond-plugin-image-exif-orientation';
import * as FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import * as FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import * as FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { handleExifData } from './handleExifData.js';

// exifOrientation needs to be registered first
registerPlugin(FilePondPluginExifOrientation);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginImagePreview);

export default function FotoUpload({ exif, files, setFieldValue, map, drawLayer, imgCollection, setImgCollection, setFiles, setSource }) {
  // needed to access the map object because it is not available in the onFileChange function
  // When onFileChange is defined, it captures the current value of map, which is null at the time of definition
  const mapRef = useRef();
  mapRef.current = map;

  const onFileChange = (files) => {
    console.log("uploaded image");
    setFiles(files);
    let items = files.map(fileItem => fileItem.file);
    setImgCollection([...imgCollection, items]);
    // Do not run code if there are no images
    setSource(files.map(file => typeof file.source === "string" ? file.source : null));

    if (files.length > 0) {
      const file = files[0].file;
      setFieldValue("beleg", { value: "Belegfoto", label: "Belegfoto" });

      if (exif) {
        // Extract EXIF data from image
        handleExifData(file, setFieldValue, mapRef.current, drawLayer);
      }
    }
  };

  return (
    <Form>
      <Form.Text style={{ color: "grey" }}>Fotos vorher zuschneiden und verkleinern (z.B. auf 900x600 Pixel).</Form.Text>
      <div className="filepond-wrapper">
        <FilePond
          files={files}
          allowMultiple={true}
          allowFileTypeValidation={true}
          allowReorder={true}
          imageTransformOutputStripImageHead={false} // to keep exif data
          labelIdle={'Fotos hier ablegen oder <span class="filepond--label-action"> Auswählen </span>'}
          acceptedFileTypes={["image/jpeg"]}
          fileValidateTypeLabelExpectedTypesMap={{
            "image/jpeg": ".jpg"
          }}
          labelFileTypeNotAllowed="Nur Bilddateien (.jpg) erlaubt"
          maxFileSize="5MB"
          labelMaxFileSizeExceeded="Datei ist zu groß"
          labelMaxFileSize="Dateien dürfen maximal 5MB groß sein"
          maxFiles={20}
          server={null}
          instantUpload={false}
          onupdatefiles={(fileItems) => onFileChange(fileItems, setFieldValue, map)}
          onremovefile={(error, file) => console.log(file)}
        ></FilePond>
      </div>
      {/* <Checkbox>EXIF Daten auslesen</Checkbox> */}
    </Form>
  )
}
