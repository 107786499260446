import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import axios from "../AxiosConfig";
import { ObservationCardGallery } from '../Beobachtungen/ObservationCard';
import config from '../../config/index.js';
import Statistiken from "./Statistiken";

export default function LetzteBeobachtungenFoto() {
  const [observations, setObservations] = useState([]);

  const getObservations = async () => {
    const response = await axios.get(`observations/latest?bundesland=${config.BUNDESLAND}&artengruppe=${config.ARTENGRUPPE}`);
    setObservations(response.data);
  };

  useEffect(() => {
    getObservations();
  }, []);

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          Zuletzt beobachtet
          <div style={{ float: "right" }}>
            <Statistiken />
          </div>
        </Card.Title>
        <Card.Text>Mehr Beobachtungen finden sich unter <Link to="/beobachtungen">Beobachtungen</Link>.</Card.Text>
        <ObservationCardGallery observations={observations} />
      </Card.Body>
    </Card>
  )
}
