import React from "react";
import { CircleMarker, Popup } from 'react-leaflet';
import { Link } from 'react-router-dom';
import toGermanDate from "../../functions/toGermanDate";

function ObservationMarkers(props) {
  const markers = props.observations.map((row, index) => {
    return (
      <CircleMarker
        key={row.id}
        center={[row.latitude, row.longitude]}
        radius={5}
        pathOptions={{ fillOpacity: 1 }}
        eventHandlers={{
          click: () => {
            console.log(row.id);
          },
        }}
      >
        <Popup>
          <Link to={"/beobachtung?id=".concat(row.id)} target="_blank">
            <div id="popup-observation">
              {row.foto !== null ? <div id="popup-observation-left">
                <img src={row.foto} width={"100%"} alt="" style={{ float: "left" }}></img>
              </div> : null}
              <div id="popup-observation-right">
                <p><b><i>{row.art}</i></b></p>
                <hr></hr>
                <p>{row.ort}</p>
                <p>{toGermanDate(row.datum)}</p>
              </div>
            </div>
          </Link>
        </Popup>
      </CircleMarker>
    );
  });

  return(markers);
}

export default ObservationMarkers;
