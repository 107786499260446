import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from "./AxiosConfig";
import Durchforschung from "./Durchforschung";
import config from '../config/index.js';

function Statistik() {

  const [data, setData] = useState([]);

  const getData = async () => {
    const response = await axios.get(`stats/beobachtungenNachJahr?bundesland=${config.BUNDESLAND}`);
    console.log(response.data);
    setData(response.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const years = data.map(row => row.jahr);
  const values = data.map(row => parseInt(row.count));
  console.log(values);

  const options = {
    chart: {
      type: 'line',
      zoomType: "xy"
    },
    title: {
      text: null
    },
    legend: {
      enabled: false
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Anzahl Beobachtungen'
      }
    },
    xAxis: {
      title: {
        text: "Jahr der Beobachtung"
      },
      categories: years
    },
    series: [{
      data: values,
      name: 'Anzahl Beobachtungen'
    }]
  };

  return (
    <div>
      <Container>
        <br></br>
        <br></br>
        <Row>
          <Col xs={12} md={6}>
            <h3>Statistik</h3>
            <hr></hr>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
            />
            <br></br>
            <p style={{ color: "grey" }}>Die Grafik zeigt die Anzahl Beobachtungen gruppiert nach Jahr der Beobachtung.
              Die meisten Beobachtungen stammen aus den letzten 10 Jahren und sind somit relativ aktuell.
              Altdaten vor 2000 machen nur einen kleinen Prozentsatz aus.
            </p>
          </Col>
          <Col xs={12} md={6}>
            <Durchforschung></Durchforschung>
          </Col>
        </Row>
      </Container>
      <br></br>
    </div>
  );
}

export default Statistik;
