import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactTable from './ReactTable';
import roteListe from "../data/rote_liste_tagfalter.json";


export default function RoteListeTabelle() {

  const columns = React.useMemo(
    () => [
      {
        Header: 'Art',
        accessor: 'art',
        Cell: ({ value }) => <em>{value}</em>,
      },
      {
        Header: 'Deutscher Name',
        accessor: 'deutscher_name'
      },
      {
        Header: 'Rote Liste Bayern 2016',
        accessor: 'rlbayern2016'
      },
      {
        Header: 'Rote Liste Thüringen 2021',
        accessor: 'rlth2021'
      },
      {
        Header: 'Rote Liste Deutschland 2011',
        accessor: 'rld2011'
      }
    ],
    []
  );

  return (
    <div>
      <br></br>
      <Container >
        <h3>Rote Liste</h3>
        <hr></hr>
        {/* TODO: Add barplot of categories */}
        <Row>
          <Col xs={12} md={6}>
            <Card>
              <Card.Body>
                <Card.Title>Rote Liste Kategorien</Card.Title>
                <Card.Text>
                  <ul style={{ listStyleType: "none", padding: 0, fontSize: "0.9rem" }}>
                    <li>0 = Ausgestorben oder verschollen</li>
                    <li>1 = Vom Aussterben bedroht</li>
                    <li>2 = Stark gefährdet</li>
                    <li>3 = Gefährdet</li>
                    <li>R = Extrem seltene Arten/Arten mit geografischer Restriktion</li>
                    <li>V = Arten der Vorwarnliste</li>
                    <li>G = Gefährdung anzunehmen</li>
                    <li>D = Daten defizitär</li>
                    <li>* = keine Gefährdung</li>
                    <li>♦ (nb) = Nicht bewertet</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <hr></hr>
        <ReactTable
          data={roteListe}
          columns={columns}
          pageSize={50}
          pageSizeOptions={[10, 20, 50, 100]}
        />
      </Container>
      <br></br>
    </div>
  )
}
