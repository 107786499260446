// ObservationTabs.js
import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import ObservationTable from './ObservationTable';
import ObservationTablePrivate from './ObservationTablePrivate';
import { ObservationCardGallery } from './ObservationCard';
import { Radio } from 'pretty-checkbox-react';


function ObservationTabs({ observationsSorted, radio, aufsteigend, showOrt }) {
  return (
    <Tab.Container id="tabs-beobachtungen-daten" defaultActiveKey="first">
      <Tab.Content>
        <Tab.Pane eventKey="first">
          {showOrt ?
            <ObservationTablePrivate observations={observationsSorted} /> :
            <ObservationTable observations={observationsSorted} />
          }
        </Tab.Pane>
        <Tab.Pane eventKey="second">
          <ObservationCardGallery observations={observationsSorted} private={showOrt} />
        </Tab.Pane>
      </Tab.Content>
      <hr></hr>
      <Nav variant="pills">
        <Nav.Item>
          <Nav.Link eventKey="first">Tabelle</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="second">Galerieansicht</Nav.Link>
        </Nav.Item>
      </Nav>
      <hr></hr>
      <h5>Sortieren nach</h5>
      <Radio color="primary" name="datum" value="datum" {...radio}>Datum der Beobachtung</Radio>
      <Radio color="primary" name="datum" value="created_at" {...radio}>Erfassungsdatum</Radio>
      <Radio color="primary" name="datum" value="tag_im_jahr" {...radio}>Tag im Jahr</Radio>
      <br></br>
      <Radio color="primary" name="aufsteigend" value="asc" {...aufsteigend}>aufsteigend</Radio>
      <Radio color="primary" name="aufsteigend" value="desc" {...aufsteigend}>absteigend</Radio>
      <br></br>
    </Tab.Container>
  );
}

export default ObservationTabs;
