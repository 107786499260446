import { useMap } from "react-leaflet";
import { useEffect } from "react";

export default function ResizeMap() {
  const map = useMap();

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      map.invalidateSize();
    });
    const container = document.getElementById("map-container");
    if (container) {
      resizeObserver.observe(container);
    }

    // Cleanup function to stop observing when component unmounts
    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, []); // Empty dependency array ensures this runs once on mount and cleanup on unmount

  return null;
};
