import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {}
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
