import React from "react";
import Table from 'react-bootstrap/Table';

export default function TK25Tabelle({ props }) {
  return (
    <Table responsive hover size="sm">
      <thead>
        <tr>
          <th>Topographische Karte</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{props.tk25_quadrant}<small style={{ color: "grey" }}> (TK25 Quadrant)</small></td>
        </tr>
      </tbody>
    </Table>
  )
}
