import React, { useEffect, useState } from "react";
import { MapContainer, Polyline, Rectangle, Tooltip } from 'react-leaflet'
import axios from "./AxiosConfig";
import { interpolateRdBu } from "d3-scale-chromatic";
import bundeslandMittelpunkte from "../data/gadm/bundesland_mittelpunkt.json";
import config from '../config/index.js';

const bundeslandMittelpunkt =
  bundeslandMittelpunkte.filter((bl) => (bl.Bundesland === config.BUNDESLAND));

const bundeslandMittelpunktLat = bundeslandMittelpunkt[0].latitude;
const bundeslandMittelpunktLng = bundeslandMittelpunkt[0].longitude;
const bundeslandZoomLevel = bundeslandMittelpunkt[0].zoom;
const shapeBundesland = config.SHAPEBUNDESLAND;

function Durchforschung() {

  const [data, setData] = useState([]);

  const getData = async () => {
    const response = await axios.get(`stats/artenProTK25?bundesland=${config.BUNDESLAND}&artengruppe=${config.ARTENGRUPPE}`);
    console.log(response.data);
    setData(response.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const maxN = Math.max.apply(Math, data.map(function (o) { return o.count; }));

  const rectangles = data.map((row) => {
    const fillColor = interpolateRdBu(row.count / maxN);

    const options = {
      color: "black",
      fillColor: fillColor,
      fillOpacity: 0.8,
      opacity: 0.8,
      weight: 1
    };

    if (row.east !== null) {
      return (
        <Rectangle
          key={row.tk25}
          bounds={[[row.south, row.west], [row.north, row.east]]}
          pathOptions={options}
        >
          <Tooltip sticky><b>{row.tk25}</b><br></br>{row.count} Arten</Tooltip>
        </Rectangle>
      );
    } else {
      return null;
    }
  });

  return (
    <div>
      <h3>Aktueller Durchforschungsstand</h3>
      <hr></hr>
      <MapContainer
        center={[bundeslandMittelpunktLat, bundeslandMittelpunktLng]}
        zoom={bundeslandZoomLevel}
        scrollWheelZoom={true}
      >
        <Polyline positions={shapeBundesland} pathOptions={{ color: 'darkgrey', weight: 2 }} />
        {rectangles}
      </MapContainer>
      <br></br>
      <p style={{ color: "grey" }}>
        Die Karte zeigt die Anzahl der nachgewiesenen Arten
        auf Basis der Topographischen Karte 1:25.000 (TK25).
      </p>
    </div>
  )

}

export default Durchforschung;
