import React from 'react';
// import Button from 'react-bootstrap/Button';

// https://github.com/chilleddev-creator/react-pagination

function Pagination(props) {

  const { objectsPerPage, totalObjects, paginate, nextPage, prevPage, currentPage } = props;

  // const visiblePages = getVisiblePages(pageIndex + 1, pageCount);
  const pageNumbers = [];
  const numPages = Math.ceil(totalObjects / objectsPerPage);

  for (let i = 2; i < numPages; i++) {
    if ((currentPage < 5 && i <= 5) || (i < (currentPage + 2) && i > (currentPage - 2))) {
      pageNumbers.push(i);
    }
  }

  let dotsLeft = "";
  if (currentPage > 4) {
    dotsLeft = <span className="rt-page-ellipsis" role="separator">...</span>
  } else {
    dotsLeft = ""
  }

  let dotsRight = "";
  if (currentPage < numPages - 3) {
    dotsRight = <span className="rt-page-ellipsis" role="separator">...</span>
  } else {
    dotsRight = ""
  }

  let lastbutton = "";
  if (numPages > 1) {
    lastbutton = <button
      className={numPages !== currentPage ? "rt-page-button" : "rt-page-button rt-page-button-current"}
      key={numPages}
      onClick={() => paginate(numPages)}
    >{numPages}</button>
  } else {
    lastbutton = ""
  }

  return (
    <div className="rt-pagination">
      <div className="rt-pagination-nav">
        <button
          disabled={currentPage === 1 ? true : false}
          className="rt-page-button"
          onClick={() => prevPage()}
        >Zurück</button>
        <button
          className={1 !== currentPage ? "rt-page-button" : "rt-page-button rt-page-button-current"}
          key={1}
          onClick={() => paginate(1)}
        >{1}</button>
        {dotsLeft}
        {pageNumbers.map(num => (
          <button
            className={num !== currentPage ? "rt-page-button" : "rt-page-button rt-page-button-current"}
            key={num}
            onClick={() => paginate(num)}
          >{num}</button>
        ))}
        {dotsRight}
        {lastbutton}
        <button
          disabled={currentPage === numPages ? true : false}
          className="rt-page-button"
          onClick={() => nextPage()}
        >Nächste</button>
      </div>
    </div>
  )
}

export default Pagination