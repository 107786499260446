import React, { useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import MarkerMap from '../Map/MarkerMap';
import BaseMap from '../Map/BaseMap';
import ImageGallery from 'react-image-gallery';
import Modal from 'react-bootstrap/Modal';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import axios from '../AxiosConfig';
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import Loading from '../Loading';
import "react-image-gallery/styles/css/image-gallery.css";
import FreigabeIcon from "../FreigabeIcon";
import toGermanDate from "../../functions/toGermanDate";
import NaturraumTabelle from './NaturraumTabelle';
import KoordinatenTabelle from "./KoordinatenTabelle";
import FundortTabelle from "./FundortTabelle";
import RegionTabelle from "./RegionTabelle";
import TK25Tabelle from "./TK25Tabelle";


export default function BeobachtungPrivate(observationArg) {

  const observation = observationArg.observation;
  const id = observation[0].id;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  // let location = useLocation();

  let mapLinks = null;
  if (observation[0].latitude !== null) {
    mapLinks = <KoordinatenTabelle props={observation[0]} />
  }

  let naturraumTable = null;
  if (observation[0].naturraum_name !== null) {
    naturraumTable = <NaturraumTabelle props={observation[0]} />
  }

  let regionTable = null;
  if (observation[0].landkreis !== null) {
    regionTable = <RegionTabelle props={observation[0]} />
  }

  // div that contains images if there are any
  let imagePlaceholder;
  if (observation !== null && observation.length > 0) {
    // let nImages = observation.length;
    let images = observation.map(function (observation) {
      return (
        {
          original: observation.foto,
          thumbnail: observation.foto
        }
      )
    });

    if (observation[0].foto !== null) {
      imagePlaceholder = <div>
        {
          observation.length > 1 ?
            <ImageGallery items={images} showNav={false} showPlayButton={false} slideDuration={0} /> :
            <SimpleReactLightbox>
              <SRLWrapper>
                <img src={observation[0].foto} alt={observation[0].art}></img>
              </SRLWrapper>
            </SimpleReactLightbox>
          // <img src={observation.foto} alt={observation.art}></img>
        }
        <br></br>
        <h4>Details</h4>
      </div>
    } else {
      imagePlaceholder = <div></div>
    }
  }

  console.log(observation);

  const handleDelete = () => {
    console.log("handleDelete");
    axios.post(
      `observation/delete/`,
      {
        id: id
      }
    ).then(response => {
      console.log("deleted observation");
      console.log(response);
      handleClose();
      navigate('/meinebeobachtungen', { replace: true });
      toast.success("Beobachtung wurde gelöscht!");
    }).catch(function (error) {
      console.log(error);
      toast.error("Es ist ein Fehler aufgetreten!");
    });
  };

  const handleEdit = () => {
    console.log("handleEdit");
    navigate("/edit?id=".concat(id), { replace: true });
  };

  const map = observation[0].latitude !== null ?
    <MarkerMap observation={observation[0]} className="map-small"></MarkerMap> :
    <BaseMap observation={observation[0]} className="map-small"></BaseMap>

  return (
    <>
      {observation ? (
        <Container>
          <Row>
            <Col md={6} xs={12}>
              <br></br>
              <Container className="panel">
                <h3>
                  <FreigabeIcon observation={observation[0]}>
                  </FreigabeIcon>{" "}
                  <i>{observation[0].art}</i>
                  <Button title="Beobachtung löschen" onClick={() => handleShow()} style={{ float: "right" }} variant="danger"><i className="fa fa-trash"></i></Button>
                  <Button title="Beobachtung editieren" onClick={() => handleEdit()} style={{ float: "right", marginRight: "5px" }} variant="warning"><i className="fa fa-pencil"></i></Button>
                </h3>
                <h5 className="color-grey"> {observation[0].deutscher_name}</h5>
                <Toaster position="bottom-right" />
                <Modal show={show} onHide={handleClose} centered>
                  <Modal.Header closeButton>
                    <Modal.Title>Beobachtung wirklich löschen?</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>Dies kann nicht rückgängig gemacht werden!</Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Abbrechen
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                      Ja, Beobachtung löschen
                    </Button>
                  </Modal.Footer>
                </Modal>
                <hr></hr>
                {imagePlaceholder}
                <Table responsive hover size="sm">
                  <tbody>
                    <tr>
                      <th>Stadium</th>
                      <td>{observation[0].stadium}</td>
                    </tr>
                    <tr>
                      <th>Anzahl</th>
                      <td>{observation[0].anzahl}
                        {
                          observation[0].stadium === "Imago" && (observation[0].anzahl_m > 0 || observation[0].anzahl_w > 0) ?
                            <small>
                              {" (davon "}{observation[0].anzahl_m > 0 ? <>
                                {observation[0].anzahl_m}{" "}{String.fromCharCode(9794)}
                              </> : null}
                              {observation[0].anzahl_m > 0 && observation[0].anzahl_w > 0 ? <>
                                {" und "}
                              </> : null}
                              {observation[0].anzahl_w > 0 ? <>
                                {observation[0].anzahl_w}{" "}{String.fromCharCode(9792)}
                              </> : null}
                              )
                            </small> :
                            <></>
                        }
                      </td>
                    </tr>
                    <tr>
                      <th>Beobachter</th>
                      <td>{observation[0].beobachter}</td>
                    </tr>
                    {
                      observation[0].datum.length > 0 ?
                        <tr>
                          <th>Datum</th>
                          <td>{toGermanDate(observation[0].datum)}</td>
                        </tr> :
                        <tr>
                          <th>Jahr</th>
                          <td>{observation[0].datum}</td>
                        </tr>
                    }
                    <tr>
                      <th>Zustand</th>
                      <td>{observation[0].zustand}</td>
                    </tr>
                    {
                      observation[0].stadium === "Imago" ?
                        <tr>
                          <th>Nektarpflanzen</th>
                          <td><i>{observation[0].nektarpflanzen}</i></td>
                        </tr> :
                        <tr>
                          <th>Larvalnahrung</th>
                          <td><i>{observation[0].larvalnahrung}</i></td>
                        </tr>
                    }
                    <tr>
                      <th>Bemerkungen</th>
                      <td>{observation[0].bemerkungen}</td>
                    </tr>
                  </tbody>
                </Table>
              </Container>
              <br></br>
              <Container className="panel">
                <Table responsive hover size="sm">
                  <tbody>
                    <tr>
                      <th>ID</th>
                      <td>{observation[0].id}</td>
                    </tr>
                    {observation[0].datenquelle_bemerkung ? <tr>
                      <th>ID Extern</th>
                      <td>{observation[0].id_extern}</td>
                    </tr> : null}
                    <tr>
                      <th>Erfasser</th>
                      <td>{observation[0].erfasser}</td>
                    </tr>
                    <tr>
                      <th>Art der Beobachtung</th>
                      <td>{observation[0].art_der_beobachtung}</td>
                    </tr>
                    <tr>
                      <th>Beleg</th>
                      <td>{observation[0].beleg}</td>
                    </tr>
                    <tr>
                      <th>Bestimmer</th>
                      <td>{observation[0].bestimmer}</td>
                    </tr>
                    <tr>
                      <th>Bestimmungssicherheit</th>
                      <td>{observation[0].bestimmungssicherheit}</td>
                    </tr>
                    <tr>
                      <th>Bestimmungsmethode</th>
                      <td>{observation[0].bestimmungsmethode}</td>
                    </tr>
                    <tr>
                      <th>Datenquelle</th>
                      <td>{observation[0].datenquelle}</td>
                    </tr>
                    {observation[0].datenquelle_bemerkung ? <tr>
                      <th>Datenquelle (Bemerkung)</th>
                      <td>{observation[0].datenquelle_bemerkung}</td>
                    </tr> : null}
                    {observation[0].erfassungsart ? <tr>
                      <th>Erfassungsart</th>
                      <td>{observation[0].erfassungsart}</td>
                    </tr> : null}
                    {observation[0].datenquelle_import ? <tr>
                      <th>Datenquelle (Import)</th>
                      <td>{observation[0].datenquelle_import}</td>
                    </tr> : null}
                    {observation[0].datenbank ? <tr>
                      <th>Datenbank</th>
                      <td>{observation[0].datenbank}</td>
                    </tr> : null}
                    {observation[0].url ? <tr>
                      <th>URL</th>
                      <td><a rel="noopener noreferrer" target="_blank" href={observation[0].url} >{observation[0].url}</a></td>
                    </tr> : null}
                  </tbody>
                </Table>
              </Container>
            </Col>
            <Col md={6} xs={12}>
              <br></br>
              <Container className="panel">
                {map}
                {mapLinks}
                {regionTable}
                {naturraumTable}
                <TK25Tabelle props={observation[0]} />
                <FundortTabelle props={observation[0]} />
              </Container>
            </Col>
          </Row>
          <br></br>
        </Container>
      ) :
        <Loading />
      }
    </>
  )
}
