import React from "react";
import Table from 'react-bootstrap/Table';

export default function FundortTabelle({ props }) {
  return (
    props.fundort ?
      <Table responsive hover size="sm">
        <thead>
          <tr>
            <th>Fundort</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{props.fundort}<small style={{ color: "grey" }}> (Fundort)</small></td>
          </tr>
        </tbody>
      </Table>
      : null
  )
}
