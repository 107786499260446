import React from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';


function Neuigkeiten() {

  return (
    <Card>
      <Card.Body>
        <Card.Title>Neuigkeiten</Card.Title>
        <hr></hr>
        <Accordion defaultActiveKey="2">
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <b>27.05.2024</b>{" Erstfund Plebejus argyrognomon"}
            </Accordion.Header>
            <Accordion.Body>
              <Card.Body>
                Arealerweiterung: Erstfund Plebejus argyrognomon nördlich des Thüringer Waldes am 23.05.2024 im Jonastal
              </Card.Body>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <b>01.01.2022 </b>{" "}Tagfalter in Thüringen online
            </Accordion.Header>
            <Accordion.Body>
              Die neue Website stellt den aktuellen Stand der
              Verbreitung der Tagfalter und Widderchen in Thüringen dar.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card.Body>
    </Card >
  )
}

export default Neuigkeiten;
