import React from "react";
import Table from 'react-bootstrap/Table';

export default function NaturraumTabelle({ props }) {
  return (
    <Table responsive hover size="sm">
      <thead>
        <tr>
          <th>Naturraum</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{props.naturraum_grosslandschaft}<small style={{color: "grey"}}> (Großlandschaft)</small></td>
        </tr>
        <tr>
          <td>{props.naturraum_hauptname}<small style={{color: "grey"}}> (Hauptname)</small></td>
        </tr>
        <tr>
          <td>{props.naturraum_name}<small style={{color: "grey"}}> (Name)</small></td>
        </tr>
      </tbody>
    </Table>
  )
}
