import React, { useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Loading from "../Loading.js";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import { MapContainer, FeatureGroup, Marker } from "react-leaflet";
// import DrawMarkerControl from "./Map/DrawMarkerControl";
import LayerControl from "../Map/LayerControl.js";
import axios from "../AxiosConfig.js";
import TK25Raster from "../Map/TK25Raster.js";
// import LeafletSearch from "./Map/LeafletSearch";
// import { OpenStreetMapProvider } from "leaflet-geosearch";
import bundeslandMittelpunkte from "../../data/gadm/bundesland_mittelpunkt.json";
import config from '../../config/index.js';
import DrawMarker from "./DrawMarker.js";

const bundeslandMittelpunkt =
  bundeslandMittelpunkte.filter((bl) => (bl.Bundesland === config.BUNDESLAND));

const bundeslandMittelpunktLat = bundeslandMittelpunkt[0].latitude;
const bundeslandMittelpunktLng = bundeslandMittelpunkt[0].longitude;
const bundeslandZoomLevel = bundeslandMittelpunkt[0].zoom;

const quadrantenBundesland = config.QUADRANTENBUNDESLAND;
const tk25Raster = <TK25Raster
  raster={quadrantenBundesland}
  keyName="tk25_quadrant"
  color="darkgrey"
  interactive={false}
/>;


function FundortInfo() {
  return (
    <Container className="panel">
      <h5>Hinweise zur Fundorterstellung</h5>
      <p style={{ "color": "grey" }}>
        Fundorte können entweder durch das Setzen eines Markers
        in der Karte oder durch manuelle Eingabe
        der Koordinaten erstellt werden.
        Die Koordinaten können z.B. auch mit Komma getrennt
        aus OpenStreetMap, Google Maps o.Ä. kopiert und in das Koordinatenfeld
        kopiert werden.
      </p>
      <p style={{ "color": "grey" }}>
        Die Höhenlage, Verwaltungseinheiten, Messtischblatt-Nummer (TK25) und
        Naturraumeinheiten (aktuell nur innerhalb Bayerns) werden automatisch aus den Koordinaten
        bestimmt.
      </p>
      <p style={{ "color": "grey" }}>
        Zum Speichern des Fundorts klicken Sie auf den grünen
        "Fundort speichern" Button. Nach dem Speichern kann der
        Fundort unter "Neue Beobachtung" ausgewählt werden
        (Seite ggf. neuladen). Das Anlegen von Fundorten ist optional.
      </p>
      <p style={{ "color": "grey" }}>
        Die Namen der Fundorte sollten möglichst aussagekräftig sein, z.B. im Format
        Ortschaft, Himmelsrichtung, Fundortname. Auch sollte die
        Kombination aus Fundortname und Koordinaten eindeutig sein.
      </p>
    </Container>
  )
}

function FundortGeoDaten(props) {
  console.log("FundortGeoDaten")
  const values = props.values;
  return (
    <Form>
      <Row>
        <Col xs={12} md={6}>
          <Form.Label>Bundesland</Form.Label>
          <Form.Control
            disabled
            id={"bundesland"}
            type={"text"}
            placeholder={"Bundesland"}
            value={values.bundesland}
          />
        </Col>
        <Col xs={12} md={6}>
          <Form.Label>Landkreis</Form.Label>
          <Form.Control
            disabled
            id={"landkreis"}
            type={"text"}
            placeholder={"Landkreis"}
            value={values.landkreis}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Form.Label>Gemeinde</Form.Label>
          <Form.Control
            disabled
            id={"gemeinde"}
            type={"text"}
            placeholder={"Gemeinde"}
            value={values.gemeinde}
          />
        </Col>
        <Col xs={12} md={6}>
          <Form.Label>Ort</Form.Label>
          <Form.Control
            disabled
            id={"ort"}
            type={"text"}
            placeholder={"Ort"}
            value={values.ort}
          />
        </Col>
      </Row>
      <hr></hr>
      <Row>
        <Col xs={12} md={6}>
          <Form.Label>Naturraum Großlandschaft</Form.Label>
          <Form.Control
            disabled
            id={"naturraum_grosslandschaft"}
            type={"text"}
            placeholder={"Naturraum Großlandschaft"}
            value={values.naturraum_grosslandschaft}
          />
        </Col>
        <Col xs={12} md={6}>
          <Form.Label>Naturraum (Hauptname)</Form.Label>
          <Form.Control
            disabled
            id={"naturraum_hauptname"}
            type={"text"}
            placeholder={"Naturraum Hauptname"}
            value={values.naturraum_hauptname}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Form.Label>Naturraum</Form.Label>
          <Form.Control
            disabled
            id={"naturraum_name"}
            type={"text"}
            placeholder={"Naturraum"}
            value={values.naturraum_name}
          />
        </Col>
        <Col xs={12} md={6}>
          <Form.Label>Höhe (in m)</Form.Label>
          <Form.Control
            disabled
            id={"hoehe"}
            type={"text"}
            placeholder={"Höhe"}
            value={values.hoehe}
          />
        </Col>
      </Row>
      <hr></hr>
      <Row>
        <Col xs={12} md={6}>
          <Form.Label>Messtischblatt (TK25)</Form.Label>
          <Form.Control
            disabled
            id={"tk25"}
            type={"text"}
            placeholder={"TK25"}
            value={values.tk25}
          />
        </Col>
        <Col>
          <Form.Label>Messtischblatt (TK25 Quadrant)</Form.Label>
          <Form.Control
            disabled
            id={"tk25_quadrant"}
            type={"text"}
            placeholder={"TK25 Quadrant"}
            value={values.tk25_quadrant}
          />
        </Col>
      </Row>
    </Form>
  )
}

function Fundortmaske(props) {

  console.log(props);

  const drawLayer = useRef(null);
  const edit = !isEmpty(props.id);
  const id = props.id;

  const [editableFG, setEditableFG] = useState(null);
  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fundort, setFundort] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const getFundort = async (id) => {
    const response = await axios.get(`fundort/get?id=`.concat(id));
    setFundort(response.data);
    setLoading(false);
  };

  useEffect(() => {
    console.log(props.id);
    if (edit) {
      getFundort(props.id);
    }
  }, [edit, props.id]);

  const onFeatureGroupReady = reactFGref => {
    // store the featureGroup ref for future access to content
    setEditableFG(reactFGref);
  };

  const handleMapCreation = (map) => {
    setMap(map);
    if (edit && fundort) {
      map.setView([fundort[0].latitude, fundort[0].longitude], 15);
    }
  }

  const getGeoInfo = async (latitude, longitude, setFieldValue) => {
    await axios.get(`/fundort/geoInfo?latitude=${latitude}&longitude=${longitude}`)
      .then(res => {
        console.log(res.data);
        setFieldValue("bundesland", res.data.bundesland ?? "");
        setFieldValue("landkreis", res.data.landkreis ?? "");
        setFieldValue("gemeinde", res.data.gemeinde ?? "");
        setFieldValue("ort", res.data.ort ?? "");
        setFieldValue("naturraum_name", res.data.naturraum_name ?? "");
        setFieldValue("naturraum_hauptname", res.data.naturraum_hauptname ?? "");
        setFieldValue("naturraum_grosslandschaft", res.data.naturraum_grosslandschaft ?? "");
        setFieldValue("hoehe", res.data.hoehe ?? "");
        setFieldValue("tk25", res.data.tk25 ?? "");
        setFieldValue("tk25_quadrant", res.data.tk25_quadrant ?? "");
      });
  }

  const title = edit ? "Fundort editieren" : "Neuen Fundort anlegen";

  const emptyInputs = {
    fundort: "",
    bundesland: "",
    landkreis: "",
    gemeinde: "",
    ort: "",
    naturraum_name: "",
    naturraum_hauptname: "",
    naturraum_grosslandschaft: "",
    hoehe: "",
    tk25: "",
    tk25_quadrant: "",
    latitude: "",
    longitude: ""
  };

  return (
    edit && loading ?
      <Loading /> :
      <div>
        <br></br>
        <Formik
          initialValues={edit ? fundort[0] : emptyInputs}

          onSubmit={
            (values, { resetForm }) => {
              // alert(JSON.stringify(values, null, 2));
              let error = false;
              if (!values.latitude) {
                toast.error("Keine Koordinaten gefunden!");
                error = true;
              }
              if (!values.fundort) {
                toast.error("Fundortname ist leer!");
                error = true;
              }

              if (!error) {
                if (edit) {
                  setButtonDisabled(true);

                  const axiosPromise = axios.post(`fundort/edit/`, {
                    "id": fundort[0].id,
                    "fundort": values.fundort,
                    "latitude": values.latitude,
                    "longitude": values.longitude
                  })
                    .then(res => {
                      setButtonDisabled(false);
                    }).catch((err) => {
                      setButtonDisabled(false);
                    });;

                  toast.promise(axiosPromise, {
                    loading: 'Änderungen werden gespeichert.',
                    success: 'Fundort wurde geändert.',
                    error: 'Ein Fehler ist aufgetreten.',
                  });
                } else {
                  setButtonDisabled(true);
                  const axiosPromise = axios.post(`fundort/create/`, {
                    "fundort": values.fundort,
                    "latitude": values.latitude,
                    "longitude": values.longitude
                  }).then(res => {
                    setButtonDisabled(false);
                    resetForm({
                      values: emptyInputs
                    });
                  }).catch((err) => {
                    setButtonDisabled(false);
                  });
                  toast.promise(axiosPromise, {
                    loading: 'Fundort wird gespeichert.',
                    success: 'Fundort wurde gespeichert.',
                    error: 'Ein Fehler ist aufgetreten.',
                  });
                }
              }
            }
          }
        >
          {
            props => {
              const {
                values,
                handleChange,
                handleSubmit,
                handleBlur,
                setFieldValue
              } = props;
              return (
                <Container>
                  <Toaster position="bottom-right" />
                  <h4 style={{ display: "inline-block" }}>{title}</h4>
                  <Link to="/meinefundorte">
                    <Button
                      style={{ float: "right" }}
                      variant="outline-success"
                    >
                      Meine Fundorte
                    </Button>
                  </Link>
                  <hr></hr>
                  <Row>
                    <Col md={6}>
                      <MapContainer
                        // ref={mapRef}
                        center={[bundeslandMittelpunktLat, bundeslandMittelpunktLng]}
                        zoom={bundeslandZoomLevel}
                        minZoom={6}
                        scrollWheelZoom={true}
                        ref={setMap}
                        // whenCreated={handleMapCreation}
                        style={{ height: 500 }}
                      >
                        <LayerControl></LayerControl>
                        {/* <LeafletSearch provider={new OpenStreetMapProvider()} /> */}
                        {tk25Raster}
                        <FeatureGroup
                          ref={featureGroupRef => {
                            onFeatureGroupReady(featureGroupRef);
                          }}>
                        </FeatureGroup>
                        <DrawMarker
                          setFieldValue={setFieldValue}
                          drawLayer={drawLayer}
                          getGeoInfo={getGeoInfo}
                        />
                        {values.latitude ? <Marker position={[values.latitude, values.longitude]}></Marker> : null}
                      </MapContainer>
                      <br></br>
                      <FundortInfo />
                    </Col>

                    <Col md={6}>
                      <Container className="panel">
                        <Form onSubmit={handleSubmit}>
                          <Row>
                            <Col md={12}>
                              <Form.Label><b>Fundort</b></Form.Label>
                              <Form.Control
                                id={"fundort"}
                                type={"text"}
                                placeholder={"Fundortname, Beispiel: Elgersburg, Reichenbachtal N, Waldbereich"}
                                value={values.fundort}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Col>
                          </Row>
                          <hr></hr>
                          <Row>
                            <Col md={12}>
                              <Form.Label>Koordinaten <small>(WGS84 numerisch, EPSG 4326)</small></Form.Label>
                              <Form.Control
                                id={"coords"}
                                type={"text"}
                                placeholder={"Bsp. 47.9981, 11.71701"}
                                style={{ marginBottom: "10px" }}
                                // value={values.coords}
                                onBlur={handleBlur}
                                onChange={(event) => {
                                  const coords = event.target.value;
                                  if (coords) {
                                    console.log(coords);
                                    const coordsSplit = coords.split(",");
                                    if (coordsSplit.length === 2) {
                                      const coord1 = coordsSplit[0].trim();
                                      const coord2 = coords.split(",")[1].trim();
                                      const latitude = coord1 > coord2 ? coord1 : coord2;
                                      const longitude = coord1 > coord2 ? coord2 : coord1;
                                      setFieldValue("latitude", latitude);
                                      setFieldValue("longitude", longitude);
                                    }
                                  } else {
                                    setFieldValue("latitude", "");
                                    setFieldValue("longitude", "");
                                  }
                                }
                                }
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Form.Label>WGS84 Breitengrad</Form.Label>
                              <Form.Control
                                id={"latitude"}
                                type={"text"}
                                value={values.latitude}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={4}>
                              <Form.Label>WGS84 Längengrad</Form.Label>
                              <Form.Control
                                id={"longitude"}
                                type="text"
                                value={values.longitude}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={4}>
                              <Button
                                variant={"outline-success"}
                                style={{
                                  "height": "40px",
                                  "marginTop": "30px"
                                }}
                                onClick={(event) => {
                                  console.log(values.latitude)
                                  // replace commata to parse coordinates correctly
                                  const latitude = String(values.latitude).replace(",", ".").replace(/[^\d.]/g, '').trim();
                                  const longitude = String(values.longitude).replace(",", ".").replace(/[^\d.]/g, '').trim();
                                  // make sure latitude and longitude are not empty
                                  if (latitude && longitude) {
                                    setFieldValue("latitude", latitude);
                                    setFieldValue("longitude", longitude);
                                    map.setView([latitude, longitude], 15);
                                    getGeoInfo(latitude, longitude, setFieldValue);
                                  }
                                }}
                              >
                                <i className="fa fa-map-marker"></i>{"  Koordinaten setzen"}
                              </Button>
                            </Col>
                          </Row>
                          <hr></hr>
                          <Row>
                            <Col md={12}>
                              <Button
                                className="w-100"
                                type="submit"
                                variant={"success"}
                                disabled={buttonDisabled}
                              >
                                Fundort speichern
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Container>
                      <br></br>
                      <Container className="panel">
                        <FundortGeoDaten values={values} />
                      </Container>
                    </Col>
                  </Row>
                  <br></br>
                </Container>
              )
            }
          }
        </Formik >
      </div >
  )
}

export default Fundortmaske;
