import React from 'react';
import ReactTable from '../ReactTable';
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { id, foto, art, created_at, freigabe } from "../TableColumns";

function ObservationTablePrivate(props) {

  const data = React.useMemo(
    () =>
      props.observations,
    [props.observations]
  );

  const columns = React.useMemo(
    () => [
      id,
      foto,
      art,
      {
        Header: 'Stadium',
        accessor: 'stadium'
      },
      {
        Header: 'Anzahl',
        accessor: 'anzahl'
      },
      {
        Header: 'Beobachter',
        accessor: 'beobachter',
      },
      {
        Header: 'Datum',
        accessor: 'datum',
      },
      {
        Header: 'Ort',
        accessor: 'ort',
      },
      created_at,
      freigabe,
    ],
    []
  );

  return (

    <SimpleReactLightbox>
      <SRLWrapper>
        <ReactTable data={data} columns={columns}></ReactTable>
      </SRLWrapper>
    </SimpleReactLightbox>
  )
}

export default ObservationTablePrivate;
