import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Select from "react-select";
import axios from '../AxiosConfig';
import { useAuth } from "../Authentication/AuthContext";


export function SelectFundort({ id, values, setFieldValue, handleBlur, map, edit, drawLayer, createdBy }) {

  const auth = useAuth();
  const [choicesFundort, setChoicesFundort] = useState([]);

  const getChoices = async (created_by = null) => {
    const userID = edit ? created_by : auth.id;
    const response = await axios.get(`fundorte/get?user=`.concat(userID));
    // console.log(response.data);
    const fundort = response.data.map((row) => {
      return ({ value: row.fundort, label: row.fundort, longitude: row.longitude, latitude: row.latitude, id_fundort: row.id });
    });
    setChoicesFundort(fundort);
  };

  useEffect(() => {
    console.log(id);
    getChoices(createdBy);
  }, []);

  return (
    <>
      <Form.Label>Fundort auswählen</Form.Label>
      <Select isClearable
        id={"fundort"}
        type={"text"}
        value={values.fundort}
        onChange={option => {
          setFieldValue("fundort", option);
          if (option !== null) {
            console.log(option.id_fundort);
            setFieldValue("id_fundort", option.id_fundort);
            console.log(map);
            if (map) {
              map.setView([option.latitude, option.longitude], 15);
              if (drawLayer.current) {
                map.removeLayer(drawLayer.current);
              }
            }
            setFieldValue("latitude", option.latitude);
            setFieldValue("longitude", option.longitude);

          } else {
            setFieldValue("id_fundort", "");
          }
        }}
        options={choicesFundort}
        onBlur={handleBlur}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        placeholder="Fundort auswählen...">
      </Select>
    </>
  );
}
