import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { orderBy } from 'lodash';


export default function EntwicklungChart(props) {

  const statsOrdered = orderBy(props.data, "jahr").filter(row => row.jahr > 2010);
  const years = statsOrdered.map(item => item.jahr)
    .filter((value, index, self) => self.indexOf(value) === index);

  const options = {
    chart: {
      type: 'spline',
      zoomType: "xy",
      resetZoomButton: {
        align: "left"
      },
    },
    title: {
      text: null
    },

    yAxis: {
      title: {
        text: null
      }
    },

    xAxis: {
      accessibility: {
        rangeDescription: 'Zeitraum'
      },
      categories: years
    },

    legend: {
      align: "center",
      verticalAlign: "top",
      // floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false
    },
    // tooltip: {
    //     crosshairs: true,
    // },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false
        },
      },
      spline: {
        marker: {
          enabled: true,
          symbol: "circle"
        }
      }
    },
    series: [{
      name: 'Beobachtungen',
      data: statsOrdered.map(row => row.beobachtungen),
      color: "#7cb5ec",
    }, {
      name: 'Individuen',
      data: statsOrdered.map(row => row.individuen),
      color: "#A6C96A",
    }, {
      name: 'TK25',
      data: statsOrdered.map(row => row.tk25),
      color: "#F28F43",
    }],

    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  )
}
