import choicesPraxisgruppe from "../data/arten/choices_praxisgruppe.json";
import choicesPraxisgruppeTagfalter from "../data/arten/choices_praxisgruppe_tagfalter.json";

import tk25Bayern from "../data/tk25/tk25_bayern.json";
import quadrantenBayern from "../data/tk25/tk25_quadrant_bayern.json";
import tk25Thueringen from "../data/tk25/tk25_thueringen.json";
import quadrantenThueringen from "../data/tk25/tk25_quadrant_thueringen.json";
import choicesTK25Bayern from "../data/tk25/choices_tk25_bayern.json";
import choicesTK25Thueringen from "../data/tk25/choices_tk25_thueringen.json";
import choicesLandkreisBayern from "../data/gadm/choices_landkreis_bayern.json";
import choicesLandkreisThueringen from "../data/gadm/choices_landkreis_thueringen.json";
import choicesRegierungsbezirkBayern from "../data/gadm/choices_regierungsbezirk_bayern.json";
import choicesNaturraumHauptnameBayern from '../data/naturraum/choices_naturraum_hauptname_bayern.json';
import choicesNaturraumBayern from '../data/naturraum/choices_naturraum_bayern.json';
import shapeBayern from "../data/gadm/shape_bayern.json";
import shapeThueringen from "../data/gadm/shape_thueringen.json";

const domain = window.location.hostname;

// Define settings based on URL
let config;

let API;
let ARTENGRUPPE;
let BUNDESLAND;
let TITEL;
let EMAIL;
let tk25Bundesland;
let quadrantenBundesland;
let choicesTK25;
let choicesRegierungsbezirk;
let choicesLandkreis;
let choicesNaturraumHauptname;
let choicesNaturraum;
let shape;

let ZEITRAUM = {
  JAHR1: 2011,
  JAHR2: 1991,
  JAHR3: 1971
};

// console.log(domain);

if (domain.includes("schmetterlingebayern.de")) {

  API = "https://devapi.schmetterlingebayern.de";
  ARTENGRUPPE = "Lepidoptera";
  BUNDESLAND = "Bayern";
  TITEL = "Schmetterlinge in Bayern";
  EMAIL = "info@tagfalterbayern.de";

} else if (domain.includes("tagfalterbayern.de")) {

  API = "https://devapi.tagfalterbayern.de";
  ARTENGRUPPE = "Tagfalter";
  BUNDESLAND = "Bayern";
  TITEL = "Tagfalter in Bayern";
  EMAIL = "info@tagfalterbayern.de";

} else if (domain.includes("tagfalter-thueringen.de")) {

  API = "https://devapi.tagfalter-thueringen.de";
  ARTENGRUPPE = "Tagfalter";
  BUNDESLAND = "Thüringen";
  TITEL = "Tagfalter in Thüringen";
  EMAIL = "kuna.tagfalter@gmx.de";

  ZEITRAUM = {
    JAHR1: 2021,
    JAHR2: 2001,
    JAHR3: 1981
  };

}

// Local development settings from environment variables

if (domain.includes("localhost")) {

  API = process.env.REACT_APP_API;
  ARTENGRUPPE = process.env.REACT_APP_ARTENGRUPPE;
  BUNDESLAND = process.env.REACT_APP_BUNDESLAND;
  EMAIL = process.env.REACT_APP_EMAIL;
  TITEL = process.env.REACT_APP_TITEL;
}

let praxisgruppe = choicesPraxisgruppe;
if (ARTENGRUPPE === "Tagfalter") {
  praxisgruppe = choicesPraxisgruppeTagfalter;
}

if (BUNDESLAND === "Bayern") {
    tk25Bundesland = tk25Bayern;
    quadrantenBundesland = quadrantenBayern;
    choicesTK25 = choicesTK25Bayern;
    choicesLandkreis = choicesLandkreisBayern;
    choicesRegierungsbezirk = choicesRegierungsbezirkBayern;
    choicesNaturraumHauptname = choicesNaturraumHauptnameBayern;
    choicesNaturraum= choicesNaturraumBayern;
    shape = shapeBayern;
} else if (BUNDESLAND === "Thüringen") {
  tk25Bundesland = tk25Thueringen;
  quadrantenBundesland = quadrantenThueringen;
  choicesTK25 = choicesTK25Thueringen;
  choicesLandkreis = choicesLandkreisThueringen;
  choicesRegierungsbezirk = [];
  shape = shapeThueringen;
  choicesNaturraumHauptname = [];
  choicesNaturraum = [];
}

config = {
  API: API,
  ARTENGRUPPE: ARTENGRUPPE,
  BUNDESLAND: BUNDESLAND,
  TITEL: TITEL,
  EMAIL: EMAIL,
  ZEITRAUM: ZEITRAUM,
  PRAXISGRUPPE: praxisgruppe,
  TK25BUNDESLAND: tk25Bundesland,
  QUADRANTENBUNDESLAND: quadrantenBundesland,
  CHOICESTK25: choicesTK25,
  CHOICESREGIERUNGSBEZIRK: choicesRegierungsbezirk,
  CHOICESLANDKREIS: choicesLandkreis,
  CHOICESNATURRAUMHAUPTNAME: choicesNaturraumHauptname,
  CHOICESNATURRAUM: choicesNaturraum,
  SHAPEBUNDESLAND: shape
}

export default config;
