import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import userfoto from '../img/user.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCog } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "./Authentication/AuthContext";
import config from '../config/index.js';

function NavbarComponent() {

  const auth = useAuth();
  const navigate = useNavigate();
  // console.log(auth.name);
  // console.log(auth.user);

  const navUserTabs = <>
    <Nav.Link as={NavLink} to="/meinebeobachtungen">Meine Beobachtungen</Nav.Link>
    <Nav.Link as={NavLink} to="/beobachtungen">Alle Beobachtungen</Nav.Link>
    <Link to="/neuebeobachtung"><Button variant="success">Neue Beobachtung</Button></Link>
    <NavDropdown
      title="Fundorte"
      id="nav-dropdown-fundorte">
      <NavDropdown.Item as={NavLink} to="/meinefundorte">Meine Fundorte</NavDropdown.Item>
      <NavDropdown.Item as={NavLink} to="/neuerfundort">Neuer Fundort</NavDropdown.Item>
    </NavDropdown>
    <NavDropdown
      title="Mehr"
      id="nav-dropdown-listen">
      <NavDropdown.Item as={NavLink} to="/arten">Arten</NavDropdown.Item>
      <NavDropdown.Item as={NavLink} to="/roteliste">Rote Liste</NavDropdown.Item>
      {config.BUNDESLAND === "Bayern" ?
        <NavDropdown.Item as={NavLink} to="/galerie">Galerie</NavDropdown.Item> : null
      }
      <NavDropdown.Item as={NavLink} to="/larvaloekologie">Larvalökologie</NavDropdown.Item>
      <NavDropdown.Item as={NavLink} to="/statistik">Statistik</NavDropdown.Item>
      {config.BUNDESLAND === "Bayern" ?
        <NavDropdown.Item href="https://blog.schmetterlingebayern.de" target="_blank" rel="noopener noreferrer">
          Blog
        </NavDropdown.Item> : null
      }
      <NavDropdown.Item as={NavLink} to="/benutzungshinweise">Benutzungshinweise</NavDropdown.Item>
    </NavDropdown>
  </>

  let navbarTabs;
  if (!auth.user) {
    navbarTabs =
      <Nav className="mr-auto">
        <Nav.Link as={NavLink} to="/beobachtungen">Beobachtungen</Nav.Link>
        <Nav.Link as={NavLink} to="/arten">Arten</Nav.Link>
        {config.BUNDESLAND === "Bayern" ?
          <Nav.Link as={NavLink} to="/galerie">Galerie</Nav.Link> : null
        }
        <Nav.Link as={NavLink} to="/roteliste">Rote Liste</Nav.Link>
        <Nav.Link as={NavLink} to="/larvaloekologie">Larvalökologie</Nav.Link>
        <Nav.Link as={NavLink} to="/statistik">Statistik</Nav.Link>
        {config.BUNDESLAND === "Bayern" ?
          <Nav.Link
            href="https://blog.schmetterlingebayern.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            Blog
          </Nav.Link> : null
        }
      </Nav>
  } else {
    navbarTabs =
      <Nav className="mr-auto">
        {navUserTabs}
      </Nav>
  }

  if (auth.admin) {
    navbarTabs =
      <Nav className="mr-auto">
        {navUserTabs}
        <NavDropdown
          title={
            <span>
              <FontAwesomeIcon icon={faUserCog} /> Admin
            </span>
          }
          id="nav-dropdown-listen">
          <NavDropdown.Item as={NavLink} to="/benutzer">Benutzer</NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to="/adminbeobachtungen">Beobachtungen</NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to="/freigabe">Freigabe</NavDropdown.Item>
        </NavDropdown>
      </Nav>
  }

  let navbarRight;
  if (!auth.user) {
    navbarRight =
      <Nav className="ms-auto">
        <Navbar.Text>
          <Link to="/login">Anmelden</Link> oder <Link to="/registrierung">Registrieren</Link>
        </Navbar.Text>
      </Nav>
  } else {
    // console.log(auth.user);
    navbarRight =
      <NavDropdown className="ms-auto"
        title={
          <span>
            <img className="rounded-circle margin-8" src={userfoto} width="32" height="32" alt=""></img>
            <span style={{ color: "white" }}> {auth.name} </span>
          </span>}
        id="nav-dropdown-user">
        <NavDropdown.Item as={NavLink} to="/benutzerprofil">Benutzerprofil</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item
          as={Button}
          onClick={() => {
            auth.signout(() => navigate("/"));
          }}
        >Abmelden
        </NavDropdown.Item>
      </NavDropdown>
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
      <Container>
        <Navbar.Brand as={NavLink} to="/">
          <img
            alt=""
            src="../logo.png"
            // width="30"
            height="30"
            className="d-inline-block align-top"
          />{' '}
          <span className="title">{config.TITEL}</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {navbarTabs}
          {/* <Navbar.Collapse className="justify-content-end"> */}
          {navbarRight}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavbarComponent;
