import React from "react";
import { Circle, Pane, Tooltip } from 'react-leaflet';
import getColorByYear from "../../functions/getColorByYear";

const radius = 2500;

function ObservationCircles(props) {
  // create tk25 circles for observations
  // use unique to only render each circle once
  const circles = props.observations.map((row, index) => {

    const circleOptions = {
      group: "tk25.circles",
      color: props.color ?? "black",
      fillColor: props.fillColor ?? getColorByYear(row.jahr),
      fillOpacity: 0.8,
      opacity: 0.8,
      weight: 1
    };

    return (
      <Circle
        key={index}
        layerId={row.tk25}
        center={[row.center_lat, row.center_lng]}
        pathOptions={circleOptions}
        // radius={radio.state === false || radio.state === "tk25_quadrant" ? 2500 : 5000}
        radius={radius}
        eventHandlers={{
          click: () => {
            props.handleMapClick(row.tk25_quadrant);
          }
        }}
      >
        <Pane name={props.pane.concat(index)} style={{ zIndex: 408 }}>
          <Tooltip sticky>{row.tk25}</Tooltip>
        </Pane>
      </Circle>
    );
  });

  return (circles)
}

export default ObservationCircles;
