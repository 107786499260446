import React from "react";
import { Rectangle } from 'react-leaflet';

function TK25Raster({
  raster,
  handleMapHover,
  keyName = "tk25",
  color = "grey",
  interactive = true
}) {

  const options = {
    fill: true,
    fillOpacity: 0,
    color: color,
    weight: 1,
    group: "TK25"
  };

  const eventHandlers =
    interactive ? 
      {
        mouseover: (e) => {
          handleMapHover(e.target.options.layerId);
        },
      } : {}

  return (
    raster.map((tk25, index) => {
      const rectangle = [
        [tk25.south, tk25.west],
        [tk25.north, tk25.east],
      ];
      return (
        <Rectangle
          key={tk25[keyName]}
          layerId={tk25[keyName]}
          bounds={rectangle}
          pathOptions={options}
          eventHandlers={eventHandlers}
          interactive={interactive}
        >
        </Rectangle>
      );
    })
  );
}

export default TK25Raster;
