import React from 'react';
import Form from 'react-bootstrap/Form';

export default function UnschaerfeInput({ values, handleChange, handleBlur, drawLayer }) {
  return (
    <>
      <Form.Label>Unschärfe in m</Form.Label>
      <input
        className="form-control"
        id={"unschaerfe"}
        type="number"
        value={values.unschaerfe}
        min="0"
        max="5000"
        step="50"
        onChange={(event) => {
          handleChange(event);
          if (drawLayer.current) {
            drawLayer.current.setRadius(event.target.value);
          }
        }}
        onBlur={handleBlur}
      ></input>
    </>
  );
};
