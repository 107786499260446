import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';

// TODO: Handle cases where there is no next species
// Use praxisgruppe to filter
export default function Links(props) {
  const art = props.taxon.art.replace(" ", "_");
  const taxonID = props.taxon.inaturalist_taxon_id;

  console.log(taxonID)

  const inaturalistLink = taxonID ?
    `https://www.inaturalist.org/taxa/${taxonID}` : 
    `https://www.inaturalist.org/taxa/search?q=${props.taxon.art}`

  return (
    <>
      <h5>Links</h5>
      <ListGroup>
        <ListGroup.Item>
          <a href={`https://lepiforum.org/wiki/page/${art}`}
            rel="noopener noreferrer" target="_blank">Lepiforum</a>
        </ListGroup.Item>
        <ListGroup.Item>
          <a href={inaturalistLink}
            rel="noopener noreferrer" target="_blank">iNaturalist</a>
        </ListGroup.Item>
        {/* <ListGroup.Item>
          <a href="https://observation.org/species/685/" rel="noopener noreferrer" target="_blank">Observation.org</a>
        </ListGroup.Item> */}
      </ListGroup>
    </>
  )
}
