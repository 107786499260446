import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate, useLocation } from 'react-router-dom';
// import toast, { Toaster } from 'react-hot-toast';
import axios from "../AxiosConfig";

import { useAuth } from "./AuthContext";

axios.defaults.withCredentials = true;

function LoginForm(props) {

  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const { from } = location.state || { from: { pathname: "/meinebeobachtungen" } };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = (event) => {
    event.preventDefault();
    console.log("LoginForm");
    auth.signin(() => {
      navigate(from, { replace: true });
    }, email, password);
  };

  return (
    <div>
      <div className="auth-form">
        <div className="auth-form-body">
          <Form onSubmit={login}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>E-Mail</Form.Label>
              <Form.Control
                type="email"
                name="email"
                // value=""
                placeholder="E-Mail Adresse eingeben"
                onChange={e => setEmail(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Passwort</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Passwort eingeben"
                onChange={e => setPassword(e.target.value)} />
            </Form.Group>
            <hr></hr>
            <div className="d-grid gap-2">
              <Button variant="success" type="submit">
                Anmelden
              </Button>
            </div>
            <hr></hr>
            Noch kein Konto? <Link to="/registrierung">Hier registrieren</Link>
            <hr></hr>
            <Link to="/passwort">Passwort vergessen?</Link>
          </Form>
        </div>
      </div>
      <br></br>
      <br></br>
    </div>
  )
}

export default LoginForm;