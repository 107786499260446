import React, { useState } from "react";
import { MapContainer, Pane } from 'react-leaflet'
import LayerControl from './LayerControl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MapTable from './MapTable';
import LeafletLegend from './LeafletLegend';
import { Radio, useRadioState, Checkbox, useCheckboxState } from 'pretty-checkbox-react';
import { orderBy, uniqBy } from 'lodash';
import TK25Pane from "./TK25Pane";
import ObservationCircles from "./ObservationCircles";
import bundeslandMittelpunkte from "../../data/gadm/bundesland_mittelpunkt.json";
import AtlasRectangles from "./AtlasRectangles";
import config from '../../config/index.js';
import { Button } from "react-bootstrap";
import ResizeMap from "./ResizeMap";

const bundeslandMittelpunkt =
  bundeslandMittelpunkte.filter((bl) => (bl.Bundesland === config.BUNDESLAND));

const bundeslandMittelpunktLat = bundeslandMittelpunkt[0].latitude;
const bundeslandMittelpunktLng = bundeslandMittelpunkt[0].longitude;
const bundeslandZoomLevel = bundeslandMittelpunkt[0].zoom;

function uniqueObservationByBeobachter(arr) {
  let clean = arr.filter((arr, index, self) =>
    index === self.findIndex((t) => (t.beobachter === arr.beobachter && t.art === arr.art && t.jahr === arr.jahr)))
  return clean
}

// This is a custom React component that resizes a Leaflet map to fit its container.
// This is necessary because the map does not render correctly because the map tab is not visible at first.
// const ResizeMap = () => {
//   const map = useMap();
//   const resizeObserver = new ResizeObserver(() => {
//     map.invalidateSize();
//   });
//   const container = document.getElementById("map-container");
//   if (container) {
//     resizeObserver.observe(container);
//   }

//   return null;
// };

function Map(props) {

  const [tk25Quadrant, setTk25Quadrant] = useState(null);
  const [observations, setObservations] = useState([]);
  const [tk25Hover, setTK25Hover] = useState("TK25 Quadrant");
  const radioTK25 = useRadioState({ state: "bundesland" });
  const quadranten = useCheckboxState();

  const handleMapClick = (tk25_quadrant) => {
    let obs =
      props.observations.filter((observation) => {
        return (
          observation.tk25_quadrant === tk25_quadrant
        )
      });
    obs = uniqueObservationByBeobachter(orderBy(obs, "jahr", "desc"));
    // obs = uniqWith(orderBy(obs, "jahr", "desc"), (a, b) => a.art === b.art && a.beobachter === b.beobachter && a.jahr === b.jahr);
    // console.log(obs);
    setTk25Quadrant(tk25_quadrant);
    setObservations(obs);
  }

  const observationsWithCoords = uniqBy(orderBy(props.observations, "jahr", "desc"), "tk25_quadrant").filter((row) => {
    return (row.center_lat !== null && row.center_lng !== null);
  });

  const activeTK25Observations = observationsWithCoords.filter((row) => {
    return row.tk25_quadrant === tk25Quadrant;
  });

  let mapTableUI;
  if (tk25Quadrant !== null) {
    console.log(observations);
    mapTableUI = <MapTable observations={observations}></MapTable>;
  } else {
    mapTableUI = <p style={{ color: "grey" }}>Bei Klick auf einen TK25 Kreis werden hier die zugehörigen Beobachtungen angezeigt.</p>;
  }

  return (
    <>
      <Row>
        <Col md={12} lg={8} className="px-0 px-md-3">
          <Col xs={12} className="mb-2">
            <MapContainer
              id="map-container"
              center={[bundeslandMittelpunktLat, bundeslandMittelpunktLng]}
              zoom={bundeslandZoomLevel}
              scrollWheelZoom={true}
              fullscreenControl={true}
              minZoom={6}
            >
              <LayerControl />
              <TK25Pane
                quadranten={quadranten}
                radioTK25={radioTK25}
                handleMapHover={() => { }}
              />
              <AtlasRectangles
                observationsAtlas={props.observationsAtlas}
              />
              <Pane name="neuedaten" style={{ zIndex: 404 }}>
                <ObservationCircles
                  observations={observationsWithCoords}
                  handleMapClick={handleMapClick}
                  pane="neuedatenTK25" />
              </Pane>
              <Pane name="aktiverquadrant" style={{ zIndex: 406 }}>
                <ObservationCircles
                  observations={activeTK25Observations}
                  handleMapClick={handleMapClick}
                  fillColor="#dc3545"
                  pane="aktiverquadrantTK25"
                />
              </Pane>
              <ResizeMap />
            </MapContainer>
          </Col>
          <Row className="px-3 px-md-0">
            <Col md={6} xs={6} className="mb-3">
              <h5>TK25 Raster</h5>
              <Radio color="primary" name="map-raster" value="bundesland" {...radioTK25}>{config.BUNDESLAND}</Radio>
              <Radio color="primary" name="map-raster" value="deutschland" {...radioTK25}>Deutschland</Radio>
              <Radio color="primary" name="map-raster" value="none" {...radioTK25}>kein Raster</Radio>
              <hr></hr>
              <Checkbox color="primary" name="map-raster-quadrant" {...quadranten}>Quadranten anzeigen</Checkbox>
            </Col>
            <Col md={6} xs={12} className="mb-3">
              <h5>Legende</h5>
              <LeafletLegend></LeafletLegend>
            </Col>
          </Row>
        </Col>
        <Col md={12} lg={4} className="mb-3">
          <h4>{tk25Quadrant}</h4>
          {tk25Quadrant !== null ? <br></br> : null}
          {mapTableUI}
          {/* <br></br> */}
          {tk25Quadrant !== null ?
            !props.showButton ? <Button variant="outline-success" onClick={() => {
              props.filterTK25(tk25Quadrant);
            }}>
              TK25 Quadrant als Filter übernehmen
            </Button> :
              <Button
                onClick={props.cancelFilterTK25}
                variant="outline-secondary"
                style={{ marginLeft: "10px" }}
                size="sm"
              >
                <i className="fa fa-remove"></i> TK25 Filter löschen
              </Button> : null}
          <br></br>
        </Col>
      </Row>
    </>
  )
}

export default Map;
