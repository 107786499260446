// StatsBadge.js
import React from 'react';
import Badge from 'react-bootstrap/Badge';

export default function StatsBadge({ nArt, nObservations, nTK25	}) {
  return (
    <div className="stats-div">
      <Badge style={{ fontSize: "40%" }} bg="secondary">Arten: {nArt}</Badge>{" "}
      <Badge style={{ fontSize: "40%" }} bg="secondary">Beobachtungen: {nObservations}</Badge>{" "}
      <Badge style={{ fontSize: "40%" }} bg="secondary">TK25: {nTK25}</Badge>
    </div>
  );
}
