import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import choicesStadium from "../../data/choices_stadium.json";

export function InputStadium({ values, setFieldValue, handleBlur }) {
  return (
    <>
      <Form.Label>Stadium</Form.Label>
      <Select
        id={"stadium"}
        type={"text"}
        value={values.stadium}
        onChange={option => {
          setFieldValue("stadium", option);
          setFieldValue("anzahl_w", 0);
          setFieldValue("anzahl_m", 0);
          if (option.value === "Imago") {
            setFieldValue("larvalnahrung", "");
          } else {
            setFieldValue("nektarpflanzen", "");
          }
        }}
        options={choicesStadium}
        onBlur={handleBlur}
      />
    </>
  )
}

export function InputAnzahl({ values, handleChange, handleBlur }) {
  return (
    <>
      <Col xs={12} sm={12} md={4}>
        <Form.Label>Anzahl Gesamt</Form.Label>
        <input
          className="form-control"
          id={"anzahl"}
          type="number"
          value={values.anzahl}
          min="1"
          onChange={handleChange}
          onBlur={handleBlur}
        ></input>
      </Col>
      {
        values.stadium.value === "Imago" &&
        <Col xs={12} sm={12} md={4}>
          <Form.Label>davon Männchen</Form.Label>
          <input
            className="form-control"
            id={"anzahl_m"}
            type="number"
            value={values.anzahl_m}
            min="0"
            onChange={handleChange}
            onBlur={handleBlur}
          ></input>
        </Col>
      }
      {
        values.stadium.value === "Imago" &&
        <Col xs={12} sm={12} md={4}>
          <Form.Label>davon Weibchen</Form.Label>
          <input
            className="form-control"
            id={"anzahl_w"}
            type="number"
            value={values.anzahl_w}
            min="0"
            onChange={handleChange}
            onBlur={handleBlur}
          ></input>
        </Col>
      }
    </>
  )
}