import React from "react";
import { useTable, usePagination, useSortBy, useExpanded } from 'react-table';
import Table from 'react-bootstrap/Table';
import './ReactTable.css';


// Get visible pages from current page (1-based) and total page count
function getVisiblePages(page, totalPages) {
  // 6 pages or less
  if (totalPages <= 6) {
    return [...Array(totalPages)].map((_, i) => i + 1)
  }
  if (page <= 4) {
    // First 4 pages: 1 2 3 *4* 5 ... 7
    return [1, 2, 3, 4, 5, totalPages]
  } else if (totalPages - page < 3) {
    // Last 3 pages: 1 ... 4 *5* 6 7
    return [1, totalPages - 3, totalPages - 2, totalPages - 1, totalPages]
  } else {
    // Middle 3 pages: 1 ... 4 *5* 6 ... 8
    return [1, page - 1, page, page + 1, totalPages]
  }
}

export default function ReactTable({ ...props }) {

  const pageSizeOptions = props.pageSizeOptions === undefined ? [10, 20, 50, 100] : props.pageSizeOptions;
  const pageSizeDefault = props.pageSize === undefined ? 10 : props.pageSize;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { ...props, initialState: { pageSize: pageSizeDefault } },
    useSortBy, useExpanded, usePagination
  );

  const visiblePages = getVisiblePages(pageIndex + 1, pageCount);
  let pageButtons = []
  // console.log(visiblePages);
  visiblePages.forEach((page, index) => {
    const isCurrent = pageIndex + 1 === page;
    const className = isCurrent ? "rt-page-button rt-page-button-current" : "rt-page-button";
    // console.log(index);
    const pageButton = (
      <button key={page} className={className} onClick={() => gotoPage(page - 1)}>
        {page}
      </button>
    );
    if (page - visiblePages[index - 1] > 1) {
      pageButtons.push(
        <span className="rt-page-ellipsis" key={`ellipsis-${page}`} role="separator">
          ...
        </span>
      )
    }
    pageButtons.push(pageButton);
  })

  return (
    <div>
      <Table striped hover size="sm" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    borderBottom: 'solid 1px black',
                    background: 'rgb(43, 140, 196)',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  {column.render('Header')}
                  {
                    column.isSorted
                      ? column.isSortedDesc
                        ? <span> &#8595;</span>
                        : <span> &#8593;</span>
                      : ''
                  }
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (

              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {

                  return (
                    <td
                      {...cell.getCellProps()}
                    // style={{
                    //   padding: '10px',
                    //   border: 'solid 1px gray',
                    //   background: 'papayawhip',
                    // }}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      {pageCount > 1 ?
        <div className="rt-pagination">
          <div className="rt-pagination-info">
            <span style={{ margin: "10px" }}>
              Seite {" "}
              <strong>
                {pageIndex + 1} von {pageCount}
              </strong>{" "}
            </span>
            <select
              className="rt-page-size-select"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {pageSizeOptions.map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize} Zeilen
                </option>
              ))}
            </select>{" "}
          </div>
          <div className="rt-pagination-nav">
            <button className="rt-page-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"Zurück"}
            </button>{" "}
            {pageButtons}
            <button className="rt-page-button" onClick={() => nextPage()} disabled={!canNextPage}>
              {"Nächste"}
            </button>{" "}
          </div>
        </div>
        : <></>}
    </div>
  )
}
