import React from 'react';
import { map } from "lodash";
import PhenogramChart from './PhenogramChart';
import { aggregateSightingsByRange } from './utils-phenogram';


/**
 * Phenogram by sex (male, female, unknown)
 * @param  {Array} observations Observations
 * @param  {String} x Interval aggregation, e.g. "pentade", "monat" or "tag_im_jahr"
 * @param  {String} y If "beobachtungen" then each row is counted as 1, else number of individuals is used
 * @return {Array} <PhenogramChart>
 */
export default function PhenogramSex({ observations, x = "pentade", y = "beobachtungen" }) {

  // Select only imago
  const observationsImago = observations
    .filter(row => row.stadium === "Imago")
    .map(row => {
      row["anzahl_ub"] = row["anzahl"] - row["anzahl_m"] - row["anzahl_w"];
      return row;
    });

  // Colors of the stacked bars
  const colors = {
    "unbekannt": "#C0C0C0",
    "Männchen": "#7cb5ec",
    "Weibchen": "#F28F43"
  };

  const column = {
    "unbekannt": "anzahl_ub",
    "Männchen": "anzahl_m",
    "Weibchen": "anzahl_w"
  };

  const counts = map(["unbekannt", "Männchen", "Weibchen"], (row) => {
    return ({
      "name": row,
      "data": aggregateSightingsByRange(observationsImago, { aggregation: x, y: y, colAnzahl: column[row] }),
      "color": colors[row],
      "selected": false,
    });
  });

  return <PhenogramChart
    counts={counts}
    x={x}
    y={y}
    legend={true}
  />
}
