import React, { useRef, useState } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-hot-toast';
import choicesStadium from "../../data/choices_stadium.json";
import choicesArtDerBeobachtung from "../../data/choices_art_der_beobachtung.json";
import choicesBeleg from "../../data/choices_beleg.json";
import choicesBestimmungsmethode from "../../data/choices_bestimmungsmethode.json";
import choicesZustand from "../../data/choices_zustand.json";
import { useAuth } from '../Authentication/AuthContext.js';
import { NeueBeobachtungUI } from './NeueBeobachtungUI.js';
import axios from '../AxiosConfig.js';
import { validateForm, createObservation, createFormData, resetFormValues } from './formHelpers.js';
import bundeslandMittelpunkte from "../../data/gadm/bundesland_mittelpunkt.json";
import config from '../../config/index.js';

const bundeslandMittelpunkt =
  bundeslandMittelpunkte.filter((bl) => (bl.Bundesland === config.BUNDESLAND));
const bundeslandMittelpunktLat = bundeslandMittelpunkt[0].latitude;
const bundeslandMittelpunktLng = bundeslandMittelpunkt[0].longitude;
const bundeslandZoomLevel = bundeslandMittelpunkt[0].zoom;

/**
 * Initializes the form values.
 *
 * @param {Object} auth - The auth object.
 * @param {Array} choicesArtDerBeobachtung - The art_der_beobachtung choices.
 * @param {Array} choicesBestimmungsmethode - The bestimmungsmethode choices.
 * @returns {Object} The initialized form values.
 */
function initializeFormValues(auth, choicesArtDerBeobachtung, choicesBestimmungsmethode) {
  console.log(auth)
  return {
    zustand: { value: "Lebendfund", label: "Lebendfund" },
    stadium: { value: "Imago", label: "Imago" },
    bemerkungen: "",
    anzahl: 1,
    anzahl_m: 0,
    anzahl_w: 0,
    nektarpflanzen: "",
    larvalnahrung: "",
    datum: "",
    art: "",
    latitude: "",
    longitude: "",
    beobachter: auth.name || "",
    fundort: "",
    id_fundort: "",
    unschaerfe: 100,
    bestimmer: auth.name || "",
    begleiter: "",
    datenquelle: { value: "eigene Beobachtung", label: "eigene Beobachtung" },
    datenquelle_bemerkung: "",
    beleg: { value: "kein Beleg", label: "kein Beleg" },
    art_der_beobachtung: choicesArtDerBeobachtung[0],
    bestimmungsmethode: choicesBestimmungsmethode[0],
    bestimmungssicherheit: { value: "sicher", label: "sicher" },
  };
}

export default function NeueBeobachtung() {

  const edit = false;
  const [observation, setObservation] = useState(null);
  const [map, setMap] = useState(null);
  const [files, setFiles] = useState([]);
  const [imgCollection, setImgCollection] = useState('');
  const [source, setSource] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const auth = useAuth();
  const drawLayer = useRef(null);

  const formValues = initializeFormValues(auth, choicesArtDerBeobachtung, choicesBestimmungsmethode);
  const mapCenter = [bundeslandMittelpunktLat, bundeslandMittelpunktLng];

  return (
    <>
      <Formik
        initialValues={formValues}

        onSubmit={
          (values, { resetForm }) => {
            // alert(JSON.stringify(values, null, 2));
            let error = validateForm(values);
            if (!error) {

              // values.latitude = coordinates[0];
              // values.longitude = coordinates[1];

              console.log(values);
              let newObservation = createObservation(values, edit, files, observation);

              console.log(newObservation);
              let formData = createFormData(newObservation, imgCollection, source, edit);

              // setButtonDisabled(true);

              const axiosPromise = axios.post(`observation/create`, formData, {
              }).then(res => {
                // setButtonDisabled(false);
                console.log(res.data);
              }).catch(function (error) {
                setButtonDisabled(false);
                if (error.response) {
                  // Request made and server responded
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log('Error', error.message);
                }
              });

              toast.promise(axiosPromise, {
                loading: 'Beobachtung wird gespeichert.',
                success: 'Beobachtung gespeichert.',
                error: 'Ein Fehler ist aufgetreten.',
              });

              resetFormValues(
                resetForm,
                values,
                choicesZustand,
                choicesStadium,
                choicesBestimmungsmethode,
                choicesBeleg
              );
              // setFileUrls([]);
              setFiles([]);
              setImgCollection("");
            }
          }
        }
      >
        {
          props => {
            const {
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue
            } = props;
            return (
              <NeueBeobachtungUI
                values={values}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                map={map}
                setMap={setMap}
                files={files}
                setFiles={setFiles}
                buttonDisabled={buttonDisabled}
                imgCollection={imgCollection}
                setImgCollection={setImgCollection}
                setSource={setSource}
                id={0}
                edit={edit}
                drawLayer={drawLayer}
                mapCenter={mapCenter}
                mapZoom={bundeslandZoomLevel}
              />
            )
          }
        }
      </Formik >
    </>
  );
}
