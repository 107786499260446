import React, { useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';


function BadgeFamilie({ familie, familieDeutsch, foto, active, setActive }) {
  const bgColor = active === familie ? "primary" : "dark";
  return (
    <Link to={`?familie=${familie}`} onClick={() => setActive(familie)}>
      <Badge bg={bgColor}>
        <>
          <img width={150} alt={familieDeutsch} src={foto}></img>
          <br></br>
          {familie}
          <br></br>
          {familieDeutsch}
        </>
      </Badge>
    </Link>
  )
}

export default function Header() {

  const [active, setActive] = useState("");

  const familien = [
    {
      familie: "Hesperiidae",
      familieDeutsch: "Dickkopffalter",
      foto: "https://storage.googleapis.com/schmetterlingebayern/19f4d24c-928b-4624-b1c1-aa79ec98ee96-1.jpg"
    },
    {
      familie: "Papilionidae",
      familieDeutsch: "Ritterfalter",
      foto: "https://storage.googleapis.com/schmetterlingebayern/net24-20201003223155-1.jpg"
    },
    {
      familie: "Pieridae",
      familieDeutsch: "Weißlinge",
      foto: "https://storage.googleapis.com/schmetterlingebayern/b56ce5f4-080e-11eb-8978-ae3003c38a8d-20201019212522-1.jpg"
    },
    {
      familie: "Lycaenidae",
      familieDeutsch: "Bläulinge",
      foto: "https://storage.googleapis.com/schmetterlingebayern/net24-20201003224027-1.jpg"
    },
    {
      familie: "Riodinidae",
      familieDeutsch: "Würfelfalter",
      foto: "https://storage.googleapis.com/schmetterlingebayern/markusdumke-20200209110303-3.jpg"
    },
    {
      familie: "Nymphalidae",
      familieDeutsch: "Edelfalter",
      foto: "https://storage.googleapis.com/schmetterlingebayern/bdd96bf4-0926-11eb-8d2d-defa3bc574eb-20201009055625-2.jpg"
    },
    {
      familie: "Zygaenidae",
      familieDeutsch: "Widderchen",
      foto: "https://storage.googleapis.com/schmetterlingebayern/fbc8eb88-087c-11eb-a8b8-1e51128f66a0-20201113185744-1.jpg"
    },
    // {
    //   familie: "Sesiidae",
    //   familieDeutsch: "Glasflügler",
    //   foto: "https://storage.googleapis.com/schmetterlingebayern/2aef2831-e842-4277-b084-1b02b8c567e6-1.jpg"
    // }
  ];

  const res = familien.map((row, index) => {
    return (
      <React.Fragment key={index}>
        <BadgeFamilie
          familie={row.familie}
          familieDeutsch={row.familieDeutsch}
          foto={row.foto}
          active={active}
          setActive={setActive}
        />{" "}
      </React.Fragment>
    )
  });

  return (
    <>
      {res}
    </>
  )
}
