import axios from 'axios';
import config from '../config/index.js';

const instance = axios.create({
  baseURL: `${config.API}`
});

instance.defaults.withCredentials = true;

export default instance;
