import React from 'react';
import Table from 'react-bootstrap/Table';

export default function Statistik(props) {
  return (
    <>
      {/* <h5>Statistik</h5> */}
      <Table responsive hover size="sm">
        <thead>
          <tr>
            <th>Daten</th>
            <th>Anzahl</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Beobachtungen</td>
            <td>{props.beobachtungen}</td>
          </tr>
          <tr>
            <td>TK25</td>
            <td>{props.tk25}</td>
          </tr>
          <tr>
            <td>Fotos</td>
            <td>{props.fotos}</td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}
