import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import abelogo from '../../img/abe-logo.png';
import config from '../../config/index.js';

function Projektbeschreibung() {

  const titel = config.TITEL;

  return (
    <Card>
      <Card.Body>
        <Card.Title><h1>{titel}
          <span style={{ float: "right" }}>
            <a href="https://www.abe-entomofaunistik.org" rel="noopener noreferrer" target="_blank">
              <img src={abelogo} alt="ABE"></img>
            </a>
          </span>
        </h1></Card.Title>
        <hr></hr>
        <Card.Title>Das Projekt</Card.Title>
        <Card.Text>Das Portal „Schmetterlinge in Bayern“ stellt die Verbreitung 
          der Schmetterlingsarten in Bayern dar. Ziel des Online-Portals ist
          die Darstellung der aktuellen Verbreitung und Flugzeit (Phänologie) 
          der in Bayern vorkommenden Arten. </Card.Text>
        <Card.Text>Insbesondere sollen dadurch aktuelle Bestandsentwicklungen,
          Veränderungen von Verbreitungsarealen und phänologische Verschiebungen
          sowie neue Erkenntnisse zur Lebensweise der Schmetterlinge
          in Bayern veranschaulicht werden.</Card.Text>
        <Link to="/registrierung"><Button variant="success">&gt; Jetzt mitmachen</Button></Link>
      </Card.Body>
    </Card>
  )
}

export default Projektbeschreibung
