import React from 'react';
import Card from 'react-bootstrap/Card';

function Kontakt() {

  return (
    <Card>
      <Card.Body>
        <Card.Title>Kontakt</Card.Title>
        <Card.Text>
          Bei inhaltlichen Fragen, Anregungen oder technischen Problemen
          kontaktieren Sie uns bitte:
        </Card.Text>
        <Card.Text>
          Ansprechpartner Datenbank: Gerd Kuna: kuna.tagfalter@gmx.de
        </Card.Text>
        <Card.Text>
          Ansprechpartner technische Probleme: info@tagfalterbayern.de
        </Card.Text>
        <hr></hr>
        <Card.Title>Links</Card.Title>
        <ul>
          <li><a href="https://www.tagfalterbayern.de" rel="noopener noreferrer" target="_blank">Tagfalter in Bayern</a></li>
          <li><a href="https://lepiforum.org/" rel="noopener noreferrer" target="_blank">Lepiforum</a></li>
          <li><a href="https://www.schmetterlinge-d.de/" rel="noopener noreferrer" target="_blank">Schmetterlinge Deutschland</a></li>
          <li><a href="https://www.schmetterlinge-bw.de/" rel="noopener noreferrer" target="_blank">Schmetterlinge Baden Württemberg</a></li>
          <li><a href="https://www.schmetterlinge-brandenburg-berlin.de/" rel="noopener noreferrer" target="_blank">Schmetterlinge in Brandenburg und Berlin</a></li>
        </ul>
      </Card.Body>
    </Card>
  )
}

export default Kontakt;
