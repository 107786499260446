import React from "react";
import ReactTable from './ReactTable';
import { Link } from 'react-router-dom';

const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export default function LarvaloekologieTable(props) {

  // A function which groups by field "larvalnahrung"
  const groupByPlant = groupBy('larvalnahrung');

  const observationsByPlant = groupByPlant(props.observations);
  const observationsPlant =
    Object.entries(observationsByPlant)
      .map(
        ([larvalnahrung, subRows]) => ({ larvalnahrung, subRows })
      );
  console.log(observationsPlant);

  const data = React.useMemo(
    () =>
      observationsPlant,
    [observationsPlant]
  );

  const columns = React.useMemo(
    () => [
      {
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? <button className="rt-expander-button"><span className="rt-expander rt-expander-open" tabIndex="-1" aria-hidden="true">​</span></button> : <button className="rt-expander-button"><span className="rt-expander" tabIndex="-1" aria-hidden="true">​</span></button>}
          </span>
        ),
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
            >
              {row.isExpanded ? <button className="rt-expander-button"><span className="rt-expander rt-expander-open" tabIndex="-1" aria-hidden="true">​</span></button> : <button className="rt-expander-button"><span className="rt-expander" tabIndex="-1" aria-hidden="true">​</span></button>}
            </span>
          ) : null,
      },
      {
        Header: 'Nahrungspflanze',
        accessor: 'larvalnahrung',
        id: "larvalnahrung",
        Cell: ({ cell: { value } }) => <i>{value}</i>
      },
      {
        Header: 'Foto',
        accessor: 'foto',
        id: "foto",
        // Cell method will provide the cell value; we pass it to render a custom component
        Cell: ({ cell: { value } }) => <img src={value} alt="" width={120}></img>
      },
      // {
      //   Header: 'Art',
      //   accessor: 'art',
      //   id: "art",
      //   Cell: ({ cell: { value } }) => <i>{value}</i>
      // },
      {
        Header: 'Stadium',
        accessor: 'stadium',
        id: "stadium"
      },
      {
        Header: 'Anzahl',
        accessor: 'anzahl',
        id: "anzahl"
      },
      {
        Header: 'Beobachter',
        accessor: 'beobachter',
        id: "beobachter"
      },
      {
        Header: 'Datum',
        accessor: 'datum',
        id: "datum"
      },
      {
        Header: 'Naturraum',
        accessor: 'naturraum_name',
        id: "naturraum_name"
      },
      {
        Header: 'ID',
        accessor: 'id',
        id: "id",
        Cell: ({ cell: { value } }) => <Link to={"/beobachtung?id=".concat(value)} rel="noopener noreferrer" target="_blank">{value}</Link>
        // Cell: ({ cell: { value } }) => <a href={"beobachtungen=".concat(value)}>{value}</a>
      }
    ],
    []
  );

  return (
    <ReactTable data={data} columns={columns}></ReactTable>
  )
}
