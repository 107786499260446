import React from "react";
import { Pane, Rectangle } from 'react-leaflet';
import getColorByYear from "../../functions/getColorByYear";

// remove duplicated entries from array
function unique(arr) {
  let clean = arr.filter((arr, index, self) =>
    index === self.findIndex((t) => (t.tk25_quadrant === arr.tk25_quadrant)))
  return clean
}

function AtlasRectangles(props) {
  // create rectangles for Tagfalter Atlas Bayern
  const tagfalteratlasRectangles = unique(props.observationsAtlas).map((row, index) => {

    const tagfalteratlasOptions = {
      color: "black",
      fillColor: getColorByYear(row.jahr),
      fillOpacity: 0.8,
      opacity: 0.8,
      weight: 1
    };

    return (
      <Rectangle
        key={index}
        bounds={[[row.south, row.west], [row.north, row.east]]}
        pathOptions={tagfalteratlasOptions}
      >
      </Rectangle>
    );
  });

  return (
    <Pane name="tagfalteratlas" style={{ zIndex: 403 }}>
      {tagfalteratlasRectangles}
    </Pane>
  )
}

export default AtlasRectangles;
