import React from 'react';
import ReactTable from '../ReactTable';
import { Link } from 'react-router-dom';

function MapTablePrivate(props) {
    const data = React.useMemo(
    () =>
      props.observations,
    [props.observations]
  );

  let item2 = null;
  if (props.admin) {
    item2 = {
      Header: 'Beobachter',
      accessor: 'beobachter'
    };
  } else {
    item2 = {
      Header: 'Foto',
      accessor: 'foto',
      // Cell method will provide the cell value; we pass it to render a custom component
      Cell: ({ cell: { value } }) => <img src={value} alt="" width={120}></img>
    };
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        Cell: ({ cell: { value } }) => <Link to={"/beobachtung?id=".concat(value)} target="_blank">{value}</Link>
        // Cell: ({ cell: { value } }) => <a href={"beobachtungen=".concat(value)}>{value}</a>
      },
      item2,
      {
        Header: 'Art',
        accessor: 'art',
        Cell: ({ cell: { value } }) => <i>{value}</i>
      },
      {
        Header: 'Stadium',
        accessor: 'stadium',
      },
      {
        Header: 'Anzahl',
        accessor: 'anzahl',
      },
      {
        Header: 'Datum',
        accessor: 'datum',
      },
    ],
    [item2]
  );

  return(
    <ReactTable data={data} columns={columns} pageSizeOptions={[5, 10, 20]}></ReactTable>
  )
}

export default MapTablePrivate;