import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

function Altitude(props) {

  const observationsWithHoehe = props.observations.filter((row) => {
    return (row.hoehe !== null);
  });

  const altitudeRounded = observationsWithHoehe.map((row, index) => {
    return (Math.floor(row.hoehe / 100) * 100);
  });

  // console.log(altitudeRounded);

  let counts = [];
  let numzeros = 30;
  for (let i = 0; i < numzeros; i++) {
    counts[i] = 0;
  }

  for (let j = 0; j < altitudeRounded.length; j++) {
    let num = altitudeRounded[j];
    let idx = (30 - num / 100);
    counts[idx] = counts[idx] ? counts[idx] + 1 : 1;
  }

  // console.log(counts);

  const options = {
    title: {
      text: null
    },
    legend: {
      enabled: false
    },
    xAxis: {
      categories: Array.from(new Array(30), (val, index) => 3000 - 100 * index),
      title: {
        text: "Höhe (in m)"
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Anzahl Beobachtungen'
      },
      labels: {
        overflow: 'justify'
      }
    },
    series: [{
      name: 'Anzahl Beobachtungen',
      type: 'bar',
      data: counts
    }]
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  )
}

export default Altitude;