import React from 'react';
import Form from "react-bootstrap/Form";
import Select from 'react-select';
import { useField, useFormikContext } from "formik";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function InputSelect(props) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  const title = props.title !== undefined ? props.title : capitalizeFirstLetter(props.name);
  const placeholder = props.placeholder ? `${props.placeholder} auswählen...` : "";

  let selectStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    // placeholder: (defaultStyles) => {
    //   return {
    //     ...defaultStyles,
    //     fontSize: '0.75rem'
    //   }
    // }
  };

  // if (props.title === false) {
  //   delete selectStyles.placeholder;
  // }

  const doNothing = () => void 0;
  const select = props.onChange ?? doNothing;

  return (
    <div>
      {title ? <Form.Text>{`${title}`}</Form.Text> : null}
      <Select
        {...field}
        {...props}
        isMulti
        isClearable
        cacheOptions
        id={props.name}
        type={"text"}
        value={(field.value) || null}
        onChange={val => {
          setFieldValue(field.name, val);
          select(val);
        }}
        options={props.choices}
        closeMenuOnSelect={false}
        placeholder={placeholder}
        styles={selectStyles}
      // formatGroupLabel={formatGroupLabel}
      // formatOptionLabel={function (data) {
      //   return (
      //     <span dangerouslySetInnerHTML={{ __html: data.label }} />
      //   );
      // }}
      // menuPortalTarget={document.body}
      />
    </div>
  )
}

export default InputSelect;
