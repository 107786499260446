import React from 'react';

function NotFound(props) {
  return (
    <div style={{
      width: "100%",
      height: props.height ?? "80vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <h3>Diese Seite existiert nicht!</h3>
    </div>
  )
}

export default NotFound;
