import React from 'react';
import FiltersAdmin from '../Inputs/FiltersAdmin';
import BeobachtungenPrivate from './BeobachtungenPrivate.js';

export default function AdminBeobachtungen() {

  return (
    <BeobachtungenPrivate
      title="Alle Beobachtungen (Admin Ansicht)"
      FilterComponent={FiltersAdmin}
      filtersSelect={[
        "art",
        "ort",
        "bundesland",
        "regierungsbezirk",
        "landkreis",
        "naturraum_hauptname",
        "naturraum_name",
        "tk25",
        "fundort",
        "praxisgruppe",
        "stadium",
        "art_der_beobachtung",
        "beleg",
        "bestimmungssicherheit",
        "bestimmungsmethode",
        "erfasser",
        "beobachter",
        "datenquelle",
        "monat"
      ]}
      admin={true}
      apiEndpoint="admin"
    ></BeobachtungenPrivate>
  );
}
