import React from 'react';
import FiltersPrivate from '../Inputs/FiltersPrivate';
import BeobachtungenPrivate from './BeobachtungenPrivate';


export default function MeineBeobachtungen() {
  return (
    <BeobachtungenPrivate
      title="Meine Beobachtungen"
      FilterComponent={FiltersPrivate}
      filtersSelect={[
        "art",
        "ort",
        "bundesland",
        "regierungsbezirk",
        "landkreis",
        "naturraum_hauptname",
        "naturraum_name",
        "tk25",
        "fundort",
        "praxisgruppe",
        "stadium",
        "art_der_beobachtung",
        "beleg",
        "monat"
      ]}
      admin={false}
      apiEndpoint="user"
    ></BeobachtungenPrivate>
  );
}
