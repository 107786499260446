import React from "react";
import { MapContainer, CircleMarker, Pane, Tooltip } from 'react-leaflet';
import LayerControl from '../Map/LayerControl.js';
import bundeslandMittelpunkte from "../../data/gadm/bundesland_mittelpunkt.json";
import config from '../../config/index.js';

const bundeslandMittelpunkt = bundeslandMittelpunkte.filter((bl) => (bl.Bundesland === config.BUNDESLAND));

const bundeslandMittelpunktLat = bundeslandMittelpunkt[0].latitude;
const bundeslandMittelpunktLng = bundeslandMittelpunkt[0].longitude;
const bundeslandZoomLevel = bundeslandMittelpunkt[0].zoom;


function FundorteCircles(props) {

  const markers = props.fundorte.map((row, index) => {
    return (
      <CircleMarker
        key={row.id}
        center={[row.latitude, row.longitude]}
        radius={6}
        pathOptions={{ fillOpacity: 1 }}
        eventHandlers={{
          click: () => {
            console.log(row)
            props.selectFundort(row);
          },
        }}
      >
        <Tooltip>
          {row.fundort}
        </Tooltip>
      </CircleMarker>
    );
  });

  return (markers);
}

export default function FundorteMap(props) {

  const fundorte = props.fundorte;
  const fundort = props.selected;

  let fundortCircle = <></>;
  if (fundort) {
    fundortCircle = <CircleMarker
      key={fundort.id}
      center={[fundort.latitude, fundort.longitude]}
      radius={6}
      pathOptions={{ fillOpacity: 1, color: 'red' }}
    />;
  }

  return (
    <MapContainer
      center={[bundeslandMittelpunktLat, bundeslandMittelpunktLng]}
      zoom={bundeslandZoomLevel}
      minZoom={6}
      scrollWheelZoom={true}
      fullscreenControl={true}
      ref={props.setMap}>
      <LayerControl />
      <Pane name="fundorte" style={{ zIndex: 500 }}>
        <FundorteCircles fundorte={fundorte} selectFundort={props.selectFundort} />
      </Pane>
      <Pane name="aktuellerfundort" style={{ zIndex: 501 }}>
        {fundortCircle}
      </Pane>
    </MapContainer>
  )
}
