import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import config from '../../config/index.js';

function Projektbeschreibung() {

  const titel = config.TITEL;

  return (
    <Card>
      <Card.Body>
        <Card.Title><h1>{titel}</h1></Card.Title>
        <hr></hr>
        <Card.Title>Das Projekt</Card.Title>
        <Card.Text>
          Das Portal „Tagfalter in Thüringen“ ist die Fortführung der 
          Tagfalterfauna Thüringens von 2006, ergänzt durch die 
          Familie der tagaktiven Widderchen (<i>Zygaenidae</i>).
        </Card.Text>
        <Card.Text>
          Ganz herzlich möchten wir uns bei unseren Freunden und Mitstreitern in 
          Bayern bedanken, die es uns ermöglichten, landesgrenzüberschreitend 
          die Verbreitung und Ausbreitung aller Arten quadrantengenau in diesem 
          Portal darzustellen. Wir hoffen, dass durch diese Zusammenarbeit 
          Defizite in der Verbreitung der einzelnen Arten schnell erkannt und 
          Verbreitungslücken geschlossen werden. 
          <br></br><br></br>
          Dieses Projekt soll aktive Naturfreunde ansprechen, ihre Funde zu melden, 
          um so die Basis für eine zukünftige Rote Liste 2030 zu schaffen.
        </Card.Text>
        <Link to="/registrierung"><Button variant="success">&gt; Jetzt mitmachen</Button></Link>
      </Card.Body>
    </Card>
  )
}

export default Projektbeschreibung
