import React, { useEffect, useState } from "react";
import axios from "./AxiosConfig";
import ReactTable from './ReactTable';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import toast, { Toaster } from 'react-hot-toast';
import { uniq } from "lodash";
import config from '../config/index.js';
import Loading from "./Loading";

function Freigabe() {

  const [observations, setObservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState([]);

  const getObservations = async (artengruppe) => {
    const response = await axios.get(`admin/freigabe?bundesland=${config.BUNDESLAND}&artengruppe=${artengruppe}`);
    setObservations(response.data);
    setLoading(false);
  };

  useEffect(() => {
    getObservations(config.ARTENGRUPPE);
  }, []);

  // Create array with only one object per observation id
  let id = [];
  const uniqueObservations = observations.filter((observation) => {
    if (!id.includes(observation.id)) {
      id.push(observation.id);
      observation.freigabe = observation.id;
      return (observation)
    } else {
      return null;
    }
  });

  // Add checked item to state or remove it
  const updateChecked = (value, status) => {
    if (status) {
      setChecked(checked => [...checked, value]);
    } else {
      // this must be a function otherwise checked would still be empty
      setChecked(checked => checked.filter(item => item !== value));
    }
  }

  console.log(checked);

  function freigeben() {
    if (uniq(checked).length > 0) {
      axios.post(
        `admin/freigabe`,
        {
          id: uniq(checked)
        }
      ).then(response => {
        toast.success(`${uniq(checked).length} Beobachtungen wurden freigegeben.`);
        setChecked([]);
        console.log("freigabe");
        getObservations(config.ARTENGRUPPE);
      }).catch(function (error) {
        console.log(error);
        toast.error("Es ist ein Fehler aufgetreten!");
      });
    } else {
      toast.error("Es wurden keine Beobachtungen ausgewählt.");
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Freigabe',
        accessor: 'freigabe',
        Cell: row => {
          return (
            <div style={{ 'textAlign': 'center' }}>
              <input type="checkbox"
                key={row.value}
                defaultChecked={checked.includes(row.value)}
                onChange={(event) => updateChecked(row.value, event.target.checked)} />
            </div>)
        }
      },
      {
        Header: 'ID',
        accessor: 'id',
        Cell: ({ cell: { value } }) => <Link key={value} to={"/beobachtung?id=".concat(value)} rel="noopener noreferrer" target="_blank">{value}</Link>
      },
      {
        Header: 'Foto',
        accessor: 'foto',
        // Cell method will provide the cell value; we pass it to render a custom component
        Cell: ({ cell: { value } }) => <img key={value} src={value} alt="" width={60}></img>
      },
      {
        Header: 'Art',
        accessor: 'art',
        Cell: ({ cell: { value } }) => <i key={value}>{value}</i>
      },
      {
        Header: 'Stadium',
        accessor: 'stadium',
      },
      {
        Header: 'Anzahl',
        accessor: 'anzahl',
      },
      {
        Header: 'Beobachter',
        accessor: 'beobachter',
      },
      {
        Header: 'Datum',
        accessor: 'datum',
      },
      {
        Header: 'TK25 Quadrant',
        accessor: 'tk25_quadrant',
      },
      {
        Header: 'Hinzugefügt am',
        accessor: 'created_at',
        Cell: ({ cell: { value } }) => <p style={{ color: "grey" }}>{value}</p>
      },
      {
        Header: 'Bestimmungssicherheit',
        accessor: 'bestimmungssicherheit',
      },
      {
        Header: 'Bemerkungen',
        accessor: 'bemerkungen',
      }
    ],
    [checked]
  );

  return (
    loading ?
      <Loading /> :
      <Container>
        <Toaster position="bottom-right"></Toaster>
        <br></br>
        <br></br>
        <h4>Datenfreigabe</h4>
        <hr></hr>
        <SimpleReactLightbox>
          <SRLWrapper>
            <ReactTable
              data={uniqueObservations}
              columns={columns}
              pageSizeOptions={[10, 20, 50, 100]}
              pageSize={100}
              autoResetSortBy={false}
            />
          </SRLWrapper>
        </SimpleReactLightbox>
        <hr></hr>
        <Button
          onClick={freigeben}
          variant="success"
        >
          Ausgewählte Beobachtungen freigeben
        </Button>
        <br></br>
        <br></br>
      </Container>
  );

}

export default Freigabe;
