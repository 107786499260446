import React from 'react';
// import ListGroup from 'react-bootstrap/ListGroup';
// import { Link } from 'react-router-dom';
import Artsuche from './Artsuche';


export default function Navigation(props) {

  // let prevLink = <></>
  // if (props.taxon.prev) {
  //   const prev = props.taxon.prev.replace(" ", "_");
  //   prevLink =
  //     <ListGroup.Item>
  //       &lt; <Link to={`artportrait?art=${prev}`}>{props.taxon.prev}</Link>
  //     </ListGroup.Item>
  // }

  // let nextLink = <></>
  // if (props.taxon.next) {
  //   const next = props.taxon.next.replace(" ", "_");
  //   nextLink =
  //     <ListGroup.Item>
  //       <Link to={`artportrait?art=${next}`}>{props.taxon.next}</Link>{" "}&gt;
  //     </ListGroup.Item>
  // }

  return (
    <>
      <Artsuche />
      {/* <br></br>
      <small>
        <ListGroup horizontal>
          {prevLink}
          <ListGroup.Item>{props.taxon.art}</ListGroup.Item>
          {nextLink}
        </ListGroup>
      </small> */}
    </>
  )
}
