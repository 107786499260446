import React from 'react';
import { groupBy, map } from "lodash";
import PhenogramChart from './PhenogramChart';
import { aggregateSightingsByRange } from './utils-phenogram';


/**
 * Phenogram by stage (Imago, Larve, Ei etc.)
 * @param  {Array} observations Observations
 * @param  {String} x Interval aggregation, e.g. "pentade", "monat" or "tag_im_jahr"
 * @param  {String} y If "beobachtungen" then each row is counted as 1, else number of individuals is used
 * @return {Array} <PhenogramChart>
 */
export default function PhenogramStadium({ observations, x = "pentade", y = "beobachtungen" }) {
  // Colors of the stacked bars
  const colors = {
    "Imago": "#7cb5ec",
    "Ei": "#F28F43",
    "Eigelege": "#F28F43",
    "Larve": "#A6C96A",
    "Raupennest": "#A6C96A",
    "Gespinst": "#A6C96A",
    "Puppe": "#C0C0C0"
  };

  // Aggregate the observation counts for each stadium
  const observationsStadium = groupBy(observations, "stadium");
  const counts = map(observationsStadium, (row) => {
    return ({
      "name": row[0].stadium,
      "data": aggregateSightingsByRange(row, { aggregation: x, y: y, colAnzahl: "anzahl" }),
      "color": colors[row[0].stadium]
    });
  });

  return (
    <PhenogramChart
      counts={counts}
      x={x}
      y={y}
      legend={true}
    />
  )
}
