import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import axios from "../AxiosConfig";
import Artsuche from './Artsuche';
import Header from './Header';
import config from '../../config/index.js';


// TODO: Auswahl zwischen Galerieansicht und Arten-Liste
// evtl. Fotoauswahl zwischen OS, US, Geschlecht, Stadium?
// Filter (Praxisgruppe, Familie, Gattung etc)
// es werden dann die jeweiligen Untergruppen angezeigt
// Was ist mit Arten ohne Fotos?
export default function ArtportraitGalerie({ familie }) {

  // State
  const [taxa, setTaxa] = useState([]);
  const [loading, setLoading] = useState(true);

  console.log(familie);

  const getTaxa = async (familie) => {
    const response = await axios.get(`arten/systematik?search=${familie}&bundesland=${config.BUNDESLAND}`);
    setTaxa(response.data);
    console.log(response.data)
    setLoading(false);
  };

  useEffect(() => {
    getTaxa(familie);
  }, [familie]);

  const cards = taxa.map((item, index) => {
    const art = item.art.replace(" ", "_")
    return (
      <Link to={`/art?art=${art}`} key={index}>
        <Card style={{ width: '18rem' }}>
          <Card.Img variant="top" src={item.foto} />
          <Card.Body>
            <Card.Title>
              <i>{item.art}</i>
              {/* {item.rlbayern2016 === "2" || item.rlbayern2016 === "3" ? <span style={{ float: "right" }}><Badge variant="danger"><small>RL {item.rlbayern2016}</small></Badge></span> : ""}
              {item.rlbayern2016 === "V" ? <span style={{ float: "right" }}><Badge variant="warning"><small>RL {item.rlbayern2016}</small></Badge></span> : ""} */}
            </Card.Title>
            <Card.Subtitle>
              {item.deutscher_name}
            </Card.Subtitle>
          </Card.Body>
        </Card>
      </Link>
    )
  });

  return (
    <Container>
      <br></br>
      <Row>
        <Col md={6}>
          <Artsuche />
        </Col>
      </Row>
      <hr></hr>
      <Header />
      <hr></hr>
      {familie ? <>
        <h3>Familie {familie}</h3>
        <p>Ein Element anklicken, um auf das jeweilige Artportrait zu gelangen.</p>
        <Container className="flow-layout">
          <br></br>
          {cards}
        </Container>
        <hr></hr>
      </> : null}
    </Container >
  )
}
