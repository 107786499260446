import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Dropdown from 'react-bootstrap/Dropdown';
import { Formik } from 'formik';
import Col from 'react-bootstrap/Col';
import dayjs from 'dayjs';
import { pick, uniqBy } from "lodash";
import InputArt from './InputArt';
import InputSelect from './InputSelect';
// import InputAsyncSelect from './InputAsyncSelect';
import DatePickerField from './DatePicker';
import choicesTK25Deutschland from '../../data/tk25/choices_tk25_deutschland.json';
import choicesTK25DeutschlandNested from '../../data/tk25/choices_tk25_deutschland_nested.json';
import choicesLandkreiseDeutschland from '../../data/gadm/choices_landkreis_deutschland.json';
import choicesLandkreiseDeutschlandNested from '../../data/gadm/choices_landkreis_deutschland_nested.json';
import choicesRegierungsbezirkDeutschlandNested from '../../data/gadm/choices_regierungsbezirk_deutschland_nested.json';
import choicesStadium from "../../data/choices_stadium.json";
import choicesMonate from "../../data/choices_monat.json";
import choicesBundesland from "../../data/gadm/choices_bundesland.json";
import choicesBestimmungssicherheit from "../../data/choices_bestimmungssicherheit.json";
import config from '../../config/index.js';
import { YearInput } from './YearInput.js';

const choicesPraxisgruppe = config.PRAXISGRUPPE;
const choicesTK25Bundesland = config.CHOICESTK25;
const choicesLandkreiseBundesland = config.CHOICESLANDKREIS;
const choicesRegierungsbezirkBundesland = config.CHOICESREGIERUNGSBEZIRK;
const choicesNaturraumHauptname = config.CHOICESNATURRAUMHAUPTNAME;

function Filters(props) {

  const [choicesTK25, setChoicesTK25] = useState(choicesTK25Bundesland);
  const [choicesLandkreise, setChoicesLandkreise] = useState(choicesLandkreiseBundesland);
  const [choicesRegierungsbezirk, setChoicesRegierungsbezirk] = useState(choicesRegierungsbezirkBundesland);

  // Function to filter object of arrays and return flat unique array
  const pickValues = (choices, selected) => {
    return (
      uniqBy(
        Object.values(
          pick(choices, selected)
        ).flat(),
        "value"
      )
    );
  }

  const filterChoices = async (selected) => {
    if (selected.length > 0) {
      const bundeslandSelected = selected.map((i) => i.value);
      // Filter all TK25 to include only the selected Bundeslaender
      // Remove duplicated TK25 entries (e.g. 5727 which is in two Bundeslaender)
      setChoicesTK25(
        pickValues(choicesTK25DeutschlandNested, bundeslandSelected)
      );

      setChoicesLandkreise(
        pickValues(choicesLandkreiseDeutschlandNested, bundeslandSelected)
      );

      setChoicesRegierungsbezirk(
        pickValues(choicesRegierungsbezirkDeutschlandNested, bundeslandSelected)
      );
    } else {
      setChoicesTK25(choicesTK25Deutschland);
      setChoicesLandkreise(choicesLandkreiseDeutschland);
      setChoicesRegierungsbezirk(choicesRegierungsbezirkBundesland);
    }
  };

  return (
    <Formik
      initialValues={{
        praxisgruppe: [],
        stadium: [],
        bestimmungssicherheit: [{ value: "sicher", label: "sicher" }],
        datum_von: null,
        datum_bis: null,
        monat: [],
        regierungsbezirk: [],
        landkreis: [],
        tk25: [],
        naturraum_hauptname: [],
        bundesland: [{ value: config.BUNDESLAND, label: config.BUNDESLAND }],
        jahr_min: "",
        jahr_max: "",
        art: null
      }}

      onSubmit={
        (values, { resetForm }) => {
          let jahr_min = values.jahr_min === "" ? 1800 : values.jahr_min;
          let jahr_max = values.jahr_max === "" ? dayjs().year() : values.jahr_max;
          let datum_von = values.datum_von === null ? '1800-01-01' : dayjs(values.datum_von).format("YYYY-MM-DD");
          let datum_bis = values.datum_bis === null ? new Date() : values.datum_bis;

          props.handleSubmit({
            art: values.art !== null ? [values.art] : [], // values.art is an Object
            praxisgruppe: values.praxisgruppe,
            jahr: [jahr_min, jahr_max],
            bundesland: values.bundesland,
            naturraum_hauptname: values.naturraum_hauptname,
            tk25: values.tk25,
            regierungsbezirk: values.regierungsbezirk,
            landkreis: values.landkreis,
            monat: values.monat,
            stadium: values.stadium,
            bestimmungssicherheit: values.bestimmungssicherheit,
            datum: [datum_von, dayjs(datum_bis).format("YYYY-MM-DD")]
          });
        }
      }
    >
      {
        props => {
          const {
            values,
            // handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue
          } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <Row className='justify-content-md-left'>
                <Form.Group as={Col} xs={12} sm={12} md={4} lg={4} className="mb-2">
                  <InputArt name="art" onChange={handleSubmit}></InputArt>
                </Form.Group>
                <Form.Group as={Col} xs={12} sm={6} md={2} className="mb-2">
                  <YearInput
                    id="jahr_min"
                    value={values.jahr_min}
                    placeholder="Jahr von"
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                  />
                </Form.Group>
                <Form.Group as={Col} xs={12} sm={6} md={2} className="mb-2">
                  <YearInput
                    id="jahr_max"
                    value={values.jahr_max}
                    placeholder="Jahr bis"
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                  />
                </Form.Group>
                <Form.Group as={Col} xs={6} sm={6} md={2} className='d-md-block mb-2'>
                  <Dropdown onSelect={(open) => console.log(open)}>
                    <Dropdown.Toggle className="w-100" variant="secondary">
                      Mehr Filter
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <div className="dropdown-wide">
                        <div style={{ padding: "0 10px" }}>
                          <Row>
                            <Form.Group as={Col} md="6" xs="12" className="dropdown-filter-column-left">
                              <InputSelect
                                name="bundesland"
                                choices={choicesBundesland}
                                onChange={filterChoices}
                              />
                              <InputSelect
                                name="regierungsbezirk"
                                choices={choicesRegierungsbezirk}
                              />
                              <InputSelect
                                name="landkreis"
                                choices={choicesLandkreise}
                              />
                              <InputSelect
                                name="naturraum_hauptname"
                                title="Naturraum"
                                choices={choicesNaturraumHauptname}
                              />
                              <InputSelect
                                name="tk25"
                                title="TK25"
                                choices={choicesTK25}
                              />
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                              <InputSelect
                                name="praxisgruppe"
                                choices={choicesPraxisgruppe}
                              />
                              <InputSelect
                                name="stadium"
                                choices={choicesStadium}
                              />
                              <InputSelect
                                name="bestimmungssicherheit"
                                choices={choicesBestimmungssicherheit}
                              />
                              <InputSelect
                                name="monat"
                                choices={choicesMonate}
                              />
                              <Form.Text>Datum der Beobachtung</Form.Text>
                              <Row>
                                <Form.Group as={Col} md="6" xs={12}>
                                  <DatePickerField
                                    name="datum_von"
                                    placeholderText="Datum von..."
                                  />
                                </Form.Group>
                                <Form.Group as={Col} md="6" xs={12} className="ps-0">
                                  <DatePickerField
                                    name="datum_bis"
                                    placeholderText="Datum bis..."
                                  />
                                </Form.Group>
                              </Row>
                            </Form.Group>
                          </Row>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
                <Form.Group as={Col} xs={6} sm={6} md={2} className="d-md-block mb-2">
                  <Button variant="success" type="submit" className="w-100">Daten abfragen</Button>
                </Form.Group>
              </Row>
            </Form>
          );
        }
      }
    </Formik>
  )
}

export default Filters;
