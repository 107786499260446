import React, { useEffect } from "react";
import { MapContainer, Rectangle, useMap } from 'react-leaflet'
import LayerControl from './LayerControl';
// import { geosearch, arcgisOnlineProvider } from 'esri-leaflet-geocoder';
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";
// import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';

const esriAPIKey = process.env.REACT_APP_ESRI_API_KEY;

// const EsriGeocoder = ({ esriAPIKey }) => {
//   const map = useMap();

//   useEffect(() => {
//     const searchControl = geosearch({
//       position: "topright",
//       title: "Ort suchen",
//       placeholder: "Einen Ort oder eine Adresse suchen...",
//       useMapBounds: false,
//       // searchBounds: L.latLngBounds(L.latLng(47.5, 9.5), L.latLng(49.5, 12.5)),
//       expanded: true,
//       providers: [
//         arcgisOnlineProvider({
//           apikey: esriAPIKey,
//           countries: "DEU",
//           // nearby: {
//           //   lat: 48,
//           //   lng: 11
//           // }
//         })
//       ]
//     });

//     searchControl.addTo(map);

//     return () => {
//       searchControl.remove();
//     };
//   }, [map, esriAPIKey]);

//   return null;
// };

function BaseMap(props) {

  const tk25Options = {
    fill: true,
    fillOpacity: 0.2,
    color: "black",
    weight: 1,
    group: "observation"//,
    // layerId: tk25.tk25
  };

  const rectangle = [
    [props.observation.south, props.observation.west],
    [props.observation.north, props.observation.east],
  ];

  return (
    <MapContainer
      className={props.className}
      center={[props.observation.center_lat, props.observation.center_lng]}
      zoom={12}
      minZoom={10}
      scrollWheelZoom={true}
    >
      <FullscreenControl position="topleft" />
      {/* <EsriGeocoder esriAPIKey={esriAPIKey} /> */}
      <LayerControl />
      <Rectangle bounds={rectangle} pathOptions={tk25Options}></Rectangle>
    </MapContainer>
  )
}

export default BaseMap;
