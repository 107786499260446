import React from 'react';
import { Form } from 'react-bootstrap';
import AsyncCreatableSelect from 'react-select/async-creatable';
import choicesPflanzen from '../../data/pflanzen.json';

const filterPflanzen = (inputValue) => {
  return choicesPflanzen.filter(i =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};

const promiseOptions = inputValue =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(filterPflanzen(inputValue));
    }, 1000);
  });

const defaultPflanzen = choicesPflanzen.filter((row) => [
  // "Achillea millefolium",
  // "Bistorta officinalis",
  // "Buddleja davidii",
  // "Buphthalmum salicifolium",
  // "Calluna vulgaris",
  // "Centaurea jacea",
  // "Centaurea scabiosa",
  // "Cirsium arvense",
  // "Cirsium palustre",
  // "Cirsium vulgare",
  // "Dianthus carthusianorum",
  // "Echium vulgare",
  // "Eupatorium cannabinum",
  // "Frangula alnus",
  // "Ligustrum vulgare",
  // "Lotus corniculatus",
  // "Lythrum salicaria",
  // "Knautia arvensis",
  // "Medicago sativa",
  // "Prunus spinosa",
  // "Origanum vulgare",
  // "Salix caprea",
  // "Sanguisorba officinalis",
  // "Scabiosa columbaria",
  // "Taraxacum officinale",
  // "Thymus pulegioides",
  // "Trifolium pratense",
  // "Tussilago farfara",
].includes(row.value));

console.log(defaultPflanzen)

export default function NahrungspflanzenSelect({ stadium, values, setFieldValue, handleBlur }) {
  return (
    <>
      {
        stadium.value === "Imago" ?
          <Form.Label>Nektarpflanzen</Form.Label> :
          <Form.Label>Nahrung der Raupe</Form.Label>
      }
      {
        stadium.value === "Imago" ?
          <AsyncCreatableSelect
            isMulti
            isClearable
            cacheOptions
            defaultOptions={defaultPflanzen}
            id={"nektarpflanzen"}
            type={"text"}
            value={values.nektarpflanzen}
            onChange={option => setFieldValue("nektarpflanzen", option)}
            loadOptions={promiseOptions}
            onBlur={handleBlur}
            closeMenuOnSelect={true}
            placeholder="Nektarpflanzen auswählen..."
            formatCreateLabel={(inputValue) => `Eingabe übernehmen "${inputValue}"`}
            noOptionsMessage={() => "Artnamen eingeben"}
          /> :
          <AsyncCreatableSelect
            isClearable
            cacheOptions
            defaultOptions={defaultPflanzen}
            id={"larvalnahrung"}
            type={"text"}
            value={values.larvalnahrung}
            onChange={option => setFieldValue("larvalnahrung", option)}
            loadOptions={promiseOptions}
            onBlur={handleBlur}
            closeMenuOnSelect={true}
            placeholder="Nahrungspflanze auswählen..."
          />
      }
    </>
  );
};
