import React from "react";
import Table from 'react-bootstrap/Table';
import proj4 from 'proj4';
proj4.defs("EPSG:32632", "+proj=utm +zone=32 +datum=WGS84 +units=m +no_defs +type=crs");
// proj4.defs("EPSG:31466","+proj=tmerc +lat_0=0 +lon_0=6 +k=1 +x_0=2500000 +y_0=0 +ellps=bessel +nadgrids=@beta2007,null +units=m +no_defs +type=crs");

function WGS84ToUTM(lng, lat) {
  return proj4(new proj4.Proj('EPSG:32632'), [lng, lat]);
}

// function WGS84ToGK(lng, lat) {
//   return proj4(new proj4.Proj('EPSG:31466'), [lng, lat]);
// }

function round(x, decimals = 0) {
  return Math.round(10 ** decimals * x) / (10 ** decimals)
};

export default function KoordinatenTabelle({ props }) {

  const latitudeRounded = round(props.latitude, 5);
  const longitudeRounded = round(props.longitude, 5);
  let urlBayernAtlas = null;
  const urlGoogle = `https://www.google.com/maps?q=${latitudeRounded},${longitudeRounded}`;
  const urlOSM = `https://www.openstreetmap.org/?mlat=${latitudeRounded}&mlon=${longitudeRounded}#map=15/${latitudeRounded}/${longitudeRounded}`;
  let spanBayernAtlas = null;
  const utmCoords = WGS84ToUTM(
    Number(props.longitude),
    Number(props.latitude)
  );
  if (props.bundesland === "Bayern") {
    urlBayernAtlas = `https://geoportal.bayern.de/bayernatlas/?lang=de&topic=ba&bgLayer=atkis&catalogNodes=11&E=${utmCoords[0]}&N=${utmCoords[1]}&zoom=11&crosshair=marker`;
    spanBayernAtlas =
      <span>
        {" · "}
        <a href={urlBayernAtlas} rel="noopener noreferrer" target="_blank">
          Bayern Atlas
        </a>
      </span>
  }

  return (
    <>
      <div>
        <small>
          <span>{"Ansehen auf "}
            <a href={urlOSM} rel="noopener noreferrer" target="_blank">
              OpenStreetMap
            </a>
          </span>{" · "}
          <span>
            <a href={urlGoogle} rel="noopener noreferrer" target="_blank">
              Google Maps
            </a>
          </span>
          {spanBayernAtlas}
        </small>
      </div>
      <br></br>
      <Table responsive hover size="sm">
        <thead>
          <tr>
            <td>Längengrad</td>
            <td>Breitengrad</td>
            <td>Höhe (in m)</td>
            <td>Unschärfe (in m)</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{longitudeRounded}</td>
            <td>{latitudeRounded}</td>
            <td>{props.hoehe}</td>
            <td>{props.unschaerfe}</td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}
