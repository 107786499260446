import React from "react";
import NaturraumTabelle from "../Observation/NaturraumTabelle";
import KoordinatenTabelle from "../Observation/KoordinatenTabelle";
import RegionTabelle from "../Observation/RegionTabelle";
import TK25Tabelle from "../Observation/TK25Tabelle";

export default function FundortTabelle(props) {
  const observation = props.observation;

  let coordinatesTable = null;
  if (observation.latitude !== null) {
    coordinatesTable = <KoordinatenTabelle props={observation} />
  }

  let naturraumTable = null;
  if (observation.naturraum_name !== null) {
    naturraumTable = <NaturraumTabelle props={observation} />
  }

  let regionTable = null;
  if (observation.landkreis !== null) {
    regionTable = <RegionTabelle props={observation} />
  }
  return (
    <>
      {coordinatesTable}
      {naturraumTable}
      {regionTable}
      <TK25Tabelle props={observation} />
    </>
  )
}
