import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { Radio, RadioGroup} from 'react-radio-group';
import { Checkbox, useCheckboxState, Radio, useRadioState } from 'pretty-checkbox-react';
import ReactTable from '../ReactTable';
import '@djthoms/pretty-checkbox';
import { groupBy, sortBy, map } from "lodash";
import { id } from "../TableColumns";
// import Entwicklung from './Entwicklung';
// import PhenoAltitude from './PhenoAltitude';
// import Altitude from './Altitude';
// import PhenoChartJahr from './PhenoChartJahr';
// import PhenoChart from './PhenoChartNaturraum';

function FlugzeitTabelle(props) {

  const observationsTable = sortBy(props.observations, "tag_im_jahr");

  const columns = React.useMemo(
    () => [
      id,
      {
        Header: 'Art',
        accessor: 'art',
        Cell: ({ cell: { value } }) => <i>{value}</i>
      },
      {
        Header: 'Stadium',
        accessor: 'stadium'
      },
      {
        Header: 'Anzahl',
        accessor: 'anzahl'
      },
      {
        Header: 'Beobachter',
        accessor: 'beobachter',
      },
      {
        Header: 'Tag im Jahr',
        accessor: 'tag_im_jahr'
      },
      {
        Header: 'Datum',
        accessor: 'datum',
      },
      {
        Header: 'Naturraum',
        accessor: 'naturraum_name',
      }
    ],
    []
  );

  return (
    <ReactTable data={observationsTable} columns={columns}></ReactTable>
  )
}

// function counts number of occurences of each element in an array
function aggregateSightings(data, aggregation, numzeros, y, colAnzahl) {

  let counts = [];
  for (let i = 0; i < numzeros; i++) {
    counts[i] = 0;
  }

  for (let i = 0; i < data.length; i++) {
    let anzahl = (y === "beobachtungen" && data[i][colAnzahl] > 0) ? 1 : data[i][colAnzahl];
    let num = data[i][aggregation];
    let idx = num - 1;
    counts[idx] = counts[idx] ? counts[idx] + anzahl : anzahl;
  }

  return counts
}

function aggregateSightingsByRange(data, aggregation, y, colAnzahl) {
  let counts;
  switch (aggregation) {
    case "tag_im_jahr":
      counts = aggregateSightings(data, "tag_im_jahr", 365, y, colAnzahl);
      break;
    case "monat":
      counts = aggregateSightings(data, "monat", 12, y, colAnzahl);
      break;
    default:
      counts = aggregateSightings(data, "pentade", 72, y, colAnzahl);
  };
  return (counts);
}

function PhenogramChartGender(props) {
  // Colors of the stacked bars
  const colors = {
    "alle": "#C0C0C0",
    "Männchen": "#A6C96A",
    "Weibchen": "#F28F43"
  };

  const column = {
    "alle": "anzahl",
    "Männchen": "anzahl_m",
    "Weibchen": "anzahl_w"
  };

  const counts = [
    {
      "name": props.sex,
      "data": aggregateSightingsByRange(props.observations, props.selected, props.y, column[props.sex]),
      "color": colors[props.sex]
    }
  ];

  return <PhenogramChart
    selected={props.selected}
    stadium={props.stadium}
    y={props.y}
    counts={counts}
    legend={true}
  />
}

function PhenogramChartStadium(props) {

  // Colors of the stacked bars
  const colors = {
    "Imago": "#7cb5ec",
    "Ei": "#F28F43",
    "Eigelege": "#F28F43",
    "Larve": "#A6C96A",
    "Raupennest": "#A6C96A",
    "Gespinst": "#A6C96A",
    "Puppe": "#C0C0C0"
  };

  // Aggregate the observation counts for each stadium
  const observationsStadium = groupBy(props.observations, "stadium");
  const counts = map(observationsStadium, (row) => {
    return ({
      "name": row[0].stadium,
      "data": aggregateSightingsByRange(row, props.selected, props.y, "anzahl"),
      "color": colors[row[0].stadium]
    });
  });

  return <PhenogramChart
    selected={props.selected}
    stadium={props.stadium}
    y={props.y}
    counts={counts}
    legend={props.stadium.state.length > 1}
  />
}

function PhenogramChart(props) {

  let numzeros;
  let step;
  let positions;
  let tickInterval;
  switch (props.selected) {
    case "tag_im_jahr":
      numzeros = 365;
      positions = Array.from({ length: 12 }, (_, i) => 15 + 30 * i);
      tickInterval = 15;
      step = 30;
      break;
    case "monat":
      numzeros = 12;
      positions = Array.from(Array(12).keys());
      tickInterval = 1;
      step = 1;
      break;
    default:
      numzeros = 72;
      positions = Array.from({ length: 12 }, (_, i) => 2 + 6 * i);
      tickInterval = 2;
      step = 6;
  };

  // Define positions for month labels on the x-axis
  const categories = Array.from(new Array(numzeros), (val, index) => index);
  const months = [["Jan"], ["Feb"], ["Mar"], ["Apr"], ["Mai"], ["Jun"],
  ["Jul"], ["Aug"], ["Sep"], ["Okt"], ["Nov"], ["Dez"]];
  let monthLabels = Array(numzeros).fill("");
  for (let i = 0; i < months.length; i++) {
    monthLabels[positions[i]] = months[i][0];
  }

  // Define the placements of grid lines
  let gridLines = [];
  if (props.selected === "tag_im_jahr") {
    const gridLinePositions = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334, 365];
    for (let i = 0; i < (months.length + 1); i++) {
      gridLines.push({
        color: 'lightgrey',
        value: gridLinePositions[i] - 0.5
      })
    }
  } else {
    for (let i = 0; i < (months.length + 1); i++) {
      gridLines.push({
        color: 'lightgrey',
        value: (step * i) - 0.5
      })
    }
  }

  const options = {
    chart: {
      type: 'column',
      zoomType: "xy",
      resetZoomButton: {
        align: "left"
      },
      // marginBottom: 70
    },
    title: {
      text: null
    },
    legend: {
      enabled: props.legend,
      align: "center",
      verticalAlign: "top",
      // floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false
    },
    yAxis: {
      min: 0,
      title: {
        text: props.y === "beobachtungen" ? 'Anzahl Beobachtungen' : "Anzahl Individuen"
      },
    },
    xAxis: {
      categories: categories,
      tickInterval: tickInterval,
      labels: {
        rotation: 0,
        formatter: function () { return monthLabels[this.value]; },
      },
      plotLines: gridLines
    },
    plotOptions: {
      column: {
        stacking: 'normal'
      }
    },
    series: props.counts
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  )
}

function Phenogram(props) {

  const radio = useRadioState({ state: "pentade" });
  const radioY = useRadioState({ state: "beobachtungen" });
  const stadium = useCheckboxState({ state: ["Imago"] });
  const geschlecht = useCheckboxState();

  // Filter observations by the selected stadium and exclude Zucht and Totfund/Diapause
  const observationsPheno = props.observations.filter((observation, index) => {
    return (
      observation.datum !== null &&
      observation.datum.length > 4 &&
      stadium.state.includes(observation.stadium) &&
      observation.zustand !== "Totfund" &&
      observation.zustand !== "Diapause" &&
      observation.art_der_beobachtung !== "Zucht"
    );
  });

  // Flugzeit über mehrere Jahre
  // Box mit n= anzeigen

  console.log(geschlecht.state)

  return (
    <>
      <Row>
        <Col md={12} lg={8} className="px-0 px-md-3">
          {
            // geschlecht.state && (stadium.state.length === 1 && stadium.state.includes("Imago")) ?
            //   <>
            //     <PhenogramChartGender
            //       observations={observationsPheno}
            //       selected={radio.state}
            //       stadium={stadium}
            //       y={radioY.state}
            //       sex={"Männchen"}
            //     />
            //     <PhenogramChartGender
            //       observations={observationsPheno}
            //       selected={radio.state}
            //       stadium={stadium}
            //       y={radioY.state}
            //       sex={"Weibchen"}
            //     />
            //   </> :
              <PhenogramChartStadium
                observations={observationsPheno}
                selected={radio.state}
                stadium={stadium}
                y={radioY.state}
              />
          }
          <FlugzeitTabelle observations={observationsPheno} stadium={stadium} />
        </Col>
        <Col md={4}>
          <h5>Daten</h5>
          <p style={{ color: "grey" }}>
            Standardmäßig werden nur Lebendfunde, nicht aber
            Totfunde, Diapause und Zuchtbeobachtungen gezählt.
          </p>
          <h6>Auswahl Stadium</h6>
          <Checkbox color="primary" name="stadium" value="Imago" {...stadium}>Imago</Checkbox>
          <Checkbox color="primary" name="stadium" value="Ei" {...stadium}>Ei</Checkbox>
          <Checkbox color="primary" name="stadium" value="Eigelege" {...stadium}>Eigelege</Checkbox>
          <Checkbox color="primary" name="stadium" value="Larve" {...stadium}>Larve</Checkbox>
          <Checkbox color="primary" name="stadium" value="Raupennest" {...stadium}>Raupennest</Checkbox>
          <Checkbox color="primary" name="stadium" value="Gespinst" {...stadium}>Gespinst</Checkbox>
          <Checkbox color="primary" name="stadium" value="Puppe" {...stadium}>Puppe</Checkbox>
          <br></br>
          <hr></hr>
          <h5>Darstellung</h5>
          <h6>x-Achse</h6>
          <Radio color="primary" name="aggregation" value="tag_im_jahr" {...radio}>Tage</Radio>
          <Radio color="primary" name="aggregation" value="pentade" {...radio}>Pentaden</Radio>
          <Radio color="primary" name="aggregation" value="monat" {...radio}>Monate</Radio>
          <br></br>
          <br></br>
          <h6>y-Achse</h6>
          <Radio color="primary" name="y-axis" value="beobachtungen" {...radioY}>Anzahl Beobachtungen</Radio>
          <Radio color="primary" name="y-axis" value="individuen" {...radioY}>Anzahl Individuen</Radio>
          {/* <hr></hr>
          <h6>Gruppierung</h6>
          <Checkbox
            color="primary"
            disabled={stadium.state.length > 1 || !stadium.state.includes("Imago")}
            {...geschlecht}
          >
            {geschlecht.state}
            nach Geschlecht (nur wenn Imago ausgewählt ist)
          </Checkbox> */}
        </Col>
      </Row>
      {/* <hr></hr>
      <Row>
        <h4>Pro Jahr</h4>
        <Container>
          <Entwicklung observations={props.observations} />
        </Container>
      </Row>
      <hr></hr>
      <Row>
        <h4>Tabelle Phänologie</h4>
        <Container>
          <br></br>
          <FlugzeitTabelle observations={observationsPheno} stadium={stadium} />
        </Container>
      </Row>
      <hr></hr>
      <h4>Höhenverbreitung</h4>
      <Row>
        <Col>
          <Altitude observations={props.observations} />
        </Col>
        <Col>
          <PhenoAltitude />
        </Col>
      </Row>
      <hr></hr>
      <h4>Nach Jahr</h4>
      <PhenoChart />
      <PhenoChartJahr /> */}
      <br></br>
    </ >
  )
}

export {
  Phenogram,
  PhenogramChart,
}
