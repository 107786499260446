import React from "react";
import { Pane, Polyline } from 'react-leaflet'
import tk25Deutschland from "../../data/tk25/tk25_deutschland.json";
import quadrantenDeutschland from "../../data/tk25/tk25_quadrant_deutschland.json";
import TK25Raster from "./TK25Raster";
import config from "../../config/index.js";
// import bundeslaender from "../../data/gadm/bundeslaender.json";

const tk25Bundesland = config.TK25BUNDESLAND;
const shapeBundesland = config.SHAPEBUNDESLAND;
const quadrantenBundesland = config.QUADRANTENBUNDESLAND;

// console.log(config);

function TK25Pane(props) {
  // create tk25 grid
  let tk25Raster = null;
  let quadrantenRaster = null;
  if (props.radioTK25.state === "bundesland") {
    if (props.quadranten.state === true) {
      quadrantenRaster =
        <TK25Raster
          raster={quadrantenBundesland}
          keyName="tk25_quadrant"
          color="darkgrey"
          handleMapHover={props.handleMapHover}
        />;
    }
    tk25Raster =
      <TK25Raster
        raster={tk25Bundesland}
        keyName="tk25"
        color="grey"
        handleMapHover={props.handleMapHover}
      />;
  } else if (props.radioTK25.state === "deutschland") {
    if (props.quadranten.state === true) {
      quadrantenRaster =
        <TK25Raster
          raster={quadrantenDeutschland}
          keyName="tk25_quadrant"
          color="darkgrey"
          handleMapHover={props.handleMapHover}
        />;
    }
    tk25Raster =
      <TK25Raster
        raster={tk25Deutschland}
        keyName="tk25"
        color="grey"
        handleMapHover={props.handleMapHover}
      />;
  } else {
    tk25Raster = null;
  }

  // console.log(bundeslaender);
  // const polylines = bundeslaender.map((bundesland) => {
  //   return (
  //     <Polyline
  //       key={bundesland.bundesland}
  //       positions={bundesland.shape}
  //       pathOptions={{ color: 'black', weight: 2.5 }}
  //     />
  //   )
  // });

  return (
    <div>
      <Polyline positions={shapeBundesland} pathOptions={{ color: 'black', weight: 2 }} />
      {/* {polylines} */}
      <Pane name="quadranten" style={{ zIndex: 401 }}>
        {quadrantenRaster}
      </Pane>
      <Pane name="tk25" style={{ zIndex: 402 }}>
        {tk25Raster}
      </Pane>
    </div>
  )
}

export default TK25Pane;
