import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Figure from 'react-bootstrap/Figure';
import dryas from '../../img/dryas.jpg';
import mnemosyne from '../../img/mnemosyne.jpg';


function FotosHomepage() {
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col xs={12} md={6}>
            <Figure>
              <Figure.Image
                width={300}
                alt="Minois dryas"
                src={dryas}
              />
              <Figure.Caption>
                Das Blaukernauge (<i>Minois dryas</i>) eine Charakterart des Kyffhäusers.
              </Figure.Caption>
            </Figure>
          </Col>
          <Col xs={12} md={6}>
            <Figure>
              <Figure.Image
                width={300}
                alt="Parnassius mnemosyne"
                src={mnemosyne}
              />
              <Figure.Caption>
                Der Schwarze Apollo (<i>Parnassius mnemosyne</i>) ist nur noch ganz lokal im ehemaligen Grenzstreifen zu Bayern zu finden.
              </Figure.Caption>
            </Figure>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default FotosHomepage;
