import React from 'react';
import PhenogramChart from './PhenogramChart';
import { aggregateSightingsByRange } from './utils-phenogram';


/**
 * Phenogram of Imagines
 * @param  {Array} observations Observations
 * @param  {String} x Interval aggregation, e.g. "pentade", "monat" or "tag_im_jahr"
 * @param  {String} y If "beobachtungen" then each row is counted as 1, else number of individuals is used
 * @return {Array} <PhenogramChart>
 */
export default function PhenogramImago({ observations, x = "pentade", y = "beobachtungen" }) {
  // Select only imago
  const observationsImago = observations.filter(row => row.stadium === "Imago");

  // Aggregate the observation counts
  const counts = [{
    "name": "Imago",
    "data": aggregateSightingsByRange(observationsImago, { aggregation: x, y: y, colAnzahl: "anzahl" }),
    "color": "#7cb5ec",
  }];

  return (
    <PhenogramChart
      counts={counts}
      x={x}
      y={y}
      legend={false}
    />
  )
}
