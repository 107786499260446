import React, { useState } from "react";
import InputArt from './Inputs/InputArt';
import { Formik } from 'formik';
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import axios from "./AxiosConfig";
import Fotogalerie from "./Beobachtungen/Fotogalerie";
import config from '../config/index.js';

function Galerie() {

  const [images, setImages] = useState([]);

  const getImages = async (art) => {
    const response = await axios.get(`galerie?art=${art}&bundesland=${config.BUNDESLAND}`);
    setImages(response.data);
    console.log(response.data);
  };

  return (
    <Formik
      initialValues={{
        art: null
      }}

      onSubmit={
        (values) => {
          console.log("get images");
          // load images using axios and display
          getImages(values.art.value);
        }
      }
    >
      {
        props => {
          const {
            // values,
            // handleChange,
            handleSubmit,
            // handleBlur,
            // setFieldValue
          } = props;
          return (
            <div>
              <Container>
                <br></br>
                <h3>Galerie</h3>
                <p style={{ color: "grey" }}>
                  Die Galerie enthält besonders schöne oder aussagekräftige Fotos zur Lebensweise der ausgewählten Art.
                </p>
                <Form style={{ maxWidth: "400px" }} onSubmit={handleSubmit}>
                  <InputArt name="art" onChange={handleSubmit}></InputArt>
                </Form>
                <hr></hr>
                <Fotogalerie
                  observations={images}
                />
              </Container>
            </div>
          );
        }
      }
    </Formik>
  )
}

export default Galerie;