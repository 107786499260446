
import React from 'react';
// import { Formik, Field, useFormik } from 'formik';
// import * as Yup from 'yup';
// import Form from 'react-bootstrap/Form';
// import FormControl from 'react-bootstrap/FormControl';
// import FormGroup from 'react-bootstrap/FormGroup';
// import FormLabel from 'react-bootstrap/FormLabel';
// import Button from 'react-bootstrap/Button';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/Container";
// import { Checkbox } from 'pretty-checkbox-react';
// import { Link } from 'react-router-dom';

const Nutzungsvereinbarung = () => {

  return (
    <div>
      <br></br>
      <Container className="panel" style={{ maxWidth: 900 }}>
        <h3>Nutzungsvereinbarung</h3>
        <hr></hr>
        <ol>
          <li>
            Das Projekt „Tagfalter in Bayern“ mit seinem Onlineportal
            „Tagfalter in Bayern“ (www.tagfalterbayern.de) ist ein
            uneigennütziges und zeitlich unbefristetes Projekt der
            Arbeitsgemeinschaft Bayerischer Entomologen e.V. (ABE),
            bearbeitet von einem Team aus ehrenamtlichen Projektkoordinatoren.
          </li>
          <br></br>
          <li>
            Primäres Ziel des Projektes ist es, kontinuierlich einen
            umfassenden Überblick über die aktuelle, aber auch ehemalige
            Verbreitung und Bestandssituation der Tagfalter in Bayern zu
            geben und vorhandene Kenntnislücken, auch zur Ökologie,
            kontinuierlich zu schließen.
          </li>
          <br></br>
          <li>
            Die Nutzung des öffentlich zugängigen Onlineportals
            „Tagfalter in Bayern“ ist kostenlos, ebenso wie die Registrierung und Datenmeldung.
          </li>
          <br></br>
          <li>
            Im Zuge der Registrierung im Onlineportal „Tagfalter in Bayern“
            akzeptiere ich alle nachfolgend getroffenen Vereinbarungen zur
            Datennutzung und bezüglich des Datenschutzes.
          </li>
          <br></br>
          <li>
            Für eine erfolgreiche Registrierung im Onlineportal
            „Tagfalter in Bayern“ sind alle Pflichtangeben vollständig anzugeben.
            Grundvoraussetzung ist insbesondere die Angabe des vollständigen
            Namens, um kontinuierlich eine seriöse wissenschaftliche Arbeit
            und Kommunikation zu gewährleisten.
          </li>
          <br></br>
          <li>
            Mit der Registrierung erkläre ich mich einverstanden, dass die
            von mir zur Verfügung gestellten Datensätze (eigene
            Beobachtungsdaten und auch Daten, die mir von Dritten mit
            ausdrücklicher Genehmigung zur Verfügung gestellt wurden) sowie
            eigene beigestellte Multimediadateien (z.B. Bilder) unter
            Wahrung der Urheberrechte kostenfrei für nichtkommerzielle
            Zwecke im Projekt „Tagfalter in Bayern“ der Arbeitsgemeinschaft
            Bayerischer Entomologen e.V. (ABE) sowohl im Onlineportal
            „Tagfalter in Bayern“, als auch in einer möglichen gedruckten
            Version, sowie in Vereinspublikationen (Aufsätze und Kurzberichte)
            genutzt werden dürfen. Die Arbeitsgemeinschaft Bayerischer
            Entomologen e.V. (ABE) erhält dabei keinen Exklusivitätsanspruch
            für meine Datensätze und Mediadateien.
          </li>
          <br></br>
          <li>
            Ich stelle meine Datensätze, so weit wie möglich, in hoher
            räumlicher und zeitlicher Auflösung (punktgenaue Funddaten,
            exaktes Datum)  als so genannte Quelldaten bereit, um eine
            gezielte Qualitätskontrolle zu ermöglichen und Fehler bei der
            Aggregation für kartographische Darstellungen zu minimieren.
          </li>
          <br></br>
          <li>
            Die öffentlich zugänglichen Online-Darstellungen der von mir
            zur Verfügung gestellten Datensätze oder sonstige
            Veröffentlichungen der Datensätze in digitaler oder analoger Form
            erfolgen ausschließlich in räumlich aggregierter
            Form (Darstellung ohne punktgenaue Fundortdaten;
            Auflösung maximal bis auf TK25-Quadranten).
          </li>
          <br></br>
          <li>
            Bei den folgenden Nutzungsänderungen ist vorher die
            ausdrückliche Genehmigung (in elektronischer oder schriftlicher
            Form) bei mir als Datenmelder einzuholen:
          </li>
          <br></br>
          <ul>
            <li>
              Bei einer Nutzung, die über die o.g. Zielstellung des
              Projektes hinausgeht.
            </li>
            <li>
              Bei der öffentlichen Nutzung von Funddaten in höherer
              Auflösung als TK25-Quadranten.
            </li>
            <li>
              Bei einer Weitergabe von Datensätzen oder Multimediadateien
              an Dritte (=Projektfremde Verwendung, z.B. Lokalfaunistische
              Kartierungsprojekte, Publikationen, wissenschaftliche Arbeiten,
              nationale oder internationale Forschungsprojekte; siehe auch
              erste Sonderfreigabe unter Punkt 21).
            </li>
          </ul>
          <br></br>
          <li>
            Ich bin und bleibe alleiniger Eigentümer meiner Datensätze
            sowie Urheber aller von mir beigestellten, selbst erstellten
            Multimediadateien. Ich trete lediglich ein nicht ausschließliches
            Nutzungsrecht meiner Daten und Dateien an die Arbeitsgemeinschaft
            Bayerischer Entomologen e.V. (ABE) ab, welche diese
            (personenbezogene Daten und Sachdaten) ausschließlich in der
            vereinseigenen Projektdatenbank verwaltet. Ich bin berechtigt,
            weiterhin alle meine Datensätze und Mediadateien für eigene Zwecke
            zu nutzen.
          </li>
          <br></br>
          <li>
            Alle Funddaten (einschließlich aller Detailangaben), die
            von mir gemeldet, eingegeben oder hochgeladen wurden, können
            jederzeit von mir eingesehen und heruntergeladen werden.
          </li>
          <br></br>
          <li>
            Ich bin mir bewusst, dass einmal freigegebene Funddaten
            (=Sachdaten) nicht wieder gelöscht oder gesperrt werden können,
            da sich sonst die Datengrundlage von bereits durchgeführten und
            unter Umständen veröffentlichten Auswertungen nachträglich
            ändern würde. Dies widerspräche den Grundregeln wissenschaftlicher
            Arbeit. Eingabe- oder Bestimmungsfehler, die erst zu einem
            späteren Zeitpunkt erkannt werden, können aber in Abstimmung
            zwischen Datenmelder und Projektkoordinatoren nachträglich
            editiert und korrigiert werden.
          </li>
          <br></br>
          <li>
            Um den hohen wissenschaftlichen Anspruch, den die Projekte
            an sich selbst stellen, gerecht zu werden, komme ich
            dem ausdrücklichen Wunsch des Projektteams nach, und
            erlaube, dass meine Fundmeldungen in interaktiven Karten
            und Datentabellen mit meinem Vor- und Nachnamen sowie
            dem Funddatum versehen werden dürfen. Mitgeliefertes
            oder hochgeladenes Bildmaterial darf in den eingewilligten
            Projekten als Belegfoto am Datensatz oder für die grafische
            Gestaltung des Online-Auftritts verwendet und veröffentlicht
            werden. Es wird bei Verwendung stets mit meinem Namen
            versehen und bleibt so urheberrechtlich geschützt.
          </li>
          <br></br>
          <li>
            Ich erlaube den Projektkoordinatoren des Projekts
            „Tagfalter in Bayern“ hiermit, bei besonders hochgradig
            gefährdeten Arten genaue Orts- und Zeitangaben der
            Beobachtungsmeldung zu unterdrücken, zu aggregieren oder unscharf
            wieder zu geben. Falls dieser Sachverhalt zutrifft, werde ich von
            den Projektkoordinatoren schriftlich darauf hingewiesen.
          </li>
          <br></br>
          <li>
            <p>
              Ich bin hiermit davon in Kenntnis gesetzt, dass die
              Arbeitsgemeinschaft Bayerischer Entomologen e.V. (ABE) und damit
              auch die Projektkoordination  des Projekts „Tagfalter in Bayern“
              den Datenschutz gemäß der Rechtslage einhalten wird und meine
              personenbezogenen Daten vertraulich und entsprechend der
              gesetzlichen Vorschriften behandelt.
              Insbesondere werden meine personenbezogenen Daten niemals ohne meine
              ausdrückliche Genehmigung (in elektronischer oder schriftlicher Form) an
              Dritte weitergeben. Als personenbezogen gelten: Name, Wohnort,
              Kontaktdaten (E-Mail, Telefonnummern, Skype usw.). Im Rahmen des Projektes
              „Tagfalter in Bayern werden folgende personenbezogenen Daten erhoben:
            </p>
          </li>
          <ul>
            <li>
              Pflichtangaben: Natürlicher Name, Vorname, E-Mail-Adresse
            </li>
            <li>
              freiwillige Angaben: Titel, Adresse, Telefonnummer, Mobilfunknummer
            </li>
          </ul>
          <br></br>
          <li>
            Ich bin hiermit außerdem in Kenntnis gesetzt worden, dass die
            Arbeitsgemeinschaft Bayerischer Entomologen e.V. (ABE), allen
            voran die Koordinatoren des Projekts „Tagfalter in Bayern“ meine
            personenbezogenen Daten ausschließlich für die interne Bearbeitung
            der Beobachtungsdaten und stets unter Einhaltung der geltenden
            Datenschutzbestimmungen verwenden werden. Ich erlaube den
            Projektkoordinatoren außerdem, mich bei Rückfragen zu eigenen
            Meldungen zu kontaktieren, um die hohen Qualitätsstandards
            aufrecht zu erhalten. In begründeten Ausnahmefällen dürfen meine
            Kontaktdaten auch für wichtige technische oder fachliche
            Informationen genutzt werden.
          </li>
          <br></br>
          <li>
            Meine personenbezogenen Daten (Name, Adresse, E-Mail) sowie die
            punktgenauen Funddaten in den von mir zur Verfügung gestellten
            Datensätzen oder in den von mir eingegebenen Fundmeldungen werden
            ausschließlich den Projektkoordinatoren zugänglich gemacht.
            Diese prüfen alle eingegangenen Datensätze sowie die eingegebenen
            Fundmeldungen und geben diese frei. Alle in der Fundmeldung
            gemachten Informationen können und dürfen nur von den
            Projektkoordinatoren eingesehen werden.
          </li>
          <br></br>
          <li>
            Ich erlaube den Projektkoordinatoren des Projekts
            „Tagfalter in Bayern“  bei Bedarf und nach sorgfältiger Prüfung
            und Abwägung, dauerhaft oder nur vorrübergehend, weitere Experten
            für die Bewältigung der Datenprüfung ins Projektteam aufzunehmen
            und für die Dauer der Tätigkeit mit den Administratorenrechten
            eines Projektkoordinators auszustatten.
          </li>
          <br></br>
          <li>
            Es ist mir jederzeit möglich, eine schriftliche Auskunft
            (elektronisch oder per Brief) darüber zu erhalten, welche
            privaten, d.h. personenbezogenen Daten über mich hinterlegt sind
            und welche Personen darauf Zugriff haben.
          </li>
          <br></br>
          <li>
            Ich habe jederzeit das Recht, personenbezogene Daten und
            Sachdaten berichtigen oder aktualisieren zu lassen,
            Verarbeitungseinschränkungen anzupassen oder anonymisieren zu lassen.
          </li>
          <br></br>
          <li>
            <p>
              Über die hier getroffenen Festlegungen zu Datenschutz und
              Datennutzung hinausgehend,  erlaube ich der Arbeitsgemeinschaft
              Bayerischer Entomologen e.V. (ABE), die von mir zur Verfügung
              gestellten Quell-Datensätze (eigene Beobachtungsdaten und auch
              Daten, die mir von Dritten mit ausdrücklicher Genehmigung zur
              Verfügung gestellt wurden), für wissenschaftliche und
              naturschutzfachliche Auswertungen und Darstellungen an
              Wissenschaftler, Arbeitsgruppen, Behörden und Organisationen, die
              im Rahmen staatlich oder EU-weit geförderter, öffentlicher,
              nationaler und internationaler Projekte aktuell an der Erstellung
              deutschland- oder europaweiter Verbreitungskarten und
              Schutzmaßnahmen arbeiten, weiterzugeben.
            </p>
            <p>
              Über die finale Weitergabe der Datensätze aus der ABE-Datenbank
              entscheidet ein Fachbeirat. Dieser setzt sich zusammen aus dem
              aktuellen Vorstand der Arbeitsgemeinschaft Bayerischer
              Entomologen e.V. (ABE) und den Projektkoordinatoren des Projekts
              „Tagfalter in Bayern“. Für einen Beschluss durch den Fachbeirat
              wird eine einfache Mehrheit benötigt.
            </p>
          </li>
          <p>
            Außerdem gelten für die Weitergabe der Datensätze folgende Sonder-Regelungen:
          </p>
          <ul>
            <li>
              Es werden keine Kontaktdaten (Email, Telefonnummer, Adresse)
              weitergegeben (nur Name, Vorname).
            </li>
            <li>
              Es werden keine beigestellten Multimediadateien
              (z.B. Bilder, Video-Dateien) weitergegeben.
            </li>
            <li>
              'Die Publikation von Daten aus dem Projekt „Tagfalter in Bayern“
              wird Dritten nur mit folgendem Zitat genehmigt:
              Bereitstellung der Daten durch das Projekt „Tagfalter in Bayern“,
              Projektdatenbank der ABE e.V. (www.tagfalterbayern.de)'
            </li>
          </ul>
          {/* <br></br>
          <p>Die Einstellungen zur Freigabe von Daten können nach der 
            Registrierung im Benutzerprofil geändert werden.</p> */}
          <br></br>
          <li>
            Sollte eine Bestimmung dieser Vereinbarung unwirksam oder
            undurchführbar sein oder werden, wird die Wirksamkeit der übrigen
            Bestimmungen davon nicht berührt. Datenmelder und
            Projektkoordinatoren der Arbeitsgemeinschaft Bayerischer
            Entomologen e.V. (ABE) verpflichten sich, anstelle der unwirksamen
            Bestimmung eine dieser Bestimmung möglichst nahekommende wirksame
            Regelung zu treffen.
          </li>
        </ol>
        <br></br>
      </Container>
      <br></br>
    </div>
  )
}

export default Nutzungsvereinbarung;
