import React from "react";
import { TileLayer, LayersControl, useMap } from 'react-leaflet'
// import 'esri-leaflet';
// import { vectorBasemapLayer } from 'esri-leaflet-vector';
// import VectorTileLayer from 'react-esri-leaflet/plugins/VectorTileLayer';
import { BasemapLayer } from "react-esri-leaflet";

const esriAPIKey = process.env.REACT_APP_ESRI_API_KEY;


// function getV2Basemap(style) {
//   return vectorBasemapLayer(style, {
//     apikey: esriAPIKey,
//     version: 2
//   })
// }

// function EsriVectorLayer({ style }) {
//   const map = useMap();

//   React.useEffect(() => {
//     const layer = getV2Basemap(style).addTo(map);
//     return () => {
//       map.removeLayer(layer);
//     };
//   }, [map]);

//   return null;
// }

const { BaseLayer } = LayersControl;

function LayerControl() {
  return (
    <LayersControl position="bottomright">
      <BaseLayer checked name="OpenStreetMap">
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </BaseLayer>
      {/* <BaseLayer name="Esri Satellit"> */}
        {/* <VectorTileLayer url="https://basemaps.arcgis.com/arcgis/rest/services/World_Basemap_v2/VectorTileServer" /> */}
        {/* <TileLayer>
            <EsriVectorLayer style='arcgis/imagery' />
          </TileLayer> */}
      {/* </BaseLayer> */}
      <LayersControl.BaseLayer name="Esri Topographic">
          <BasemapLayer name="Topographic" />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Esri Imagery">
          <BasemapLayer name="ImageryClarity" />
        </LayersControl.BaseLayer>

    </LayersControl>
  )
}

export default LayerControl;