import React, { useEffect, useState } from "react";
import axios from '../AxiosConfig';
import Loading from '../Loading';
import BeobachtungPrivate from './BeobachtungPrivate';
import BeobachtungPublic from './BeobachtungPublic';
import Alert from "react-bootstrap/Alert";

function Beobachtung({ id }) {

  console.log("id:".concat(id));

  const [loading, setLoading] = useState(true);
  const [observation, setObservation] = useState(null);
  const [publicly, setPublicly] = useState(true);
  const [error, setError] = useState(false);

  const getObservation = async (id) => {
    await axios.get(`observation/get?id=`.concat(id))
      .then((response) => {
        console.log(response.data);
        setObservation(response.data.observation);
        setPublicly(response.data.public);
      })
      .catch(err => setError(true));

    setLoading(false);
  };

  useEffect(() => {
    console.log("useEffect");
    getObservation(id);
  }, [id]);

  console.log(observation);

  return (
    loading ?
      <Loading /> :
      error === true ?
      <div style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <Alert variant="danger">Beobachtung wurde nicht gefunden.</Alert>
        </div> :
        publicly === true ?
          <BeobachtungPublic observation={observation}></BeobachtungPublic> :
          <BeobachtungPrivate observation={observation}></BeobachtungPrivate>
  )

}

export default Beobachtung;