import React from 'react';
import ReactTable from '../ReactTable';

function MapTable(props) {
    const data = React.useMemo(
    () =>
      props.observations,
    [props.observations]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Art',
        accessor: 'art',
        Cell: ({ cell: { value } }) => <i>{value}</i>
      },
      {
        Header: 'Beobachter',
        accessor: 'beobachter',
      },
      {
        Header: 'Jahr',
        accessor: 'jahr',
      },
    ],
    []
  );

  return(
    <ReactTable data={data} columns={columns}></ReactTable>
  )
}

export default MapTable;
