import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
// import toast, { Toaster } from 'react-hot-toast';
import axios from "../AxiosConfig";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useAuth } from "./AuthContext";

axios.defaults.withCredentials = true;

function PasswordResetForm(props) {

  const navigate = useNavigate();
  // const location = useLocation();
  const auth = useAuth();

  const formik = useFormik({

    initialValues: {
      email: '',
      passwort: '',
      passwort2: '',
      code: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('E-Mail Addresse ungültig!')
        .required('Pflichtfeld'),
      code: Yup.string()
        .required('Pflichtfeld'),
      passwort: Yup.string()
        .min(8, 'Das Passwort muss mindestens 8 Zeichen lang sein!')
        .required('Pflichtfeld'),
      passwort2: Yup.string()
        .oneOf([Yup.ref('passwort'), null], 'Passwörter müssen übereinstimmen!'),
    }),
    onSubmit: values => {
      console.log(values);
      auth.resetPassword(() => {
        navigate('/login', { replace: true });
      }, values.email, values.code, values.passwort);
    }
  });

  return (
    <div className="auth-form">
      <div className="auth-form-body">
        <Form onSubmit={formik.handleSubmit}>
          <Form.Text>
            Hier den per E-Mail erhaltenen Verifizierungscode einfügen
            und ein neues Passwort vergeben.
          </Form.Text>
          <br></br>
          <Form.Group controlId="email">
            <Form.Label>E-Mail</Form.Label>
            <Form.Control
              type={'email'}
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email ? (
              <div style={{ color: 'red' }}>{formik.errors.email}</div>
            ) : null}
          </Form.Group>
          <Form.Group controlId="code">
            <Form.Label>Verifizierungscode</Form.Label>
            <Form.Control
              type={'text'}
              value={formik.values.code}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.touched.code && formik.errors.code ? (
              <div style={{ color: 'red' }}>{formik.errors.code}</div>
            ) : null}
          </Form.Group>
          <Form.Group controlId="passwort">
            <Form.Label>Passwort</Form.Label>
            <Form.Control
              type={'password'}
              value={formik.values.passwort}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.touched.passwort && formik.errors.passwort ? (
              <div style={{ color: 'red' }}>{formik.errors.passwort}</div>
            ) : null}
          </Form.Group>
          <Form.Group controlId="passwort2">
            <Form.Label>Passwort wiederholen</Form.Label>
            <Form.Control
              type={'password'}
              value={formik.values.passwort2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.passwort2 && formik.errors.passwort2 ? (
              <div style={{ color: 'red' }}>{formik.errors.passwort2}</div>
            ) : null}
          </Form.Group>
          <br></br>
          <Button variant="success" type="submit" className='w-100'>
            Passwort ändern
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default PasswordResetForm;