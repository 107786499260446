import React, { useEffect, useState } from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import Pagination from '../Pagination';
import toGermanDate from "../../functions/toGermanDate";

function Fotogalerie(props) {

  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = props.imagesPerPage ?? 28;
  const indexLast = currentPage * imagesPerPage;
  const indexFirst = indexLast - imagesPerPage;
  // console.log(currentPage);
  // console.log(indexFirst);
  // console.log(indexLast);

  useEffect(() => {
    setCurrentPage(1);
  }, [props]);

  const paginate = (pageNum) => setCurrentPage(pageNum);
  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  // Filter out observations with missing images
  const observationsWithPhoto = props.observations.filter(function (observation) {
    return (observation.foto !== undefined && observation.foto !== null)
  });
  const totalRecords = observationsWithPhoto.length;

  // HUHU: add links in figure captions
  const images = observationsWithPhoto.slice(indexFirst, indexLast).map((beobachtung, index) => {
    return (
      <img
      // HUHU: generate new key
        key={beobachtung.foto}
        style={{ margin: "5px" }}
        src={beobachtung.foto}
        alt={"[Beobachtung ".concat(beobachtung.id)
          .concat("] ")
          .concat(beobachtung.art)
          .concat(", ")
          .concat(props.private ? beobachtung.ort : "Landkreis ".concat(beobachtung.landkreis))
          .concat(", ")
          .concat(toGermanDate(beobachtung.datum))
          .concat(" (Beobachter: ")
          .concat(beobachtung.beobachter)
          .concat(")")}
      />
    );
  });


  return (
    <div className="gallery">
      <SimpleReactLightbox>
        <SRLWrapper>
          <div className="image-grid">
            {images}
          </div>
        </SRLWrapper>
      </SimpleReactLightbox>
      <br></br>
      <div>
      {observationsWithPhoto.length > 0 ?
        <Pagination
          objectsPerPage={imagesPerPage}
          totalObjects={totalRecords}
          paginate={paginate}
          nextPage={nextPage}
          prevPage={prevPage}
          currentPage={currentPage}
        ></Pagination>
        : <p style={{color: "grey"}}>Es wurden keine Fotos gefunden.</p> }
      </div>
    </div>
  );
}

export default Fotogalerie;
