import React, { useState, useEffect } from 'react';
import Badge from "react-bootstrap/Badge";
import axios from "../AxiosConfig";
import Numeral from "numeral";
import "numeral/locales/de";
import config from '../../config/index.js';

// switch between locales
Numeral.locale("de");

export default function Statistiken() {
  const [nArt, setNArt] = useState(0);
  const [nObservations, setNObservations] = useState(0);
  const [nFotos, setNFotos] = useState(0);
  const [nBenutzer, setNBenutzer] = useState(0);

  const getStatistics = async () => {
    const response = await axios.get(`stats/stats?bundesland=${config.BUNDESLAND}&artengruppe=${config.ARTENGRUPPE}`);
    setNObservations(response.data.observations.n);
    setNArt(response.data.observations.art);
    setNBenutzer(response.data.users.benutzer);
    setNFotos(response.data.photos.foto);
    console.log(response.data);
  };

  useEffect(() => {
    console.log("useEffect");
    getStatistics();
  }, []);

  return (
    <div>
      <Badge style={{ fontSize: "60%" }} bg="secondary">Beobachtungen: {Numeral(nObservations).format("0,0")}</Badge>{" "}
      <Badge style={{ fontSize: "60%" }} bg="secondary">Fotos: {Numeral(nFotos).format("0,0")}</Badge>{" "}
      <Badge style={{ fontSize: "60%" }} bg="secondary">Arten: {nArt}</Badge>{" "}
      <Badge style={{ fontSize: "60%" }} bg="secondary">Benutzer: {nBenutzer}</Badge>
    </div>
  )
}
