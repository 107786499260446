import React from 'react';
import AsyncSelect from 'react-select/async';

import choicesLepidoptera from '../../data/arten/choices_arten.json';
import choicesTagfalter from '../../data/arten/choices_arten_tagfalter.json';
import choicesTagfalterThueringen from '../../data/arten/choices_arten_thueringen.json';
import config from '../../config/index.js';

let choices = choicesLepidoptera;
let defaultChoices = [];
if (config.ARTENGRUPPE === "Tagfalter") {
  choices = choicesTagfalter;
  defaultChoices = choicesTagfalter;
}

if (config.BUNDESLAND === "Thüringen") {
  choices = choicesTagfalterThueringen;
  defaultChoices = choicesTagfalterThueringen;
}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    {/* <span style={groupBadgeStyles}>{data.options.length}</span> */}
  </div>
);

// https://stackoverflow.com/questions/38375646/filtering-array-of-objects-with-arrays-based-on-nested-value
const filterArt = (inputValue) => {
  let filteredArray = choices
    .filter((element) =>
      element.options.some((subElement) => subElement.label.toLowerCase().includes(inputValue.toLowerCase())))
    .map(element => {
      return Object.assign({}, element, { options: element.options.filter(subElement => subElement.label.toLowerCase().includes(inputValue.toLowerCase())) });
    });
  return filteredArray
};

const promiseOptions = inputValue =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(filterArt(inputValue));
    }, 1000);
  });

export default function InputArt(props) {

  return (
    <AsyncSelect
      {...props}
      isClearable
      cacheOptions
      defaultOptions={defaultChoices}
      // id={"art"}
      // type={"text"}
      formatGroupLabel={formatGroupLabel}
      // value={(field.value) || null}
      onChange={props.onChange}
      formatOptionLabel={function (data) {
        return (
          <span dangerouslySetInnerHTML={{ __html: data.label }} />
        );
      }}
      menuPortalTarget={document.body}
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      loadOptions={promiseOptions}
      closeMenuOnSelect={true}
      placeholder="Art suchen..."
    />
  )
}
