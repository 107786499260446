import React from 'react';
import { Row, Col } from 'react-bootstrap';
import config from '../../config/index.js';

export function LeafletLegendZeitraum() {
  return (
    <div>
      <i className="legend-circle" style={{ background: "black" }}></i>{" "}
      <div className="legend-text">ab {config.ZEITRAUM.JAHR1}</div>
      <br></br>
      <i className="legend-circle" style={{ background: "#808080" }}></i>{" "}
      <div className="legend-text">{config.ZEITRAUM.JAHR2} - {config.ZEITRAUM.JAHR1 - 1}</div>
      <br></br>
      <i className="legend-circle" style={{ background: "#C0C0C0" }}></i>{" "}
      <div className="legend-text">{config.ZEITRAUM.JAHR3} - {config.ZEITRAUM.JAHR2 - 1}</div>
      <br></br>
      <i className="legend-circle" style={{ background: "white" }}></i>{" "}
      <div className="legend-text">bis {config.ZEITRAUM.JAHR3}</div>
    </div>
  )
}

export function LeafletLegend() {
  return (
    <Row>
      <Col xs={12} lg={4} className="mb-3">
        <label className="control-label"><b>Zeitraum</b></label>
        <br></br>
        <LeafletLegendZeitraum />
      </Col>
      {config.BUNDESLAND === "Bayern" ?
        <Col xs={12} lg={8}>
          <div>
            <label className="control-label"><b>Datenquelle</b></label>
            <br></br>
            <i className="legend-square"></i>{" "}
            <div className="legend-text">Tagfalter Atlas Bayern (Bräu et al., 2013)</div>
            <br></br>
            <i className="legend-circle"></i>{" "}
            <div className="legend-text">Neue Daten</div>
          </div>
        </Col> : null}
    </Row>
  )
}

export default LeafletLegend;
