import React from 'react';
import Card from 'react-bootstrap/Card';
import config from '../../config/index.js';
import { links, admins } from '../../config/config.js';

function Kontakt() {

  const email = config.EMAIL;

  return (
    <Card style={{ fontSize: '0.9em' }}>
      <Card.Body>
        <Card.Title>Kontakt</Card.Title>
        <Card.Text>Bei inhaltlichen Fragen, Anregungen oder technischen Problemen
          kontaktieren Sie uns bitte unter: {email}
        </Card.Text>
        <Card.Text>Projektkoordination und Datenprüfung:</Card.Text>
        <ul>
          {admins.map(member => <li key={member}>{member}</li>)}
        </ul>
        <hr></hr>
        <Card.Title style={{ fontSize: '1.2em' }}>Links</Card.Title>
        <ul>
          {links.map(link => (
            <li key={link.url}>
              <a href={link.url} rel="noopener noreferrer" target="_blank">{link.text}</a>
            </li>
          ))}
        </ul>
      </Card.Body>
    </Card>
  )
}

export default Kontakt;
