import React, { useEffect, useState } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from "./AuthContext";
import axios from "../AxiosConfig";
import toast, { Toaster } from 'react-hot-toast';
import Loading from '../Loading';
// import { Radio, useRadioState } from "pretty-checkbox-react";

function Benutzerprofil() {

  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  // const datenweitergabe = useRadioState({ state: userData.freigabe });

  console.log(userData.freigabe);

  const getUserData = async (query) => {
    const response = await axios.get(`user/benutzerprofil`);
    console.log(response.data.email);
    setUserData(response.data);
    setLoading(false);
  };

  useEffect(() => {
    getUserData();
  }, []);

  // console.log(userData.email);

  // function changeDatenweitergabe(event) {
  //   event.preventDefault();
  //   console.log(datenweitergabe.state);
  //   axios.post(
  //     `changeUserDatenweitergabe`,
  //     {
  //       datenweitergabe: datenweitergabe.state
  //     },
  //     {
  //       withCredentials: true
  //     })
  //     .then(response => {
  //       console.log(response);
  //       toast.success("Änderungen wurden gespeichert");
  //     }).catch(function (error) {
  //       toast.error("Es ist ein Fehler aufgetreten!");
  //     });
  // }

  const formikPasswordChange = useFormik({
    enableReinitialize: true,
    initialValues: {
      passwordnew: "",
      passwordnew2: "",
      passwordold: ""
    },
    validationSchema: Yup.object({
      passwordold: Yup.string()
        .required('Pflichtfeld'),
      passwordnew: Yup.string()
        .min(8, 'Das Passwort muss mindestens 8 Zeichen lang sein!')
        .required('Pflichtfeld'),
      // HUHU: does this really work?
      passwordnew2: Yup.string()
        .oneOf([Yup.ref('passwordnew'), null], 'Passwörter müssen übereinstimmen!'),
    }),
    onSubmit: (values, { resetForm }) => {
      auth.changePassword(() => { console.log("password change") }, values.passwordold, values.passwordnew);
      resetForm();
    }
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: userData.email,
      adresse: userData.adresse,
      ort: userData.ort,
      telefon: userData.telefon
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('E-Mail Addresse ungültig!')
        .required('Pflichtfeld'),
    }),
    onSubmit: (values, { resetForm }) => {
      axios.post(
        `user/updateBenutzerprofil`,
        {
          email: values.email,
          adresse: values.adresse,
          ort: values.ort,
          telefon: values.telefon
        },
        {
          withCredentials: true
        })
        .then(response => {
          console.log(response);
          toast.success("Änderungen wurden gespeichert");
        }).catch(function (error) {
          toast.error("Es ist ein Fehler aufgetreten!");
        });
    }
  });

  return (
    loading ?
      <Loading /> :
      <div>
        <Toaster position="bottom-right"></Toaster>
        <div className="auth-form-wide">
          <div className="auth-form-body-wide">
            <Tab.Container id="left-tabs-example" defaultActiveKey="kontaktdaten">
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    {/* <Nav.Item>
                      <Nav.Link eventKey="einstellungen">Einstellungen</Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link eventKey="kontaktdaten">Meine Kontaktdaten</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="passwort">Passwort ändern</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    {/* <Tab.Pane eventKey="einstellungen">
                      <Form onSubmit={event => changeDatenweitergabe(event)}>
                        <p>
                          Über die hier getroffenen Festlegungen zu Datenschutz und
                          Datennutzung hinausgehend,  erlaube ich der Arbeitsgemeinschaft
                          Bayerischer Entomologen e.V. (ABE), die von mir zur Verfügung
                          gestellten Quell-Datensätze (eigene Beobachtungsdaten und auch
                          Daten, die mir von Dritten mit ausdrücklicher Genehmigung zur
                          Verfügung gestellt wurden), für wissenschaftliche und
                          naturschutzfachliche Auswertungen und Darstellungen an
                          Wissenschaftler, Arbeitsgruppen, Behörden und Organisationen, die
                          im Rahmen staatlich oder EU-weit geförderter, öffentlicher,
                          nationaler und internationaler Projekte aktuell an der Erstellung
                          deutschland- oder europaweiter Verbreitungskarten und
                          Schutzmaßnahmen arbeiten, weiterzugeben.
                        </p>
                        <p>
                          Über die finale Weitergabe der Datensätze aus der ABE-Datenbank
                          entscheidet ein Fachbeirat. Dieser setzt sich zusammen aus dem
                          aktuellen Vorstand der Arbeitsgemeinschaft Bayerischer
                          Entomologen e.V. (ABE) und den Projektkoordinatoren des Projekts
                          „Tagfalter in Bayern“. Für einen Beschluss durch den Fachbeirat
                          wird eine einfache Mehrheit benötigt.
                        </p>
                        <p>
                          Außerdem gelten für die Weitergabe der Datensätze folgende Sonder-Regelungen:
                        </p>
                        <ul>
                          <li>
                            Es werden keine Kontaktdaten (Email, Telefonnummer, Adresse)
                            weitergegeben (nur Name, Vorname).
                          </li>
                          <li>
                            Es werden keine beigestellten Multimediadateien
                            (z.B. Bilder, Video-Dateien) weitergegeben.
                          </li>
                          <li>
                            'Die Publikation von Daten aus dem Projekt „Tagfalter in Bayern“
                            wird Dritten nur mit folgendem Zitat genehmigt:
                            Bereitstellung der Daten durch das Projekt „Tagfalter in Bayern“,
                            Projektdatenbank der ABE e.V. (www.tagfalterbayern.de)'
                          </li>
                        </ul>Zustimmen? {" "}
                        <Radio color="primary" name="datum" value="ja" {...datenweitergabe}>ja</Radio>
                        <Radio color="primary" name="datum" value="nein" {...datenweitergabe}>nein</Radio>
                        <hr></hr>
                        <Button variant="success" type="submit">Änderungen speichern</Button>
                      </Form>
                      <br></br>
                    </Tab.Pane> */}
                    <Tab.Pane eventKey="kontaktdaten">
                      <Form onSubmit={formik.handleSubmit}>
                        <Form.Group controlId="email">
                          <Form.Label>E-Mail*</Form.Label>
                          <Form.Control
                            type={'email'}
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div style={{ color: 'red' }}>{formik.errors.email}</div>
                          ) : null}
                        </Form.Group>
                        <Form.Group controlId="adresse">
                          <Form.Label>Straße, Hausnummer</Form.Label>
                          <Form.Control
                            type={'text'}
                            value={formik.values.adresse}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                        <Form.Group controlId="ort">
                          <Form.Label>PLZ, Ort</Form.Label>
                          <Form.Control
                            type={'text'}
                            value={formik.values.ort}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                        <Form.Group controlId="telefon">
                          <Form.Label>Telefonnummer</Form.Label>
                          <Form.Control
                            type={'tel'}
                            value={formik.values.telefon}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                        <hr></hr>
                        <div className="d-grid gap-2">
                          <Button variant="success" type="submit">
                            Änderungen speichern
                          </Button>
                        </div>
                      </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="passwort">
                      <Form style={{ maxWidth: "400px" }} onSubmit={formikPasswordChange.handleSubmit}>
                        <br></br>
                        <Form.Group controlId="passwordold">
                          <Form.Label>Bisheriges Passwort</Form.Label>
                          <Form.Control
                            type={'password'}
                            value={formikPasswordChange.values.passwordold}
                            onBlur={formikPasswordChange.handleBlur}
                            onChange={formikPasswordChange.handleChange}
                          />
                          {formikPasswordChange.touched.passwordold && formikPasswordChange.errors.passwordold ? (
                            <div style={{ color: 'red' }}>{formikPasswordChange.errors.passwordold}</div>
                          ) : null}
                        </Form.Group>
                        <Form.Group controlId="passwordnew">
                          <Form.Label>Neues Passwort</Form.Label>
                          <Form.Control
                            type={'password'}
                            value={formikPasswordChange.values.passwordnew}
                            onBlur={formikPasswordChange.handleBlur}
                            onChange={formikPasswordChange.handleChange}
                          />
                          {formikPasswordChange.touched.passwordnew && formikPasswordChange.errors.passwordnew ? (
                            <div style={{ color: 'red' }}>{formikPasswordChange.errors.passwordnew}</div>
                          ) : null}
                        </Form.Group>
                        <Form.Group controlId="passwordnew2">
                          <Form.Label>Neues Passwort wiederholen</Form.Label>
                          <Form.Control
                            type={'password'}
                            value={formikPasswordChange.values.passwordnew2}
                            onChange={formikPasswordChange.handleChange}
                            onBlur={formikPasswordChange.handleBlur}
                          />
                          {formikPasswordChange.touched.passwordnew2 && formikPasswordChange.errors.passwordnew2 ? (
                            <div style={{ color: 'red' }}>{formikPasswordChange.errors.passwordnew2}</div>
                          ) : null}
                        </Form.Group>
                        <hr></hr>
                        <div className="d-grid gap-2">
                          <Button variant="success" type="submit">
                            Passwort ändern
                          </Button>
                        </div>
                      </Form>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
        <br></br>
      </div>
  )
}

export default Benutzerprofil;