import toast from 'react-hot-toast';
import moment from 'moment';

/**
 * Validates the form values.
 *
 * @param {Object} values - The form values to validate.
 * @returns {boolean} `true` if there are any errors, `false` otherwise.
 */
export function validateForm(values) {
  let error = false;

  if (!values.art) {
    toast.error("Keine Art ausgewählt!");
    error = true;
  }
  if (!values.latitude) {
    toast.error("Keine Koordinaten gefunden!");
    error = true;
  }
  if (!values.datum) {
    toast.error("Kein Datum ausgewählt!");
    error = true;
  }
  if (!values.beobachter) {
    toast.error("Kein Beobachter angegeben!");
    error = true;
  }
  if (values.unschaerfe > 5000) {
    toast.error("Unschärfe darf maximal 5000m betragen!");
    error = true;
  }
  if (values.stadium.value === "Imago" && values.anzahl < (values.anzahl_m + values.anzahl_w)) {
    toast.error("Anzahl Männchen + Anzahl Weibchen darf Gesamtanzahl nicht übersteigen!");
    error = true;
  }

  return error;
}
/**
 * Creates a new observation object.
 *
 * @param {Object} values - The form values.
 * @param {boolean} edit - Whether the observation is being edited.
 * @param {Array} files - The files associated with the observation.
 * @param {Array} observation - The current observation data.
 * @returns {Object} The new observation object.
 */
export function createObservation(values, edit, files, observation) {
  return {
    id: edit ? observation[0].id : null,
    art: values.art.value,
    stadium: values.stadium.value,
    id_fundort: values.id_fundort ? values.id_fundort : "",
    latitude: values.latitude,
    longitude: values.longitude,
    unschaerfe: values.unschaerfe,
    datum: moment(values.datum).format('YYYY-MM-DD'),
    beobachter: values.beobachter,
    anzahl: values.anzahl,
    anzahl_m: values.anzahl_m,
    anzahl_w: values.anzahl_w,
    zustand: values.zustand.value,
    nektarpflanzen: values.nektarpflanzen ? values.nektarpflanzen.map(item => item.value).join(", ") : "",
    larvalnahrung: values.larvalnahrung ? values.larvalnahrung.value : "",
    bemerkungen: values.bemerkungen,
    files: files,
    beleg: values.beleg ? values.beleg.value : "",
    erfassungsart: "Eingabemaske Online Portal",
    bestimmer: values.bestimmer,
    begleiter: values.begleiter,
    bestimmungssicherheit: values.bestimmungssicherheit ? values.bestimmungssicherheit.value : "",
    bestimmungsmethode: values.bestimmungsmethode ? values.bestimmungsmethode.value : "",
    art_der_beobachtung: values.art_der_beobachtung ? values.art_der_beobachtung.value : "",
    datenquelle: values.datenquelle ? values.datenquelle.value : "",
    datenquelle_bemerkung: values.datenquelle_bemerkung,
  };
}
/**
 * Resets the form values.
 *
 * @param {Object} resetForm - The form reset function.
 * @param {Object} values - The current form values.
 * @param {Array} choicesZustand - The zustand choices.
 * @param {Array} choicesStadium - The stadium choices.
 * @param {Array} choicesBestimmungsmethode - The bestimmungsmethode choices.
 * @param {Array} choicesBeleg - The beleg choices.
 */
export function resetFormValues(resetForm, values, choicesZustand, choicesStadium, choicesBestimmungsmethode, choicesBeleg) {
  resetForm({
    values: {
      zustand: choicesZustand[0],
      stadium: choicesStadium[0],
      bemerkungen: "",
      anzahl: 1,
      anzahl_m: 0,
      anzahl_w: 0,
      nektarpflanzen: "",
      larvalnahrung: "",
      datum: values.datum,
      art: "",
      beobachter: values.beobachter,
      bestimmer: values.bestimmer,
      begleiter: values.begleiter,
      art_der_beobachtung: values.art_der_beobachtung,
      bestimmungssicherheit: { value: "sicher", label: "sicher" },
      bestimmungsmethode: choicesBestimmungsmethode[0],
      beleg: choicesBeleg[0],
      fundort: values.fundort,
      id_fundort: values.id_fundort,
      latitude: values.latitude,
      longitude: values.longitude,
      unschaerfe: values.unschaerfe,
      datenquelle: values.datenquelle,
      datenquelle_bemerkung: values.datenquelle_bemerkung,
    }
  });
}
/**
 * Creates a new FormData object.
 *
 * @param {Object} newObservation - The new observation data.
 * @param {Array} imgCollection - The image collection.
 * @param {Array} source - The source data.
 * @param {boolean} edit - Whether the observation is being edited.
 * @returns {FormData} The new FormData object.
 */
export function createFormData(newObservation, imgCollection, source, edit) {
  let formData = new FormData();

  if (edit) {
    formData.append("id", newObservation.id);
  }
  for (let img in imgCollection[0]) {
    formData.append('imgCollection', imgCollection[0][img]);
  }

  source.forEach((item) => {
    formData.append('foto[]', item);
  });

  formData.append('foto', source);
  formData.append('art', newObservation.art);
  formData.append('stadium', newObservation.stadium);
  formData.append('id_fundort', newObservation.id_fundort);
  formData.append('latitude', newObservation.latitude);
  formData.append('longitude', newObservation.longitude);
  formData.append("unschaerfe", newObservation.unschaerfe);
  formData.append('datum', newObservation.datum);
  formData.append('beobachter', newObservation.beobachter);
  formData.append('anzahl', newObservation.anzahl);
  formData.append('anzahl_m', newObservation.anzahl_m);
  formData.append('anzahl_w', newObservation.anzahl_w);
  formData.append('zustand', newObservation.zustand);
  formData.append('nektarpflanzen', newObservation.nektarpflanzen);
  formData.append('larvalnahrung', newObservation.larvalnahrung);
  formData.append('bemerkungen', newObservation.bemerkungen);
  formData.append('beleg', newObservation.beleg);
  formData.append('erfassungsart', newObservation.erfassungsart);
  formData.append('bestimmer', newObservation.bestimmer);
  formData.append('begleiter', newObservation.begleiter);
  formData.append('bestimmungssicherheit', newObservation.bestimmungssicherheit);
  formData.append('bestimmungsmethode', newObservation.bestimmungsmethode);
  formData.append('art_der_beobachtung', newObservation.art_der_beobachtung);
  formData.append('datenquelle', newObservation.datenquelle);
  formData.append('datenquelle_bemerkung', newObservation.datenquelle_bemerkung);

  return formData;
}
